import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AWSDate: { input: string; output: string; }
  AWSDateTime: { input: string; output: string; }
  AWSEmail: { input: string; output: string; }
  AWSIPAddress: { input: string; output: string; }
  AWSJSON: { input: string; output: string; }
  AWSPhone: { input: string; output: string; }
  AWSTime: { input: string; output: string; }
  AWSTimestamp: { input: number; output: number; }
  AWSURL: { input: string; output: string; }
};

export enum AddressTypeCode {
  BusinessAddress = 'BUSINESS_ADDRESS',
  DeliveryToAddress = 'DELIVERY_TO_ADDRESS',
  FiscalAddress = 'FISCAL_ADDRESS',
  MailToAddress = 'MAIL_TO_ADDRESS',
  PhysicalAddress = 'PHYSICAL_ADDRESS',
  PostalAddress = 'POSTAL_ADDRESS',
  PostboxAddress = 'POSTBOX_ADDRESS',
  ResidentialAddress = 'RESIDENTIAL_ADDRESS'
}

export type ApproveCertificateRequestInput = {
  certifier: SpsAuthenticationInput;
  requestor?: InputMaybe<Scalars['String']['input']>;
  requestor_id: Scalars['String']['input'];
  status: ExportCertificateStatus;
};

export type AvailableTemplateFields = {
  __typename?: 'AvailableTemplateFields';
  code: Scalars['String']['output'];
  commodity?: Maybe<Scalars['String']['output']>;
  destinationMarket: DestinationMarket;
  displayedDescription?: Maybe<Scalars['String']['output']>;
  exportCertificateType?: Maybe<CertificateType>;
  id: Scalars['ID']['output'];
  majorVersion: Scalars['Int']['output'];
  minorVersion: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type B2GAddress = {
  __typename?: 'B2GAddress';
  city?: Maybe<Scalars['String']['output']>;
  cityId?: Maybe<Scalars['ID']['output']>;
  countryId?: Maybe<Scalars['ID']['output']>;
  countryName?: Maybe<Scalars['String']['output']>;
  countrySubdivisionId?: Maybe<Scalars['ID']['output']>;
  countrySubdivisionName?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  postcodeCode?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  typeCode?: Maybe<AddressTypeCode>;
};

export type B2GAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  cityId?: InputMaybe<Scalars['ID']['input']>;
  countryId?: InputMaybe<Scalars['ID']['input']>;
  countryName?: InputMaybe<Scalars['String']['input']>;
  countrySubdivisionId?: InputMaybe<Scalars['ID']['input']>;
  countrySubdivisionName?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  postcodeCode?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  typeCode?: InputMaybe<AddressTypeCode>;
};

export type B2GAuthentication = {
  __typename?: 'B2GAuthentication';
  actual?: Maybe<Scalars['AWSDateTime']['output']>;
  includedClauses: Array<B2GClause>;
  issueLocation?: Maybe<B2GLocation>;
  locationProviderParty?: Maybe<B2GParty>;
  providerParty?: Maybe<B2GParty>;
  typeCode?: Maybe<NamedCode>;
};

export type B2GAuthenticationInput = {
  actual?: InputMaybe<Scalars['AWSDateTime']['input']>;
  includedClauses?: InputMaybe<Array<B2GClauseInput>>;
  issueLocation?: InputMaybe<B2GLocationInput>;
  locationProviderParty?: InputMaybe<B2GPartyInput>;
  providerParty?: InputMaybe<B2GPartyInput>;
  typeCode?: InputMaybe<NamedCodeInput>;
};

export type B2GBanWineBatchInput = {
  effectiveDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  market: DestinationMarket;
  productId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type B2GCertificateDelivery = {
  __typename?: 'B2GCertificateDelivery';
  certificateDeliveryMethod?: Maybe<B2GCertificateDeliveryMethod>;
  certificateSigningOffice?: Maybe<Scalars['ID']['output']>;
  collectionMethod?: Maybe<B2GCollectionMethod>;
  copies?: Maybe<Scalars['Int']['output']>;
  digitalScanRequired?: Maybe<Scalars['Boolean']['output']>;
  dueDate?: Maybe<Scalars['AWSDateTime']['output']>;
  recipientAddress?: Maybe<B2GAddress>;
  recipientContactName?: Maybe<Scalars['String']['output']>;
  recipientEmail?: Maybe<Scalars['String']['output']>;
};

export type B2GCertificateDeliveryInput = {
  certificateDeliveryMethod?: InputMaybe<B2GCertificateDeliveryMethod>;
  certificateSigningOffice?: InputMaybe<Scalars['ID']['input']>;
  collectionMethod?: InputMaybe<B2GCollectionMethod>;
  copies?: InputMaybe<Scalars['Int']['input']>;
  digitalScanRequired?: InputMaybe<Scalars['Boolean']['input']>;
  dueDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  recipientAddress?: InputMaybe<B2GAddressInput>;
  recipientContactName?: InputMaybe<Scalars['String']['input']>;
  recipientEmail?: InputMaybe<Scalars['String']['input']>;
};

export enum B2GCertificateDeliveryMethod {
  OvernightCourierAuckland = 'OVERNIGHT_COURIER_AUCKLAND',
  OvernightCourierChristchurch = 'OVERNIGHT_COURIER_CHRISTCHURCH',
  UrgentCourier = 'URGENT_COURIER'
}

export type B2GClassification = {
  __typename?: 'B2GClassification';
  classCode?: Maybe<Scalars['String']['output']>;
  className: Scalars['String']['output'];
  systemId?: Maybe<Scalars['String']['output']>;
  systemName: Scalars['String']['output'];
};

export type B2GClassificationInput = {
  classCode?: InputMaybe<Scalars['String']['input']>;
  className: Scalars['String']['input'];
  systemId?: InputMaybe<Scalars['String']['input']>;
  systemName: Scalars['String']['input'];
};

export type B2GClause = {
  __typename?: 'B2GClause';
  content?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type B2GClauseInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum B2GCollectionMethod {
  Delivery = 'Delivery',
  Pickup = 'Pickup'
}

export type B2GConsignment = {
  __typename?: 'B2GConsignment';
  EORINumber?: Maybe<Scalars['String']['output']>;
  carrier?: Maybe<B2GParty>;
  consignee?: Maybe<B2GParty>;
  consignor?: Maybe<B2GParty>;
  consignorReference?: Maybe<Scalars['ID']['output']>;
  containers: Array<B2GContainer>;
  delivery?: Maybe<B2GParty>;
  expectedArrivalDateTime?: Maybe<Scalars['AWSDateTime']['output']>;
  exportExitDateTime?: Maybe<Scalars['AWSDateTime']['output']>;
  grossWeight?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  items: Array<B2GTradeLineItem>;
  loadingBasePort?: Maybe<B2GLocation>;
  mainCarriageTransportMovements: Array<B2GTransportMovement>;
  unloadingBasePort?: Maybe<B2GLocation>;
  verifier?: Maybe<B2GParty>;
  wineConsignment?: Maybe<B2GWineConsignment>;
  /** @deprecated Use wineConsignment.shippingFormat */
  wineShippingFormat?: Maybe<WinePackaging>;
};

export type B2GConsignmentInput = {
  EORINumber?: InputMaybe<Scalars['String']['input']>;
  carrier?: InputMaybe<B2GPartyInput>;
  consignee?: InputMaybe<B2GPartyInput>;
  consignor?: InputMaybe<B2GPartyInput>;
  consignorReference?: InputMaybe<Scalars['ID']['input']>;
  containers?: InputMaybe<Array<B2GContainerInput>>;
  delivery?: InputMaybe<B2GPartyInput>;
  expectedArrivalDateTime?: InputMaybe<Scalars['AWSDateTime']['input']>;
  exportExitDateTime?: InputMaybe<Scalars['AWSDateTime']['input']>;
  grossWeight?: InputMaybe<Scalars['String']['input']>;
  items?: InputMaybe<Array<B2GTradeLineItemInput>>;
  loadingBasePort?: InputMaybe<B2GLocationInput>;
  mainCarriageTransportMovements?: InputMaybe<Array<B2GTransportMovementInput>>;
  unloadingBasePort?: InputMaybe<B2GLocationInput>;
  verifier?: InputMaybe<B2GPartyInput>;
  wineConsignment?: InputMaybe<B2GWineConsignmentInput>;
  /** @deprecated Use wineConsignment.shippingFormat */
  wineShippingFormat?: InputMaybe<WinePackaging>;
};

export type B2GContact = {
  __typename?: 'B2GContact';
  email?: Maybe<Scalars['AWSEmail']['output']>;
  personName?: Maybe<Scalars['String']['output']>;
};

export type B2GContactInput = {
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  personName?: InputMaybe<Scalars['String']['input']>;
};

export type B2GContainer = {
  __typename?: 'B2GContainer';
  affixedSeals: Array<B2GSeal>;
  containerId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  temperatureSettings: Array<B2GTemperature>;
};

export type B2GContainerInput = {
  affixedSeals?: InputMaybe<Array<B2GSealInput>>;
  containerId: Scalars['String']['input'];
  temperatureSettings?: InputMaybe<Array<B2GTemperatureInput>>;
};

export type B2GCountry = {
  __typename?: 'B2GCountry';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  subdivision?: Maybe<B2GCountrySubDivision>;
};

export type B2GCountryInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  subdivision?: InputMaybe<B2GCountrySubDivisionInput>;
};

export type B2GCountrySubDivision = {
  __typename?: 'B2GCountrySubDivision';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type B2GCountrySubDivisionInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type B2GDeclaration = {
  __typename?: 'B2GDeclaration';
  eligibilityCriteria: Scalars['ID']['output'];
  market?: Maybe<DestinationMarket>;
  text?: Maybe<Scalars['String']['output']>;
};

export type B2GDeclarationInput = {
  eligibilityCriteria: Scalars['ID']['input'];
  market?: InputMaybe<DestinationMarket>;
  text: Scalars['String']['input'];
};

export type B2GEligibilityCriteriaMet = {
  __typename?: 'B2GEligibilityCriteriaMet';
  effectiveDate?: Maybe<Scalars['AWSDateTime']['output']>;
  eligibilityCriteria: Scalars['ID']['output'];
  expiryDate?: Maybe<Scalars['AWSDateTime']['output']>;
  processId: Scalars['ID']['output'];
};

export type B2GEligibilityCriteriaMetInput = {
  effectiveDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  eligibilityCriteria: Scalars['ID']['input'];
  expiryDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  processId: Scalars['ID']['input'];
};

export type B2GEligibilityStatus = {
  __typename?: 'B2GEligibilityStatus';
  expiryDate?: Maybe<Scalars['AWSDateTime']['output']>;
  market: DestinationMarket;
  status: B2GEligibilityStatusType;
};

export enum B2GEligibilityStatusType {
  Eligible = 'ELIGIBLE',
  Expired = 'EXPIRED',
  Incomplete = 'INCOMPLETE',
  NotEligible = 'NOT_ELIGIBLE',
  NotRequested = 'NOT_REQUESTED',
  Requested = 'REQUESTED'
}

export enum B2GError {
  InvalidValue = 'INVALID_VALUE',
  Required = 'REQUIRED'
}

export type B2GExportCertificateRequest = {
  __typename?: 'B2GExportCertificateRequest';
  certificateDelivery?: Maybe<B2GCertificateDelivery>;
  certificateNumber?: Maybe<Scalars['String']['output']>;
  certificateType?: Maybe<CertificateType>;
  commodity?: Maybe<Scalars['String']['output']>;
  consignment?: Maybe<B2GConsignment>;
  destination?: Maybe<B2GCountry>;
  exporterDeclaration?: Maybe<B2GExporterDeclaration>;
  id: Scalars['ID']['output'];
  lastStatusChange?: Maybe<Scalars['AWSDateTime']['output']>;
  market?: Maybe<DestinationMarket>;
  referencedDocuments: Array<B2GReferencedDocument>;
  requestName?: Maybe<Scalars['String']['output']>;
  requestedOnBehalfOfConsignor?: Maybe<Scalars['Boolean']['output']>;
  status: ExportCertificateStatus;
};

export type B2GExportCertificateRequestInput = {
  certificateDelivery?: InputMaybe<B2GCertificateDeliveryInput>;
  certificateType?: InputMaybe<CertificateType>;
  commodity?: InputMaybe<Scalars['String']['input']>;
  consignment?: InputMaybe<B2GConsignmentInput>;
  destination?: InputMaybe<B2GCountryInput>;
  exporterDeclaration?: InputMaybe<B2GExporterDeclarationInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  partyId?: InputMaybe<Scalars['ID']['input']>;
  referencedDocuments?: InputMaybe<Array<B2GReferencedDocumentInput>>;
  requestName?: InputMaybe<Scalars['String']['input']>;
  requestedOnBehalfOfConsignor?: InputMaybe<Scalars['Boolean']['input']>;
};

export type B2GExporterDeclaration = {
  __typename?: 'B2GExporterDeclaration';
  date: Scalars['AWSDateTime']['output'];
  name: Scalars['String']['output'];
};

export type B2GExporterDeclarationInput = {
  date: Scalars['AWSDateTime']['input'];
  name: Scalars['String']['input'];
};

export type B2GLocation = {
  __typename?: 'B2GLocation';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type B2GLocationInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type B2GNote = {
  __typename?: 'B2GNote';
  content: Scalars['String']['output'];
  contentCode?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  subjectCode?: Maybe<Scalars['String']['output']>;
};

export type B2GNoteInput = {
  content: Scalars['String']['input'];
  contentCode?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  subjectCode?: InputMaybe<Scalars['String']['input']>;
};

export type B2GPackage = {
  __typename?: 'B2GPackage';
  levelCode?: Maybe<Scalars['String']['output']>;
  nominalGrossVolume?: Maybe<Scalars['String']['output']>;
  nominalGrossWeight?: Maybe<Scalars['String']['output']>;
  packaging?: Maybe<B2GPackage>;
  physicalShippingMarks: Array<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['String']['output']>;
  typeCode?: Maybe<NamedCode>;
};

export type B2GPackageInput = {
  levelCode?: InputMaybe<Scalars['String']['input']>;
  nominalGrossVolume?: InputMaybe<Scalars['String']['input']>;
  nominalGrossWeight?: InputMaybe<Scalars['String']['input']>;
  packaging?: InputMaybe<B2GPackageInput>;
  physicalShippingMarks?: InputMaybe<Array<Scalars['String']['input']>>;
  quantity?: InputMaybe<Scalars['String']['input']>;
  typeCode?: InputMaybe<NamedCodeInput>;
};

export type B2GParty = {
  __typename?: 'B2GParty';
  definedContacts: Array<B2GContact>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  partyId?: Maybe<Scalars['String']['output']>;
  specifiedAddress?: Maybe<B2GAddress>;
  specifiedPerson?: Maybe<B2GPerson>;
};

export type B2GPartyInput = {
  definedContacts?: InputMaybe<Array<B2GContactInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partyId?: InputMaybe<Scalars['String']['input']>;
  specifiedAddress?: InputMaybe<B2GAddressInput>;
  specifiedPerson?: InputMaybe<B2GPersonInput>;
};

export type B2GPeriod = {
  __typename?: 'B2GPeriod';
  duration?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['AWSDateTime']['output']>;
  start?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type B2GPeriodInput = {
  duration?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['AWSDateTime']['input']>;
  start?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type B2GPerson = {
  __typename?: 'B2GPerson';
  attainedQualifications: Array<B2GQualification>;
  name?: Maybe<Scalars['String']['output']>;
};

export type B2GPersonInput = {
  attainedQualifications?: InputMaybe<Array<B2GQualificationInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type B2GPremise = {
  __typename?: 'B2GPremise';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type B2GPremiseInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type B2GProcess = {
  __typename?: 'B2GProcess';
  applicableProcessCharacteristics: Array<B2GProcessCharacteristic>;
  completionPeriod?: Maybe<B2GPeriod>;
  operationCountry?: Maybe<B2GCountry>;
  operatorParty?: Maybe<B2GParty>;
  typeCode?: Maybe<NamedCode>;
};

export type B2GProcessCharacteristic = {
  __typename?: 'B2GProcessCharacteristic';
  description?: Maybe<Scalars['String']['output']>;
  maximum?: Maybe<Scalars['String']['output']>;
  minimum?: Maybe<Scalars['String']['output']>;
  typeCode?: Maybe<NamedCode>;
  value?: Maybe<Scalars['String']['output']>;
};

export type B2GProcessCharacteristicInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  maximum?: InputMaybe<Scalars['String']['input']>;
  minimum?: InputMaybe<Scalars['String']['input']>;
  typeCode?: InputMaybe<NamedCodeInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type B2GProcessInput = {
  applicableProcessCharacteristics?: InputMaybe<Array<B2GProcessCharacteristicInput>>;
  completionPeriod?: InputMaybe<B2GPeriodInput>;
  operationCountry?: InputMaybe<B2GCountryInput>;
  operatorParty?: InputMaybe<B2GPartyInput>;
  typeCode?: InputMaybe<NamedCodeInput>;
};

export type B2GProduct = {
  __typename?: 'B2GProduct';
  additionalInformation: Array<B2GNote>;
  appliedProcesses: Array<B2GProcess>;
  assertedAuthentications: Array<B2GAuthentication>;
  classifications: Array<B2GClassification>;
  cnCode?: Maybe<Scalars['String']['output']>;
  commodity: Scalars['String']['output'];
  commonName?: Maybe<Scalars['String']['output']>;
  created: Scalars['AWSDateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  declarations?: Maybe<Array<B2GDeclaration>>;
  description: Scalars['String']['output'];
  eligibilityCriteriaFailed?: Maybe<Array<B2GEligibilityCriteriaMet>>;
  eligibilityCriteriaMet?: Maybe<Array<B2GEligibilityCriteriaMet>>;
  eligibilityStatus?: Maybe<Array<B2GEligibilityStatus>>;
  events: Array<B2GProductEvent>;
  expiry?: Maybe<Scalars['AWSDateTime']['output']>;
  exporterReference?: Maybe<Scalars['ID']['output']>;
  finalProcessor?: Maybe<B2GParty>;
  grossVolume?: Maybe<Scalars['String']['output']>;
  grossWeight?: Maybe<Scalars['String']['output']>;
  hsCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  intendedUse: Array<Scalars['String']['output']>;
  migrationId?: Maybe<Scalars['ID']['output']>;
  modifiable: Scalars['Boolean']['output'];
  netVolume?: Maybe<Scalars['String']['output']>;
  netWeight?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
  physicalPackage?: Maybe<B2GPackage>;
  premise: B2GPremise;
  productNumber?: Maybe<Scalars['String']['output']>;
  productionBatchIds: Array<Scalars['String']['output']>;
  referencedDocuments: Array<B2GReferencedDocument>;
  scientificName?: Maybe<Scalars['String']['output']>;
  verifier?: Maybe<B2GParty>;
  wine?: Maybe<B2GWineFields>;
};

export type B2GProductEligibility = {
  __typename?: 'B2GProductEligibility';
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  criteriaId: Scalars['ID']['output'];
  effectiveDate?: Maybe<Scalars['AWSDateTime']['output']>;
  executionArn?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['ID']['output'];
  market: DestinationMarket;
  status: B2GEligibilityStatusType;
};

export type B2GProductEligibilityInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  criteriaId: Scalars['ID']['input'];
  effectiveDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  executionArn?: InputMaybe<Scalars['String']['input']>;
  expiryDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id: Scalars['ID']['input'];
  market: DestinationMarket;
  status: B2GEligibilityStatusType;
};

export type B2GProductEvent = {
  __typename?: 'B2GProductEvent';
  from: B2GProduct;
  id: Scalars['ID']['output'];
  other?: Maybe<Scalars['String']['output']>;
  to: B2GProduct;
  what: B2GProductEventType;
  when: Scalars['AWSDateTime']['output'];
  where: B2GPremise;
};

export enum B2GProductEventType {
  AddedToExportCertificateRequest = 'ADDED_TO_EXPORT_CERTIFICATE_REQUEST',
  Amended = 'AMENDED',
  Other = 'OTHER',
  Processsed = 'PROCESSSED',
  Saved = 'SAVED',
  Transferred = 'TRANSFERRED'
}

export type B2GProductInput = {
  additionalInformation?: InputMaybe<Array<B2GNoteInput>>;
  appliedProcesses?: InputMaybe<Array<B2GProcessInput>>;
  assertedAuthentications?: InputMaybe<Array<B2GAuthenticationInput>>;
  classifications?: InputMaybe<Array<B2GClassificationInput>>;
  cnCode?: InputMaybe<Scalars['String']['input']>;
  commodity: Scalars['String']['input'];
  commonName?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['AWSDateTime']['input']>;
  declarations?: InputMaybe<Array<B2GDeclarationInput>>;
  description: Scalars['String']['input'];
  eligibilityCriteriaFailed?: InputMaybe<Array<B2GEligibilityCriteriaMetInput>>;
  eligibilityCriteriaMet?: InputMaybe<Array<B2GEligibilityCriteriaMetInput>>;
  expiry?: InputMaybe<Scalars['AWSDateTime']['input']>;
  exporterReference?: InputMaybe<Scalars['ID']['input']>;
  finalProcessor?: InputMaybe<B2GPartyInput>;
  grossVolume?: InputMaybe<Scalars['String']['input']>;
  grossWeight?: InputMaybe<Scalars['String']['input']>;
  hsCode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  intendedUse?: InputMaybe<Array<Scalars['String']['input']>>;
  netVolume?: InputMaybe<Scalars['String']['input']>;
  netWeight?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  physicalPackage?: InputMaybe<B2GPackageInput>;
  premise: B2GPremiseInput;
  productionBatchIds?: InputMaybe<Array<Scalars['String']['input']>>;
  referencedDocuments?: InputMaybe<Array<B2GReferencedDocumentInput>>;
  scientificName?: InputMaybe<Scalars['String']['input']>;
  verifier?: InputMaybe<B2GPartyInput>;
  wine?: InputMaybe<B2GWineFieldsInput>;
};

export type B2GQualification = {
  __typename?: 'B2GQualification';
  abbreviatedName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type B2GQualificationInput = {
  abbreviatedName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type B2GReferencedDocument = {
  __typename?: 'B2GReferencedDocument';
  attachment?: Maybe<ReferencedDocument>;
  documentType?: Maybe<Scalars['String']['output']>;
  information?: Maybe<Scalars['String']['output']>;
  issued?: Maybe<Scalars['AWSDateTime']['output']>;
  relationshipTypeCode: Scalars['String']['output'];
  requestorId?: Maybe<Scalars['ID']['output']>;
  typeCode?: Maybe<Scalars['String']['output']>;
};

export type B2GReferencedDocumentInput = {
  attachment: Scalars['ID']['input'];
  documentType?: InputMaybe<Scalars['String']['input']>;
  information?: InputMaybe<Scalars['String']['input']>;
  issued?: InputMaybe<Scalars['AWSDateTime']['input']>;
  relationshipTypeCode: Scalars['String']['input'];
  requestorId?: InputMaybe<Scalars['ID']['input']>;
  typeCode?: InputMaybe<Scalars['String']['input']>;
};

export type B2GSeal = {
  __typename?: 'B2GSeal';
  issuingParty?: Maybe<B2GParty>;
  sealId: Scalars['String']['output'];
};

export type B2GSealInput = {
  issuingParty?: InputMaybe<B2GPartyInput>;
  sealId: Scalars['String']['input'];
};

export type B2GTemperature = {
  __typename?: 'B2GTemperature';
  maximum?: Maybe<Scalars['String']['output']>;
  minimum?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type B2GTemperatureInput = {
  maximum?: InputMaybe<Scalars['String']['input']>;
  minimum?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type B2GTradeLineItem = {
  __typename?: 'B2GTradeLineItem';
  associatedTransportEquipment: Array<B2GContainer>;
  id: Scalars['String']['output'];
  physicalPackage?: Maybe<B2GPackage>;
  product?: Maybe<B2GProduct>;
  verified: Scalars['Boolean']['output'];
};

export type B2GTradeLineItemContainer = {
  __typename?: 'B2GTradeLineItemContainer';
  containerId: Scalars['String']['output'];
  grossVolume?: Maybe<Scalars['String']['output']>;
  grossWeight?: Maybe<Scalars['String']['output']>;
  netVolume?: Maybe<Scalars['String']['output']>;
  netWeight?: Maybe<Scalars['String']['output']>;
};

export type B2GTradeLineItemContainerInput = {
  containerId: Scalars['String']['input'];
  grossVolume?: InputMaybe<Scalars['String']['input']>;
  grossWeight?: InputMaybe<Scalars['String']['input']>;
  netVolume?: InputMaybe<Scalars['String']['input']>;
  netWeight?: InputMaybe<Scalars['String']['input']>;
};

export type B2GTradeLineItemInput = {
  associatedTransportEquipment?: InputMaybe<Array<B2GContainerInput>>;
  physicalPackage?: InputMaybe<B2GPackageInput>;
  product?: InputMaybe<B2GProductInput>;
};

export type B2GTransportMovement = {
  __typename?: 'B2GTransportMovement';
  ITDNNumber?: Maybe<Scalars['String']['output']>;
  mode: TransportModeCode;
  reference?: Maybe<Scalars['String']['output']>;
  registeredCountry?: Maybe<B2GCountry>;
  usedTransportMeans?: Maybe<Scalars['String']['output']>;
};

export type B2GTransportMovementInput = {
  ITDNNumber?: InputMaybe<Scalars['String']['input']>;
  mode: TransportModeCode;
  reference?: InputMaybe<Scalars['String']['input']>;
  registeredCountry?: InputMaybe<B2GCountryInput>;
  usedTransportMeans?: InputMaybe<Scalars['String']['input']>;
};

export type B2GValidationErrors = {
  __typename?: 'B2GValidationErrors';
  error?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  type?: Maybe<B2GError>;
};

export type B2GWineConsignment = {
  __typename?: 'B2GWineConsignment';
  packaging?: Maybe<B2GPackage>;
  shippingFormat?: Maybe<WinePackaging>;
};

export type B2GWineConsignmentInput = {
  packaging?: InputMaybe<B2GPackageInput>;
  shippingFormat?: InputMaybe<WinePackaging>;
};

export type B2GWineFields = {
  __typename?: 'B2GWineFields';
  EUCategory?: Maybe<Scalars['String']['output']>;
  EUIngredients?: Maybe<Array<EuIngredient>>;
  alternateWineNames?: Maybe<Array<Scalars['String']['output']>>;
  availableLitres?: Maybe<Scalars['Float']['output']>;
  colour?: Maybe<Scalars['String']['output']>;
  exportedLitres?: Maybe<Scalars['Float']['output']>;
  geographicIndication?: Maybe<Scalars['ID']['output']>;
  grapeVariety?: Maybe<Scalars['String']['output']>;
  mainNamePrefix?: Maybe<Scalars['String']['output']>;
  mainNameSuffix?: Maybe<Scalars['String']['output']>;
  package?: Maybe<B2GWinePackage>;
  shippingFormat?: Maybe<WinePackaging>;
  specialProcess?: Maybe<Scalars['String']['output']>;
  style?: Maybe<Scalars['String']['output']>;
  totalPackagedLitres?: Maybe<Scalars['Float']['output']>;
  vintage?: Maybe<Scalars['String']['output']>;
  volumeAdjustment?: Maybe<Array<ManualVolumeAdjustment>>;
};

export type B2GWineFieldsInput = {
  EUCategory?: InputMaybe<Scalars['String']['input']>;
  EUIngredients?: InputMaybe<Array<EuIngredientInput>>;
  alternateWineNames?: InputMaybe<Array<Scalars['String']['input']>>;
  colour?: InputMaybe<Scalars['String']['input']>;
  geographicIndication?: InputMaybe<Scalars['ID']['input']>;
  grapeVariety?: InputMaybe<Scalars['String']['input']>;
  mainNamePrefix?: InputMaybe<Scalars['String']['input']>;
  mainNameSuffix?: InputMaybe<Scalars['String']['input']>;
  package?: InputMaybe<B2GWinePackageInput>;
  shippingFormat?: InputMaybe<WinePackaging>;
  specialProcess?: InputMaybe<Scalars['String']['input']>;
  style?: InputMaybe<Scalars['String']['input']>;
  vintage?: InputMaybe<Scalars['String']['input']>;
};

export type B2GWinePackage = {
  __typename?: 'B2GWinePackage';
  bottleSize?: Maybe<Scalars['String']['output']>;
  bottlesPerCase?: Maybe<Scalars['Int']['output']>;
  cases?: Maybe<Scalars['Int']['output']>;
  extraBottles?: Maybe<Scalars['Int']['output']>;
  nameForDocuments?: Maybe<Scalars['String']['output']>;
};

export type B2GWinePackageInput = {
  bottleSize?: InputMaybe<Scalars['String']['input']>;
  bottlesPerCase?: InputMaybe<Scalars['Int']['input']>;
  cases?: InputMaybe<Scalars['Int']['input']>;
  extraBottles?: InputMaybe<Scalars['Int']['input']>;
  nameForDocuments?: InputMaybe<Scalars['String']['input']>;
};

export type BottlingRunData = {
  __typename?: 'BottlingRunData';
  bottleVolume: Scalars['ID']['output'];
  bottlesPerCase: Scalars['Int']['output'];
  cases: Scalars['Int']['output'];
  date: Scalars['AWSDateTime']['output'];
  extraBottles?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  litres: Scalars['Float']['output'];
  reference: Scalars['String']['output'];
};

export type BottlingRunDataInput = {
  bottleVolume: Scalars['ID']['input'];
  bottlesPerCase: Scalars['Int']['input'];
  cases: Scalars['Int']['input'];
  date: Scalars['AWSDateTime']['input'];
  extraBottles?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  reference: Scalars['String']['input'];
};

export type BottlingRunInput = {
  processId: Scalars['ID']['input'];
  runs: Array<BottlingRunDataInput>;
};

export type BottlingRunResponse = {
  __typename?: 'BottlingRunResponse';
  processId: Scalars['ID']['output'];
  runs: Array<BottlingRunData>;
};

export enum BusinessArea {
  Dairy = 'DAIRY',
  Honey = 'HONEY',
  LiveAnimals = 'LIVE_ANIMALS',
  Meat = 'MEAT',
  Organics = 'ORGANICS',
  Other = 'OTHER',
  Plants = 'PLANTS',
  Seafood = 'SEAFOOD',
  Seed = 'SEED',
  Wine = 'WINE'
}

export type CtCancellationDataInput = {
  sampleId: Scalars['String']['input'];
};

export type CtCancelledDataInput = {
  sampleId: Scalars['String']['input'];
};

export type CtErrorDataInput = {
  processId: Scalars['String']['input'];
};

export type CtFailStatusDataInput = {
  destinationMarkets?: InputMaybe<Array<InputMaybe<DestinationMarket>>>;
  sampleId: Scalars['String']['input'];
};

export type CtFirstReportedDateDataInput = {
  firstReportedDate: Scalars['AWSDateTime']['input'];
  sampleId: Scalars['String']['input'];
};

export type CtInProgressStatusDataInput = {
  comments?: InputMaybe<Scalars['String']['input']>;
  dateTimeReceived?: InputMaybe<Scalars['String']['input']>;
  isReceipted?: InputMaybe<Scalars['Boolean']['input']>;
  laboratoryReference?: InputMaybe<Scalars['String']['input']>;
  officiallySealed?: InputMaybe<Scalars['Boolean']['input']>;
  reasonUnsuitable?: InputMaybe<Scalars['String']['input']>;
  receiptChangedOn?: InputMaybe<Scalars['String']['input']>;
  sampleId: Scalars['String']['input'];
  sampleTemperature?: InputMaybe<Scalars['String']['input']>;
  suitable?: InputMaybe<Scalars['Boolean']['input']>;
  timeStamp?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CtnCancellationErrorDataInput = {
  sampleId: Scalars['String']['input'];
};

export type CtNoOrgErrorDataInput = {
  processId: Scalars['String']['input'];
};

export type CtPassStatusDataInput = {
  destinationMarkets?: InputMaybe<Array<InputMaybe<DestinationMarket>>>;
  sampleId: Scalars['String']['input'];
};

export type CtRequestedStatusDataInput = {
  processId: Scalars['String']['input'];
  sampleId: Scalars['String']['input'];
  sampleNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum CtStatusEventType {
  CancellationError = 'CANCELLATION_ERROR',
  CancellationRequested = 'CANCELLATION_REQUESTED',
  Cancelled = 'CANCELLED',
  Error = 'ERROR',
  Fail = 'FAIL',
  FirstResultReported = 'FIRST_RESULT_REPORTED',
  InProgress = 'IN_PROGRESS',
  NoOrgFoundError = 'NO_ORG_FOUND_ERROR',
  Pass = 'PASS',
  Requested = 'REQUESTED',
  Unsuitable = 'UNSUITABLE'
}

export type CtUnsuitableStatusDataInput = {
  reason?: InputMaybe<Scalars['String']['input']>;
  sampleId: Scalars['String']['input'];
  suitable?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CancelledProcessInput = {
  cancelledReason?: InputMaybe<Scalars['String']['input']>;
  processId: Scalars['ID']['input'];
};

export type CancelledProcessResponse = {
  __typename?: 'CancelledProcessResponse';
  cancelledReason?: Maybe<Scalars['String']['output']>;
  firstResultReportedDate?: Maybe<Scalars['String']['output']>;
  receipt: ChemistryTestReceipt;
  request: ChemistryTestRequest;
  status?: Maybe<Scalars['String']['output']>;
};

export enum CargoTypeClassificationCode {
  CtBallastOnly = 'CT_BALLAST_ONLY',
  CtBreakbulk = 'CT_BREAKBULK',
  CtContainerized = 'CT_CONTAINERIZED',
  CtDeepFrozenCargo = 'CT_DEEP_FROZEN_CARGO',
  CtDiplomatic = 'CT_DIPLOMATIC',
  CtDocuments = 'CT_DOCUMENTS',
  CtEnvironmentalPollutantCargo = 'CT_ENVIRONMENTAL_POLLUTANT_CARGO',
  CtFrozenCargo = 'CT_FROZEN_CARGO',
  CtGeneralCargo = 'CT_GENERAL_CARGO',
  CtHazardousCargo = 'CT_HAZARDOUS_CARGO',
  CtHighValueConsignments = 'CT_HIGH_VALUE_CONSIGNMENTS',
  CtHouseholdGoodsAndPersonalEffects = 'CT_HOUSEHOLD_GOODS_AND_PERSONAL_EFFECTS',
  CtIncompatibleCargo = 'CT_INCOMPATIBLE_CARGO',
  CtLiquidCargo = 'CT_LIQUID_CARGO',
  CtLowValueDutiableConsignments = 'CT_LOW_VALUE_DUTIABLE_CONSIGNMENTS',
  CtLowValueNonDutiableConsignments = 'CT_LOW_VALUE_NON_DUTIABLE_CONSIGNMENTS',
  CtMilitary = 'CT_MILITARY',
  CtNotHazardousCargo = 'CT_NOT_HAZARDOUS_CARGO',
  CtObnoxious = 'CT_OBNOXIOUS',
  CtOtherNonContainerized = 'CT_OTHER_NON_CONTAINERIZED',
  CtOutOfGauge = 'CT_OUT_OF_GAUGE',
  CtPalletized = 'CT_PALLETIZED',
  CtRollOnRollOff = 'CT_ROLL_ON_ROLL_OFF',
  CtTemperatureControlledCargo = 'CT_TEMPERATURE_CONTROLLED_CARGO',
  CtVehicles = 'CT_VEHICLES'
}

export enum CertificateType {
  ExportEligibilityStatement = 'EXPORT_ELIGIBILITY_STATEMENT',
  FreeSale = 'FREE_SALE',
  FreeSaleStatement = 'FREE_SALE_STATEMENT',
  LiveAnimals = 'LIVE_ANIMALS',
  Organics = 'ORGANICS',
  Supplementary = 'SUPPLEMENTARY'
}

export type ChemistryTest = {
  chemistryTest: Scalars['String']['input'];
  result: Scalars['Float']['input'];
};

export type ChemistryTestEventDataInput = {
  cancellationData?: InputMaybe<CtCancellationDataInput>;
  cancellationErrorData?: InputMaybe<CtnCancellationErrorDataInput>;
  cancelledData?: InputMaybe<CtCancelledDataInput>;
  errorData?: InputMaybe<CtErrorDataInput>;
  event: CtStatusEventType;
  failData?: InputMaybe<CtFailStatusDataInput>;
  firstReportedDateData?: InputMaybe<CtFirstReportedDateDataInput>;
  inProgressData?: InputMaybe<CtInProgressStatusDataInput>;
  noOrgErrorData?: InputMaybe<CtNoOrgErrorDataInput>;
  passData?: InputMaybe<CtPassStatusDataInput>;
  requestedData?: InputMaybe<CtRequestedStatusDataInput>;
  unsuitableData?: InputMaybe<CtUnsuitableStatusDataInput>;
};

export type ChemistryTestInput = {
  attributes: ChemistryTestRequestInput;
  productId: Scalars['ID']['input'];
};

export type ChemistryTestReceipt = {
  __typename?: 'ChemistryTestReceipt';
  laboratoryReference?: Maybe<Scalars['String']['output']>;
  receiptDate?: Maybe<Scalars['AWSDateTime']['output']>;
  sampleSuitable?: Maybe<Scalars['Boolean']['output']>;
  unsuitableReason?: Maybe<Scalars['String']['output']>;
};

export type ChemistryTestRequest = {
  __typename?: 'ChemistryTestRequest';
  expectedAlcoholContent?: Maybe<Scalars['Float']['output']>;
  laboratory: Scalars['ID']['output'];
  packagingDate?: Maybe<Scalars['AWSDateTime']['output']>;
  packagingReference?: Maybe<Scalars['String']['output']>;
  sampleCollectionDate: Scalars['AWSDateTime']['output'];
  sampleReference: Scalars['String']['output'];
  testRequired: Scalars['ID']['output'];
  wineTestReference: Scalars['String']['output'];
};

export type ChemistryTestRequestInput = {
  expectedAlcoholContent?: InputMaybe<Scalars['Float']['input']>;
  laboratory: Scalars['String']['input'];
  packagingDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  packagingReference?: InputMaybe<Scalars['String']['input']>;
  sampleCollectionDate: Scalars['AWSDateTime']['input'];
  testRequired: Scalars['ID']['input'];
};

export type ChemistryTestResponse = {
  __typename?: 'ChemistryTestResponse';
  cancelledReason?: Maybe<Scalars['String']['output']>;
  firstResultReportedDate?: Maybe<Scalars['String']['output']>;
  receipt: ChemistryTestReceipt;
  request: ChemistryTestRequest;
  requestedDate?: Maybe<Scalars['AWSDateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export enum ChemistryTestStatus {
  CancellationRequested = 'CANCELLATION_REQUESTED',
  Cancelled = 'CANCELLED',
  Error = 'ERROR',
  Fail = 'FAIL',
  InProgress = 'IN_PROGRESS',
  Pass = 'PASS',
  Requested = 'REQUESTED',
  Unsuitable = 'UNSUITABLE'
}

export type CombinedPartyTemplate = {
  __typename?: 'CombinedPartyTemplate';
  authenticator?: Maybe<SpsPartyTemplate>;
  processor?: Maybe<SpsPartyTemplate>;
};

export type ConfirmPackagingInput = {
  confirmed: Scalars['Boolean']['input'];
  disputeReason?: InputMaybe<Scalars['String']['input']>;
  disputeResolution?: InputMaybe<Scalars['String']['input']>;
  processId: Scalars['ID']['input'];
  taskId: Scalars['ID']['input'];
};

export type ConfirmPackagingResponse = {
  __typename?: 'ConfirmPackagingResponse';
  confirmed: Scalars['Boolean']['output'];
  disputeReason?: Maybe<Scalars['String']['output']>;
  disputeResolution?: Maybe<Scalars['String']['output']>;
  processId: Scalars['ID']['output'];
};

export type ConfirmWinemakingInput = {
  confirmations: Array<WinemakingConfirmationInput>;
  processId: Scalars['ID']['input'];
  taskId: Scalars['ID']['input'];
};

export enum ConnectionType {
  Direct = 'Direct',
  Hub = 'Hub'
}

export type CreateTemplateInput = {
  assignedTo?: InputMaybe<Scalars['String']['input']>;
  businessArea: BusinessArea;
  code: Scalars['String']['input'];
  commodity?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  destinationMarket: DestinationMarket;
  displayedDescription?: InputMaybe<Scalars['String']['input']>;
  effective?: InputMaybe<Scalars['AWSDateTime']['input']>;
  expire?: InputMaybe<Scalars['AWSDateTime']['input']>;
  exportCertificateType?: InputMaybe<CertificateType>;
  importCertificateType?: InputMaybe<ImportCertificateType>;
  majorVersion: Scalars['Int']['input'];
  minorVersion: Scalars['Int']['input'];
  newFileUpload?: InputMaybe<Scalars['Boolean']['input']>;
  reasonForUpdate?: InputMaybe<Scalars['String']['input']>;
  status: TemplateVersionStatus;
  title: Scalars['String']['input'];
};

export type CustomsPermitNumberUsage = {
  __typename?: 'CustomsPermitNumberUsage';
  allocatedInPastThirtyDays: Scalars['Int']['output'];
  unallocated: Scalars['Int']['output'];
};

export type DateTemplate = {
  __typename?: 'DateTemplate';
  day: IntTemplate;
  month: MonthTemplate;
  year: IntTemplate;
};

export type DateTimeTemplate = {
  __typename?: 'DateTimeTemplate';
  date: DateTemplate;
  time: TimeTemplate;
};

export type DecimalTemplate = {
  __typename?: 'DecimalTemplate';
  in_English: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type DecisionTable = {
  __typename?: 'DecisionTable';
  approvedRevision?: Maybe<DecisionTableRevision>;
  id: Scalars['ID']['output'];
  inputColumns: Array<DecisionTableColumn>;
  lastRevision?: Maybe<DecisionTableRevision>;
  responseColumns: Array<DecisionTableColumn>;
  revisions: Array<DecisionTableRevision>;
};

export type DecisionTableBaseInput = {
  validFrom?: Maybe<Scalars['AWSDateTime']['output']>;
  validUntil?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type DecisionTableColumn = {
  __typename?: 'DecisionTableColumn';
  collection?: Maybe<Scalars['String']['output']>;
  dataType: DecisionTableDataType;
  enumeration?: Maybe<Array<Scalars['String']['output']>>;
  enumerationInfo?: Maybe<Array<EnumerationInfo>>;
  header: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mandatory?: Maybe<Scalars['Boolean']['output']>;
};

export enum DecisionTableDataType {
  Boolean = 'BOOLEAN',
  Enumeration = 'ENUMERATION',
  Number = 'NUMBER',
  Search = 'SEARCH',
  String = 'STRING',
  Text = 'TEXT'
}

export type DecisionTableExpectation = {
  __typename?: 'DecisionTableExpectation';
  description: Scalars['String']['output'];
  input: RulesInput;
  response: RulesResponse;
};

export type DecisionTableField = {
  __typename?: 'DecisionTableField';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  mandatory: Scalars['Boolean']['output'];
};

export type DecisionTableFilter = {
  q?: InputMaybe<Scalars['String']['input']>;
};

export type DecisionTableRevision = {
  __typename?: 'DecisionTableRevision';
  createdAt: Scalars['AWSDateTime']['output'];
  description: Scalars['String']['output'];
  expectations: Array<DecisionTableExpectation>;
  id: Scalars['ID']['output'];
  revisionNumber: Scalars['Int']['output'];
  rows: Array<DecisionTableRow>;
  status: DecisionTableRevisionStatus;
  title: Scalars['String']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
  updatedBy: Scalars['String']['output'];
};

export type DecisionTableRevisionInput = {
  decisionTableId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  expectations: Array<DecisionTableRowInput>;
  rows: Array<DecisionTableRowInput>;
  title: Scalars['String']['input'];
};

export enum DecisionTableRevisionStatus {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT'
}

export type DecisionTableRow = {
  __typename?: 'DecisionTableRow';
  description: Scalars['String']['output'];
  input: RulesInput;
  response: RulesResponse;
};

export type DecisionTableRowInput = {
  declarationsInput?: InputMaybe<DeclarationsInput>;
  declarationsResponse?: InputMaybe<DeclarationsResponseInput>;
  description: Scalars['String']['input'];
  eligibilityInput?: InputMaybe<EligibilityInput>;
  eligibilityResponse?: InputMaybe<EligibilityResponseInput>;
  exportCertificateWorkflowInput?: InputMaybe<ExportCertificateWorkflowInput>;
  exportCertificateWorkflowResponse?: InputMaybe<ExportCertificateWorkflowResponseInput>;
  importConnectionsInput?: InputMaybe<ImportConnectionsInput>;
  importConnectionsResponse?: InputMaybe<ImportConnectionsResponseInput>;
  productFieldInput?: InputMaybe<ProductFieldInput>;
  productFieldResponse?: InputMaybe<ProductFieldResponseInput>;
  validFrom?: InputMaybe<Scalars['AWSDateTime']['input']>;
  validUntil?: InputMaybe<Scalars['AWSDateTime']['input']>;
  wineAnalytesTolerancesInput?: InputMaybe<WineAnalytesTolerancesInput>;
  wineAnalytesTolerancesResponse?: InputMaybe<WineAnalytesTolerancesResponseRowInput>;
  wineProductFieldInput?: InputMaybe<WineProductFieldInput>;
};

export type DecisionTableTestResult = {
  __typename?: 'DecisionTableTestResult';
  error_message: Scalars['String']['output'];
  succes: Scalars['Boolean']['output'];
};

export type DeclarationFormletFields = {
  __typename?: 'DeclarationFormletFields';
  eligibilityCriteria: FormletField;
  market: FormletField;
  text: FormletField;
};

export type DeclarationFormletSection = {
  __typename?: 'DeclarationFormletSection';
  fields: DeclarationFormletFields;
  id: Scalars['String']['output'];
  type: FormletFieldType;
};

export type DeclarationsInput = {
  commodity?: InputMaybe<Scalars['String']['input']>;
  destinationMarket?: InputMaybe<DestinationMarket>;
  processType?: InputMaybe<Scalars['String']['input']>;
};

export type DeclarationsResponse = {
  __typename?: 'DeclarationsResponse';
  commodity?: Maybe<Scalars['String']['output']>;
  declaration?: Maybe<Scalars['String']['output']>;
  destinationMarket?: Maybe<DestinationMarket>;
  eligibilityCriteria?: Maybe<Scalars['String']['output']>;
};

export type DeclarationsResponseInput = {
  declaration?: InputMaybe<Scalars['String']['input']>;
  eligibilityCriteria?: InputMaybe<Scalars['String']['input']>;
};

export type DeclarationsType = DecisionTableBaseInput & {
  __typename?: 'DeclarationsType';
  commodity?: Maybe<Scalars['String']['output']>;
  destinationMarket?: Maybe<DestinationMarket>;
  processType?: Maybe<Scalars['String']['output']>;
  validFrom?: Maybe<Scalars['AWSDateTime']['output']>;
  validUntil?: Maybe<Scalars['AWSDateTime']['output']>;
};

export enum DestinationMarket {
  Australia = 'AUSTRALIA',
  Brazil = 'BRAZIL',
  China = 'CHINA',
  Eu = 'EU',
  GreatBritain = 'GREAT_BRITAIN',
  Japan = 'JAPAN',
  NotApplicable = 'NOT_APPLICABLE',
  Singapore = 'SINGAPORE',
  Taiwan = 'TAIWAN'
}

export type DraftExportCertificate = {
  __typename?: 'DraftExportCertificate';
  executionArn: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type EStarOrganisation = {
  __typename?: 'EStarOrganisation';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type EStarOrganisationFilter = {
  q?: InputMaybe<Scalars['String']['input']>;
};

export type EuIngredient = {
  __typename?: 'EUIngredient';
  allergen: Scalars['Boolean']['output'];
  eNumber?: Maybe<Scalars['String']['output']>;
  functionalCategory?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  ingredient: Scalars['String']['output'];
  useENumber?: Maybe<Scalars['Boolean']['output']>;
};

export type EuIngredientInput = {
  allergen: Scalars['Boolean']['input'];
  eNumber?: InputMaybe<Scalars['String']['input']>;
  functionalCategory?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ingredient: Scalars['String']['input'];
  useENumber?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EuIngredientsTemplate = {
  __typename?: 'EUIngredientsTemplate';
  as_formatted_paragraph?: Maybe<Scalars['String']['output']>;
};

export type EligibilityInput = {
  commodity?: InputMaybe<Scalars['String']['input']>;
  destinationMarket?: InputMaybe<DestinationMarket>;
};

export type EligibilityResponse = {
  __typename?: 'EligibilityResponse';
  commodity?: Maybe<Scalars['String']['output']>;
  destinationMarket?: Maybe<DestinationMarket>;
  eligibilityCriteria: Scalars['String']['output'];
};

export type EligibilityResponseInput = {
  eligibilityCriteria: Scalars['String']['input'];
};

export type EligibilityType = DecisionTableBaseInput & {
  __typename?: 'EligibilityType';
  commodity?: Maybe<Scalars['String']['output']>;
  destinationMarket?: Maybe<DestinationMarket>;
  eligibilityCriteria?: Maybe<Scalars['String']['output']>;
  validFrom?: Maybe<Scalars['AWSDateTime']['output']>;
  validUntil?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type EnumerationInfo = {
  __typename?: 'EnumerationInfo';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type EstarCancelRequest = {
  __typename?: 'EstarCancelRequest';
  executionArn: Scalars['ID']['output'];
};

export type ExportCertificateRequest = {
  __typename?: 'ExportCertificateRequest';
  certificate_id?: Maybe<Scalars['String']['output']>;
  certificate_type: CertificateType;
  certifier?: Maybe<SpsAuthentication>;
  commodity?: Maybe<Scalars['String']['output']>;
  consignment: SpsConsignment;
  destination_market: DestinationMarket;
  id: Scalars['ID']['output'];
  reason_for_status_change?: Maybe<Scalars['String']['output']>;
  requestor?: Maybe<Scalars['String']['output']>;
  requestor_id?: Maybe<Scalars['String']['output']>;
  state_machine_execution_arn?: Maybe<Scalars['String']['output']>;
  status: ExportCertificateStatus;
  verifiers: Array<SpsAuthentication>;
};

export type ExportCertificateRequestInput = {
  certificate_id?: InputMaybe<Scalars['String']['input']>;
  certificate_type: CertificateType;
  certifier?: InputMaybe<SpsAuthenticationInput>;
  commodity?: InputMaybe<Scalars['String']['input']>;
  consignment: SpsConsignmentInput;
  destination_market: DestinationMarket;
  reason_for_status_change?: InputMaybe<Scalars['String']['input']>;
  requestor?: InputMaybe<Scalars['String']['input']>;
  requestor_id?: InputMaybe<Scalars['String']['input']>;
  status: ExportCertificateStatus;
  verifiers?: InputMaybe<Array<SpsAuthenticationInput>>;
};

export enum ExportCertificateStatus {
  Amended = 'AMENDED',
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  PendingReplacement = 'PENDING_REPLACEMENT',
  ProvisionallyApproved = 'PROVISIONALLY_APPROVED',
  Raised = 'RAISED',
  Replaced = 'REPLACED',
  RequestedToReplace = 'REQUESTED_TO_REPLACE',
  RequestedToResubmit = 'REQUESTED_TO_RESUBMIT',
  RequestedToRevoke = 'REQUESTED_TO_REVOKE',
  Resubmitted = 'RESUBMITTED',
  Revoked = 'REVOKED',
  Verified = 'VERIFIED'
}

export type ExportCertificateSupportingDocumentsInput = {
  requestor?: InputMaybe<Scalars['String']['input']>;
  requestor_id: Scalars['ID']['input'];
};

export type ExportCertificateWorkflowInput = {
  certificateType?: InputMaybe<CertificateType>;
};

export type ExportCertificateWorkflowResponse = {
  __typename?: 'ExportCertificateWorkflowResponse';
  validatonNeeded: Scalars['Boolean']['output'];
};

export type ExportCertificateWorkflowResponseInput = {
  validatonNeeded: Scalars['Boolean']['input'];
};

export type ExportCertificateWorkflowType = DecisionTableBaseInput & {
  __typename?: 'ExportCertificateWorkflowType';
  certificateType?: Maybe<CertificateType>;
  validFrom?: Maybe<Scalars['AWSDateTime']['output']>;
  validUntil?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type Field = {
  __typename?: 'Field';
  name?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  valid?: Maybe<Scalars['Boolean']['output']>;
};

export type FlexiFillingRunData = {
  __typename?: 'FlexiFillingRunData';
  date: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  litres: Scalars['Float']['output'];
  reference: Scalars['String']['output'];
};

export type FlexiFillingRunDataInput = {
  date: Scalars['AWSDateTime']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  litres: Scalars['Float']['input'];
  reference: Scalars['String']['input'];
};

export type FlexiFillingRunInput = {
  processId: Scalars['ID']['input'];
  runs: Array<FlexiFillingRunDataInput>;
};

export type FlexiFillingRunResponse = {
  __typename?: 'FlexiFillingRunResponse';
  processId: Scalars['ID']['output'];
  runs: Array<FlexiFillingRunData>;
};

export type Form = {
  __typename?: 'Form';
  declarations: Array<DeclarationFormletSection>;
  productDetails: Array<FormletField>;
};

export type FormletCollectionFilter = {
  __typename?: 'FormletCollectionFilter';
  attribute: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type FormletField = {
  __typename?: 'FormletField';
  charLength?: Maybe<Scalars['Int']['output']>;
  collection?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  filter?: Maybe<FormletCollectionFilter>;
  id: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  nested?: Maybe<Array<Maybe<FormletNestedFields>>>;
  numberConstrain?: Maybe<NumberConstrain>;
  numberOfLines?: Maybe<Scalars['Int']['output']>;
  options?: Maybe<Array<Maybe<FormletOption>>>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  sectionFields?: Maybe<Array<Maybe<FormletField>>>;
  type?: Maybe<FormletFieldType>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum FormletFieldType {
  Checkbox = 'CHECKBOX',
  Date = 'DATE',
  LongText = 'LONG_TEXT',
  MultilineText = 'MULTILINE_TEXT',
  MultisearchTable = 'MULTISEARCH_TABLE',
  Nested = 'NESTED',
  Number = 'NUMBER',
  Parallel = 'PARALLEL',
  Party = 'PARTY',
  Radio = 'RADIO',
  Search = 'SEARCH',
  Select = 'SELECT',
  ShortText = 'SHORT_TEXT',
  ShortTextArray = 'SHORT_TEXT_ARRAY'
}

export type FormletNestedFields = {
  __typename?: 'FormletNestedFields';
  collection?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  type: FormletFieldType;
};

export type FormletOption = {
  __typename?: 'FormletOption';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GenerateDraftExportCertificateInput = {
  requestor?: InputMaybe<Scalars['String']['input']>;
  requestor_id: Scalars['ID']['input'];
};

export type IDsTemplate = {
  __typename?: 'IDsTemplate';
  as_rows: Array<Scalars['String']['output']>;
  comma_separated: Scalars['String']['output'];
  new_line_separated: Scalars['String']['output'];
};

export type IppcHubLogEntry = {
  __typename?: 'IPPCHubLogEntry';
  /** @deprecated Use certificateNumber */
  certificateId?: Maybe<Scalars['String']['output']>;
  certificateNumber?: Maybe<Scalars['String']['output']>;
  date: Scalars['AWSDateTime']['output'];
  exportCountryCode: Scalars['String']['output'];
  hubDeliveryNumber: Scalars['String']['output'];
  hubTrackingInfo: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  message: Scalars['String']['output'];
};

export type IppcHubLogInput = {
  /** @deprecated Use certificateNumber */
  certificateId?: InputMaybe<Scalars['String']['input']>;
  certificateNumber?: InputMaybe<Scalars['String']['input']>;
  exportCountryCode: Scalars['String']['input'];
  hubDeliveryNumber: Scalars['String']['input'];
  hubTrackingInfo: Scalars['String']['input'];
  message: Scalars['String']['input'];
};

export type ImportCertificate = {
  __typename?: 'ImportCertificate';
  certificate_id: Scalars['String']['output'];
  /** @deprecated Use consignment.consignee */
  consignee?: Maybe<SpsParty>;
  consignment: SpsConsignment;
  /** @deprecated Use consignment.consignor */
  consignor?: Maybe<SpsParty>;
  /** @deprecated Use exchanged_document.referenced_documents */
  embeddedDocument: Array<ImportCertificateEmbeddedDocument>;
  exchanged_document: SpsExchangedDocument;
  export_country: SpsCountry;
  id: Scalars['ID']['output'];
  /** @deprecated Use consignment.import_country */
  import_country: SpsCountry;
  /** @deprecated Use exchanged_document.included_notes */
  included_notes: Array<SpsNote>;
  ippcLogs: Array<IppcHubLogEntry>;
  issued?: Maybe<Scalars['AWSDateTime']['output']>;
  /** @deprecated Use exchanged_document.issuer */
  issuer?: Maybe<SpsParty>;
  items: Array<SpsTradeLineItem>;
  /** @deprecated Use consignment.main_carriage_transport_movements */
  last_transport_movement?: Maybe<SpsTransportMovement>;
  /** @deprecated Use consignment.loading_base_port_location */
  loading_base_port_location?: Maybe<SpsLocation>;
  logs: Array<ImportCertificateLogMessage>;
  /** @deprecated Use consignment.main_carriage_transport_movements */
  main_carriage_transport_movements?: Maybe<Array<SpsTransportMovement>>;
  pdf_generated: PdfGenerationStatus;
  /** @deprecated Use exchanged_document.referenced_documents */
  referenced_documents: Array<SpsReferencedDocument>;
  replaced_by?: Maybe<ImportCertificate>;
  replacement_reason?: Maybe<Scalars['String']['output']>;
  replacing?: Maybe<ImportCertificate>;
  replacing_certificate_number?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use exchanged_document.signatories */
  signatories: Array<SpsAuthentication>;
  status: ImportCertificateStatus;
  status_code: Scalars['Int']['output'];
  /** @deprecated Use consignment.transit_countries */
  transit_countries?: Maybe<Array<SpsCountry>>;
  type_code: Scalars['Int']['output'];
  type_name?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use consignment.unloading_base_port_location */
  unloading_base_port_location?: Maybe<SpsLocation>;
  /** @deprecated Use consignment.utilized_transport_equipment */
  utilized_transport_equipment: Array<SpsTransportEquipment>;
};

export type ImportCertificateEmbeddedDocument = {
  __typename?: 'ImportCertificateEmbeddedDocument';
  description?: Maybe<Scalars['String']['output']>;
  documentID: Scalars['String']['output'];
  file_name: Scalars['String']['output'];
  file_size?: Maybe<Scalars['String']['output']>;
  file_type: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type ImportCertificateExtractItem = {
  __typename?: 'ImportCertificateExtractItem';
  code: Scalars['String']['output'];
  items?: Maybe<Array<ImportCertificateExtractItem>>;
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ImportCertificateExtractMeasurement = {
  __typename?: 'ImportCertificateExtractMeasurement';
  code: Scalars['String']['output'];
  label: Scalars['String']['output'];
  measures?: Maybe<Array<ImportCertificateExtractItem>>;
  value: Scalars['String']['output'];
};

export type ImportCertificateExtractTemplate = {
  __typename?: 'ImportCertificateExtractTemplate';
  as_items?: Maybe<Scalars['String']['output']>;
};

export type ImportCertificateIntegration = {
  __typename?: 'ImportCertificateIntegration';
  countryCode: Scalars['String']['output'];
  response?: Maybe<ImportCertificateIntegrationResponse>;
};

export enum ImportCertificateIntegrationResponse {
  Accept = 'Accept',
  DeliveredNotReadable = 'DeliveredNotReadable',
  Ignore = 'Ignore'
}

export type ImportCertificateLogMessage = {
  __typename?: 'ImportCertificateLogMessage';
  createdBy: Scalars['String']['output'];
  date?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  relatedCertificate?: Maybe<ImportCertificate>;
  status: ImportCertificateLogStatus;
};

export type ImportCertificateLogMessageInput = {
  id: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  relatedCertificateId?: InputMaybe<Scalars['ID']['input']>;
  relatedCertificateNumber?: InputMaybe<Scalars['String']['input']>;
  status: ImportCertificateLogStatus;
};

export enum ImportCertificateLogStatus {
  Acknowledged = 'ACKNOWLEDGED',
  Issued = 'ISSUED',
  PdfDownloaded = 'PDF_DOWNLOADED',
  Rejected = 'REJECTED',
  Replaced = 'REPLACED',
  ReplacementRequested = 'REPLACEMENT_REQUESTED',
  Withdrawn = 'WITHDRAWN'
}

export type ImportCertificateMeasurementTemplate = {
  __typename?: 'ImportCertificateMeasurementTemplate';
  as_new_lines?: Maybe<Scalars['String']['output']>;
};

export type ImportCertificatePackagingTemplate = {
  __typename?: 'ImportCertificatePackagingTemplate';
  as_new_lines?: Maybe<Scalars['String']['output']>;
};

export type ImportCertificatePdf = {
  __typename?: 'ImportCertificatePdf';
  id: Scalars['ID']['output'];
  status: PdfGenerationStatus;
  url?: Maybe<Scalars['String']['output']>;
};

export type ImportCertificatePdfInput = {
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export enum ImportCertificateStatus {
  Approved = 'APPROVED',
  Issued = 'ISSUED',
  Rejected = 'REJECTED',
  Replaced = 'REPLACED',
  ReplacementRequested = 'REPLACEMENT_REQUESTED',
  Unrecognised = 'UNRECOGNISED',
  Withdrawn = 'WITHDRAWN'
}

export type ImportCertificateStatusChangeInput = {
  id: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};

export enum ImportCertificateType {
  ImportedPhytosanitary = 'IMPORTED_PHYTOSANITARY',
  ImportedSanitary = 'IMPORTED_SANITARY',
  ReexportedPhytosanitary = 'REEXPORTED_PHYTOSANITARY'
}

export type ImportCertificateXml = {
  __typename?: 'ImportCertificateXml';
  documentName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type ImportConnectionsInput = {
  connectionType?: InputMaybe<ConnectionType>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
};

export type ImportConnectionsResponse = {
  __typename?: 'ImportConnectionsResponse';
  connectionType: ConnectionType;
  countryCode: Scalars['String']['output'];
  receiving?: Maybe<ImportCertificateIntegrationResponse>;
  sending?: Maybe<Scalars['Boolean']['output']>;
  spsAcknowledgement: Scalars['Boolean']['output'];
};

export type ImportConnectionsResponseInput = {
  receiving?: InputMaybe<ImportCertificateIntegrationResponse>;
  sending?: InputMaybe<Scalars['Boolean']['input']>;
  spsAcknowledgement: Scalars['Boolean']['input'];
};

export type ImportConnectionsType = DecisionTableBaseInput & {
  __typename?: 'ImportConnectionsType';
  connectionType?: Maybe<ConnectionType>;
  countryCode?: Maybe<Scalars['String']['output']>;
  validFrom?: Maybe<Scalars['AWSDateTime']['output']>;
  validUntil?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type IndicatorType = {
  __typename?: 'IndicatorType';
  content: Scalars['String']['output'];
  format?: Maybe<Scalars['String']['output']>;
};

export type IntTemplate = {
  __typename?: 'IntTemplate';
  in_English: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ManualVolumeAdjustment = {
  __typename?: 'ManualVolumeAdjustment';
  date?: Maybe<Scalars['AWSDateTime']['output']>;
  litres?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

export type MeasureTemplate = {
  __typename?: 'MeasureTemplate';
  mpi_unit?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  three_decimals?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use three_decimals */
  three_digits?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type MonthTemplate = {
  __typename?: 'MonthTemplate';
  abbreviation: Scalars['String']['output'];
  name: Scalars['String']['output'];
  number: IntTemplate;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptedTermsAndConditions?: Maybe<Scalars['Boolean']['output']>;
  addBottlingRuns: BottlingRunResponse;
  addCustomsPermitNumbers: Scalars['Int']['output'];
  addFlexiFillingRuns: FlexiFillingRunResponse;
  addProduct: B2GProduct;
  allocateCustomsPermitNumber: Scalars['String']['output'];
  appendIPPCHubLog: IppcHubLogEntry;
  appendImportCertificateLog: Scalars['Boolean']['output'];
  approveExportCertificateRequest?: Maybe<ExportCertificateRequest>;
  attachTemplateDocument: TemplateUploadUrl;
  banWineBatch: Scalars['String']['output'];
  cancelEstarRequest: EstarCancelRequest;
  cancelExportCertificateRequest: B2GExportCertificateRequest;
  cancelProcess: CancelledProcessResponse;
  cloneTemplate: Template;
  completeSensoryProcess: SensoryProcessRequest;
  confirmPackaging: ConfirmPackagingResponse;
  confirmWinemaking: Scalars['String']['output'];
  createTemplate: Template;
  deleteAllCustomsPermitNumbers: Scalars['Boolean']['output'];
  deleteExportCertificateRequest: Scalars['Boolean']['output'];
  deleteExportRequest: Scalars['Boolean']['output'];
  deleteProduct: Scalars['Boolean']['output'];
  deleteReferencedDocument: Scalars['ID']['output'];
  deleteTemplate: Scalars['String']['output'];
  draftExportCertificateRequest: B2GExportCertificateRequest;
  e2eRaiseExportCertificateRequest?: Maybe<B2GExportCertificateRequest>;
  executeChemistryTestEvent: Scalars['Boolean']['output'];
  forceApproveTemplateVersion: TemplateVersion;
  generateDraftExportCertificate: DraftExportCertificate;
  getNewTemplateVersionUploadUrl: TemplateUploadUrl;
  makeProductEligible: Scalars['String']['output'];
  newPartyApiKey?: Maybe<SearchApiKey>;
  newReferencedDocument: NewReferencedDocument;
  newSearchApiKey?: Maybe<SearchApiKey>;
  raiseExportCertificateRequest?: Maybe<B2GExportCertificateRequest>;
  reindexCollection: ReindexResponse;
  reindexDatabaseItem: ReindexResponse;
  rejectImportCertificate?: Maybe<ImportCertificate>;
  requestChemistryTest: ChemistryTestResponse;
  requestConfirmation: RequestConfirmationResponse;
  requestReplacementImportCertificate?: Maybe<ImportCertificate>;
  resetConfirmationRequest: Scalars['String']['output'];
  saveExportCertificateRequest: ExportCertificateRequest;
  setTemplateVersionApproved: TemplateVersion;
  setTemplateVersionStatus: TemplateVersion;
  sfChangeExportCertificateRequestStatus?: Maybe<ExportCertificateRequest>;
  sfVerifyExportCertificateRequestProducts?: Maybe<Array<Maybe<SpsTradeLineItem>>>;
  splitProduct: Array<B2GProduct>;
  startPackaging: ProcessResponse;
  startWinemaking: ProcessResponse;
  transferProduct: B2GProduct;
  updateDecisionTable: DecisionTableRevision;
  updateTemplate: Template;
};


export type MutationAddBottlingRunsArgs = {
  input: BottlingRunInput;
};


export type MutationAddCustomsPermitNumbersArgs = {
  csv: Scalars['String']['input'];
};


export type MutationAddFlexiFillingRunsArgs = {
  input: FlexiFillingRunInput;
};


export type MutationAddProductArgs = {
  ingredients?: InputMaybe<Array<B2GProductInput>>;
  product: B2GProductInput;
};


export type MutationAllocateCustomsPermitNumberArgs = {
  certificateRequestId: Scalars['String']['input'];
};


export type MutationAppendIppcHubLogArgs = {
  input: IppcHubLogInput;
};


export type MutationAppendImportCertificateLogArgs = {
  input: ImportCertificateLogMessageInput;
};


export type MutationApproveExportCertificateRequestArgs = {
  input: ApproveCertificateRequestInput;
};


export type MutationAttachTemplateDocumentArgs = {
  templateId: Scalars['ID']['input'];
};


export type MutationBanWineBatchArgs = {
  input: Array<B2GBanWineBatchInput>;
};


export type MutationCancelEstarRequestArgs = {
  reason?: InputMaybe<Scalars['String']['input']>;
  sampleId: Scalars['ID']['input'];
};


export type MutationCancelExportCertificateRequestArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCancelProcessArgs = {
  input: CancelledProcessInput;
};


export type MutationCloneTemplateArgs = {
  code: Scalars['String']['input'];
  parentTemplateId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};


export type MutationCompleteSensoryProcessArgs = {
  input: SensoryProcessInput;
};


export type MutationConfirmPackagingArgs = {
  input: ConfirmPackagingInput;
};


export type MutationConfirmWinemakingArgs = {
  input: ConfirmWinemakingInput;
};


export type MutationCreateTemplateArgs = {
  template: CreateTemplateInput;
};


export type MutationDeleteExportCertificateRequestArgs = {
  requestor: Scalars['String']['input'];
  requestor_id: Scalars['String']['input'];
};


export type MutationDeleteExportRequestArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteReferencedDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDraftExportCertificateRequestArgs = {
  request: B2GExportCertificateRequestInput;
};


export type MutationE2eRaiseExportCertificateRequestArgs = {
  id: Scalars['ID']['input'];
  partyId: Scalars['String']['input'];
};


export type MutationExecuteChemistryTestEventArgs = {
  input: ChemistryTestEventDataInput;
};


export type MutationForceApproveTemplateVersionArgs = {
  templateVersionId: Scalars['ID']['input'];
};


export type MutationGenerateDraftExportCertificateArgs = {
  input: GenerateDraftExportCertificateInput;
};


export type MutationGetNewTemplateVersionUploadUrlArgs = {
  templateId: Scalars['ID']['input'];
};


export type MutationMakeProductEligibleArgs = {
  market: DestinationMarket;
  productId: Scalars['ID']['input'];
};


export type MutationNewReferencedDocumentArgs = {
  filename?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRaiseExportCertificateRequestArgs = {
  id: Scalars['ID']['input'];
};


export type MutationReindexCollectionArgs = {
  collectionName: Scalars['String']['input'];
  options?: InputMaybe<SearchOptions>;
};


export type MutationReindexDatabaseItemArgs = {
  collectionName: Scalars['String']['input'];
  ids: Array<Scalars['ID']['input']>;
};


export type MutationRejectImportCertificateArgs = {
  input: ImportCertificateStatusChangeInput;
};


export type MutationRequestChemistryTestArgs = {
  input: ChemistryTestInput;
};


export type MutationRequestConfirmationArgs = {
  input: RequestConfirmationInput;
};


export type MutationRequestReplacementImportCertificateArgs = {
  input: ImportCertificateStatusChangeInput;
};


export type MutationResetConfirmationRequestArgs = {
  input: ResetConfirmationRequestInput;
};


export type MutationSaveExportCertificateRequestArgs = {
  input: ExportCertificateRequestInput;
};


export type MutationSetTemplateVersionApprovedArgs = {
  templateVersionId: Scalars['ID']['input'];
};


export type MutationSetTemplateVersionStatusArgs = {
  status: TemplateVersionStatus;
  templateVersionId: Scalars['ID']['input'];
};


export type MutationSfChangeExportCertificateRequestStatusArgs = {
  input: SfChangeExportCertificateRequestStatusInput;
};


export type MutationSfVerifyExportCertificateRequestProductsArgs = {
  input: SfVerifyExportCertificateRequestProductsInput;
};


export type MutationSplitProductArgs = {
  fromProduct: Scalars['ID']['input'];
  toProducts: Array<B2GProductInput>;
};


export type MutationStartPackagingArgs = {
  productId: Scalars['ID']['input'];
};


export type MutationStartWinemakingArgs = {
  productId: Scalars['ID']['input'];
};


export type MutationTransferProductArgs = {
  id: Scalars['ID']['input'];
  toPremise: Scalars['ID']['input'];
};


export type MutationUpdateDecisionTableArgs = {
  input: DecisionTableRevisionInput;
};


export type MutationUpdateTemplateArgs = {
  template: UpdateTemplateInput;
};

export type NamedCode = {
  __typename?: 'NamedCode';
  code: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type NamedCodeInput = {
  code: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type NamedCodeTemplate = {
  __typename?: 'NamedCodeTemplate';
  code: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type NewReferencedDocument = {
  __typename?: 'NewReferencedDocument';
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type NumberConstrain = {
  __typename?: 'NumberConstrain';
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  step: Scalars['Float']['output'];
};

export enum Optionality {
  Disabled = 'DISABLED',
  Optional = 'OPTIONAL',
  Readonly = 'READONLY',
  Required = 'REQUIRED'
}

export type PackageTemplate = {
  __typename?: 'PackageTemplate';
  bottle_size: MeasureTemplate;
  bottles_per_case: IntTemplate;
  cases: IntTemplate;
  extra_bottles: IntTemplate;
  total_bottles?: Maybe<MeasureTemplate>;
};

export type Packaging = {
  __typename?: 'Packaging';
  availableLitres: Scalars['Float']['output'];
  confirmation?: Maybe<PackagingConfirmation>;
  exportedLitres?: Maybe<Scalars['Float']['output']>;
  runs: Array<PackagingRun>;
  status: PackagingStatus;
  totalPackagedLitres: Scalars['Float']['output'];
  volumeAdjustment?: Maybe<Array<Maybe<ManualVolumeAdjustment>>>;
  wsmp?: Maybe<Scalars['ID']['output']>;
};

export type PackagingConfirmation = {
  __typename?: 'PackagingConfirmation';
  confirmed: Scalars['Boolean']['output'];
  disputeReason?: Maybe<Scalars['String']['output']>;
  disputeResolution?: Maybe<Scalars['String']['output']>;
};

export type PackagingRun = BottlingRunData | FlexiFillingRunData;

export enum PackagingStatus {
  Confirmed = 'CONFIRMED',
  Disputed = 'DISPUTED',
  NotRequested = 'NOT_REQUESTED',
  Requested = 'REQUESTED'
}

export enum PdfGenerationStatus {
  Generated = 'GENERATED',
  InProgress = 'IN_PROGRESS',
  NotGenerated = 'NOT_GENERATED'
}

export type ProcessAttributesResponse = ChemistryTestResponse | Packaging | SensoryProcessRequest | Winemaking;

export type ProcessResponse = {
  __typename?: 'ProcessResponse';
  id: Scalars['ID']['output'];
  task?: Maybe<ProcessTask>;
  type: ProductProcessType;
};

export enum ProcessStatus {
  Active = 'ACTIVE',
  Completed = 'COMPLETED'
}

export type ProcessTask = {
  __typename?: 'ProcessTask';
  assignedTo?: Maybe<Scalars['String']['output']>;
  taskId: Scalars['ID']['output'];
};

export type ProcessType = {
  __typename?: 'ProcessType';
  description: Scalars['String']['output'];
  id: ProductProcessType;
};

export type ProductFieldInput = {
  certificateType?: InputMaybe<CertificateType>;
  commodity?: InputMaybe<Scalars['String']['input']>;
  destinationMarket?: InputMaybe<DestinationMarket>;
};

export type ProductFieldResponse = {
  __typename?: 'ProductFieldResponse';
  certificateType?: Maybe<CertificateType>;
  collection?: Maybe<Scalars['String']['output']>;
  commodity?: Maybe<Scalars['String']['output']>;
  defaultUnit?: Maybe<Scalars['String']['output']>;
  defaultValue?: Maybe<Scalars['String']['output']>;
  destinationMarket?: Maybe<DestinationMarket>;
  fieldId: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  listOfValues?: Maybe<Array<Scalars['String']['output']>>;
  maxStringLength?: Maybe<Scalars['Int']['output']>;
  required: Optionality;
  type?: Maybe<Scalars['String']['output']>;
};

export type ProductFieldResponseInput = {
  defaultUnit?: InputMaybe<Scalars['String']['input']>;
  defaultValue?: InputMaybe<Scalars['String']['input']>;
  fieldId: Scalars['ID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  listOfValues?: InputMaybe<Array<Scalars['String']['input']>>;
  maxStringLength?: InputMaybe<Scalars['Int']['input']>;
  required: Optionality;
};

export type ProductFieldType = DecisionTableBaseInput & {
  __typename?: 'ProductFieldType';
  certificateType?: Maybe<CertificateType>;
  commodity?: Maybe<Scalars['String']['output']>;
  destinationMarket?: Maybe<DestinationMarket>;
  validFrom?: Maybe<Scalars['AWSDateTime']['output']>;
  validUntil?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type ProductFieldsInput = {
  certificateType?: InputMaybe<CertificateType>;
  commodity?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  destination?: InputMaybe<DestinationMarket>;
};

export type ProductProcessFilterInput = {
  processType?: InputMaybe<ProductProcessType>;
  status?: InputMaybe<ProcessStatus>;
};

export type ProductProcessResponse = {
  __typename?: 'ProductProcessResponse';
  attributes: ProcessAttributesResponse;
  confirmationTaskId?: Maybe<Scalars['ID']['output']>;
  processId: Scalars['ID']['output'];
  processType: ProductProcessType;
  requestTaskId?: Maybe<Scalars['ID']['output']>;
  resetTaskId?: Maybe<Scalars['ID']['output']>;
  taskId?: Maybe<Scalars['ID']['output']>;
};

export enum ProductProcessType {
  BottlingRun = 'BOTTLING_RUN',
  Chemistry = 'CHEMISTRY',
  FlexiFilling = 'FLEXI_FILLING',
  Sensory = 'SENSORY',
  WineMaking = 'WINE_MAKING'
}

export type ProductProcessesInput = {
  filter?: InputMaybe<ProductProcessFilterInput>;
  productId: Scalars['ID']['input'];
};

export type Query = {
  __typename?: 'Query';
  attestations?: Maybe<Array<Scalars['String']['output']>>;
  availableProcesses: Array<ProcessType>;
  availableTemplates?: Maybe<Array<AvailableTemplateFields>>;
  certificateTemplateFields?: Maybe<Scalars['AWSJSON']['output']>;
  certificateTemplateFlatFields: Array<TemplateSection>;
  certificateTemplateFlattestFields: Array<TemplateSectionField>;
  customsPermitNumberUsage: CustomsPermitNumberUsage;
  decisionTable?: Maybe<DecisionTable>;
  downloadDocument: TemplateDocumentUrl;
  downloadReferencedDocument: ReferencedDocumentUrl;
  estarOrganisations: Array<EStarOrganisation>;
  executeRules: Array<RulesResponse>;
  exportCertificateAsTemplateValues: SpsCertificateTemplate;
  exportCertificateRequest?: Maybe<B2GExportCertificateRequest>;
  exportCertificateSupportingDocuments: Array<B2GReferencedDocument>;
  findApprovedImportCertificate?: Maybe<TemplateVersion>;
  getApprovedTemplateVersion?: Maybe<TemplateVersion>;
  getExportCertificateRequest?: Maybe<ExportCertificateRequest>;
  getFeatureFlags: Array<Scalars['String']['output']>;
  getImportCertificate?: Maybe<ImportCertificate>;
  /** Return a presigned url to upload a document to the given `key` in the given `fileStore`. */
  getPresignedUrl: Scalars['String']['output'];
  getTemplate?: Maybe<Template>;
  getTemplateVersion?: Maybe<TemplateVersion>;
  importCertificateEmbeddedDocument: ImportCertificateEmbeddedDocument;
  importCertificatePdf: ImportCertificatePdf;
  importCertificateXml: ImportCertificateXml;
  listImportCertificateIntegrations: Array<ImportCertificateIntegration>;
  listImportCertificates: Array<ImportCertificate>;
  numberOfDocuments: Scalars['Int']['output'];
  product: B2GProduct;
  productFields: Array<Maybe<ProductFieldResponse>>;
  productForm: Array<Maybe<FormletField>>;
  productFormlet: Form;
  productProcesses: Array<ProductProcessResponse>;
  referenceDocuments?: Maybe<Array<Maybe<B2GReferencedDocument>>>;
  referencedDocument?: Maybe<ReferencedDocument>;
  salesForceApplicationId?: Maybe<Scalars['ID']['output']>;
  salesForceProductId?: Maybe<SalesForceProductId>;
  searchDecisionTables: Array<DecisionTable>;
  searchTemplateVersions: Array<TemplateVersion>;
  searchTemplates: Array<Template>;
  sfExporter: SalesForceParty;
  testDecisionTableRevision: Array<DecisionTableTestResult>;
  tradecertUserProfile: UserProfile;
  validate?: Maybe<Array<Maybe<B2GValidationErrors>>>;
  validateProduct?: Maybe<Array<Maybe<B2GValidationErrors>>>;
};


export type QueryAttestationsArgs = {
  certificateType: CertificateType;
  commodity: Scalars['String']['input'];
  destination: Scalars['String']['input'];
};


export type QueryAvailableProcessesArgs = {
  productId: Scalars['ID']['input'];
};


export type QueryAvailableTemplatesArgs = {
  commodity: Scalars['String']['input'];
  countryCode: Scalars['String']['input'];
};


export type QueryCertificateTemplateFieldsArgs = {
  commodity?: InputMaybe<Scalars['String']['input']>;
  destinationMarket?: InputMaybe<DestinationMarket>;
  exportCertificateType?: InputMaybe<CertificateType>;
  importCertificateType?: InputMaybe<ImportCertificateType>;
};


export type QueryCertificateTemplateFlatFieldsArgs = {
  commodity?: InputMaybe<Scalars['String']['input']>;
  destinationMarket?: InputMaybe<DestinationMarket>;
  exportCertificateType?: InputMaybe<CertificateType>;
  importCertificateType?: InputMaybe<ImportCertificateType>;
};


export type QueryCertificateTemplateFlattestFieldsArgs = {
  commodity?: InputMaybe<Scalars['String']['input']>;
  destinationMarket?: InputMaybe<DestinationMarket>;
  exportCertificateType?: InputMaybe<CertificateType>;
  importCertificateType?: InputMaybe<ImportCertificateType>;
};


export type QueryDecisionTableArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDownloadDocumentArgs = {
  templateVersionId: Scalars['ID']['input'];
};


export type QueryDownloadReferencedDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEstarOrganisationsArgs = {
  filter?: InputMaybe<EStarOrganisationFilter>;
};


export type QueryExecuteRulesArgs = {
  input: RulesExecutionInput;
};


export type QueryExportCertificateAsTemplateValuesArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExportCertificateRequestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExportCertificateSupportingDocumentsArgs = {
  input: ExportCertificateSupportingDocumentsInput;
};


export type QueryFindApprovedImportCertificateArgs = {
  certificateType: ImportCertificateType;
  commodity?: InputMaybe<Scalars['String']['input']>;
  destinationMarket: DestinationMarket;
};


export type QueryGetApprovedTemplateVersionArgs = {
  certificateType: CertificateType;
  commodity?: InputMaybe<Scalars['String']['input']>;
  destinationMarket: DestinationMarket;
};


export type QueryGetExportCertificateRequestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetImportCertificateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetPresignedUrlArgs = {
  fileStore: Scalars['String']['input'];
  key: Scalars['String']['input'];
};


export type QueryGetTemplateArgs = {
  templateId: Scalars['ID']['input'];
};


export type QueryGetTemplateVersionArgs = {
  templateVersionId: Scalars['ID']['input'];
};


export type QueryImportCertificateEmbeddedDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryImportCertificatePdfArgs = {
  input: ImportCertificatePdfInput;
};


export type QueryImportCertificateXmlArgs = {
  id: Scalars['ID']['input'];
  inline?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryNumberOfDocumentsArgs = {
  collectionName: Scalars['String']['input'];
};


export type QueryProductArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductFieldsArgs = {
  input: ProductFieldsInput;
};


export type QueryProductFormArgs = {
  input: ProductFieldsInput;
};


export type QueryProductFormletArgs = {
  input: ProductFieldsInput;
};


export type QueryProductProcessesArgs = {
  input: ProductProcessesInput;
};


export type QueryReferenceDocumentsArgs = {
  exportRequestId: Scalars['ID']['input'];
};


export type QueryReferencedDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySalesForceApplicationIdArgs = {
  requestId: Scalars['ID']['input'];
};


export type QuerySalesForceProductIdArgs = {
  productId: Scalars['ID']['input'];
};


export type QuerySearchDecisionTablesArgs = {
  filter?: InputMaybe<DecisionTableFilter>;
};


export type QuerySearchTemplateVersionsArgs = {
  filter?: InputMaybe<TemplateFilter>;
  optionalFilter?: InputMaybe<TemplateOptionalFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySearchTemplatesArgs = {
  filter?: InputMaybe<TemplateFilter>;
  optionalFilter?: InputMaybe<TemplateOptionalFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySfExporterArgs = {
  certificateType?: InputMaybe<CertificateType>;
  id: Scalars['ID']['input'];
};


export type QueryTestDecisionTableRevisionArgs = {
  input: DecisionTableRevisionInput;
};


export type QueryTradecertUserProfileArgs = {
  certificateType?: InputMaybe<CertificateType>;
  id?: InputMaybe<Scalars['ID']['input']>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
};


export type QueryValidateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryValidateProductArgs = {
  id: Scalars['ID']['input'];
};

export type ReferencedDocument = {
  __typename?: 'ReferencedDocument';
  filename?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  uploadInProgress: Scalars['Boolean']['output'];
};

export type ReferencedDocumentUrl = {
  __typename?: 'ReferencedDocumentUrl';
  documentName: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ReindexResponse = {
  __typename?: 'ReindexResponse';
  alias: Scalars['String']['output'];
  collectionName: Scalars['String']['output'];
  functionName: Scalars['String']['output'];
};

export type RequestConfirmationInput = {
  processId: Scalars['ID']['input'];
  taskId: Scalars['ID']['input'];
  wsmp: Scalars['ID']['input'];
};

export type RequestConfirmationResponse = {
  __typename?: 'RequestConfirmationResponse';
  processId: Scalars['ID']['output'];
  wsmp: Scalars['ID']['output'];
};

export type ResetConfirmationRequestInput = {
  processId: Scalars['ID']['input'];
  taskId: Scalars['ID']['input'];
};

export type RulesExecutionInput = {
  decisionTableId: Scalars['ID']['input'];
  declarations?: InputMaybe<DeclarationsInput>;
  eligibility?: InputMaybe<EligibilityInput>;
  exportCertificateWorkflow?: InputMaybe<ExportCertificateWorkflowInput>;
  importConnections?: InputMaybe<ImportConnectionsInput>;
  productField?: InputMaybe<ProductFieldInput>;
  wineAnalytesTolerances?: InputMaybe<WineAnalytesTolerancesResponseInput>;
  wineProductField?: InputMaybe<WineProductFieldInput>;
};

export type RulesInput = DeclarationsType | EligibilityType | ExportCertificateWorkflowType | ImportConnectionsType | ProductFieldType | WineAnalytesTolerancesType | WineProductFieldType;

export type RulesResponse = DeclarationsResponse | EligibilityResponse | ExportCertificateWorkflowResponse | ImportConnectionsResponse | ProductFieldResponse | WineAnalytesTolerancesResponse;

export type SpsAddress = {
  __typename?: 'SPSAddress';
  city?: Maybe<Scalars['String']['output']>;
  city_id?: Maybe<Scalars['ID']['output']>;
  country_id?: Maybe<Scalars['ID']['output']>;
  country_name?: Maybe<Scalars['String']['output']>;
  country_subdivision_id?: Maybe<Scalars['ID']['output']>;
  country_subdivision_name?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  postcode_code?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  type_code?: Maybe<AddressTypeCode>;
};

export type SpsAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  city_id?: InputMaybe<Scalars['ID']['input']>;
  country_id?: InputMaybe<Scalars['ID']['input']>;
  country_name?: InputMaybe<Scalars['String']['input']>;
  country_subdivision_id?: InputMaybe<Scalars['ID']['input']>;
  country_subdivision_name?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  postcode_code?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  type_code?: InputMaybe<AddressTypeCode>;
};

export type SpsAuthentication = {
  __typename?: 'SPSAuthentication';
  actual?: Maybe<Scalars['AWSDateTime']['output']>;
  included_clauses: Array<SpsClause>;
  issue_location?: Maybe<SpsLocation>;
  location_provider_party?: Maybe<SpsParty>;
  provider_party?: Maybe<SpsParty>;
  type_code?: Maybe<NamedCode>;
};

export type SpsAuthenticationInput = {
  actual?: InputMaybe<Scalars['AWSDateTime']['input']>;
  included_clauses?: InputMaybe<Array<SpsClauseInput>>;
  issue_location?: InputMaybe<SpsLocationInput>;
  location_provider_party?: InputMaybe<SpsPartyInput>;
  provider_party?: InputMaybe<SpsPartyInput>;
  type_code?: InputMaybe<NamedCodeInput>;
};

export type SpsCertificate = {
  __typename?: 'SPSCertificate';
  consignment: SpsConsignment;
  exchanged_document: SpsExchangedDocument;
};

export type SpsClassification = {
  __typename?: 'SPSClassification';
  class_code?: Maybe<Scalars['String']['output']>;
  class_name: Scalars['String']['output'];
  system_id?: Maybe<Scalars['String']['output']>;
  system_name: Scalars['String']['output'];
};

export type SpsClassificationInput = {
  class_code?: InputMaybe<Scalars['String']['input']>;
  class_name: Scalars['String']['input'];
  system_id?: InputMaybe<Scalars['String']['input']>;
  system_name: Scalars['String']['input'];
};

export type SpsClause = {
  __typename?: 'SPSClause';
  content?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SpsClauseInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SpsConsignment = {
  __typename?: 'SPSConsignment';
  availability_due_date_time?: Maybe<Scalars['AWSDateTime']['output']>;
  carrier?: Maybe<SpsParty>;
  consignee?: Maybe<SpsParty>;
  consignee_receipt_location?: Maybe<SpsLocation>;
  consignor?: Maybe<SpsParty>;
  customs_transit_agent?: Maybe<SpsParty>;
  delivery?: Maybe<SpsParty>;
  despatch?: Maybe<SpsParty>;
  examination_event?: Maybe<SpsEvent>;
  export_country?: Maybe<SpsCountry>;
  export_exit_date_time?: Maybe<Scalars['AWSDateTime']['output']>;
  exporter_reference?: Maybe<Scalars['String']['output']>;
  gross_weight?: Maybe<Scalars['String']['output']>;
  import_country?: Maybe<SpsCountry>;
  items: Array<SpsConsignmentItem>;
  loading_base_port_location?: Maybe<SpsLocation>;
  main_carriage_transport_movements: Array<SpsTransportMovement>;
  reexport_country?: Maybe<SpsCountry>;
  ship_stores_indicator?: Maybe<IndicatorType>;
  storage_events: Array<SpsEvent>;
  transit_countries: Array<SpsCountry>;
  transit_locations: Array<SpsLocation>;
  unloading_base_port_location?: Maybe<SpsLocation>;
  utilized_transport_equipment: Array<SpsTransportEquipment>;
  wine?: Maybe<SpsWineConsignment>;
};

export type SpsConsignmentInput = {
  availability_due_date_time?: InputMaybe<Scalars['AWSDateTime']['input']>;
  carrier?: InputMaybe<SpsPartyInput>;
  consignee?: InputMaybe<SpsPartyInput>;
  consignee_receipt_location?: InputMaybe<SpsLocationInput>;
  consignor?: InputMaybe<SpsPartyInput>;
  customs_transit_agent?: InputMaybe<SpsPartyInput>;
  delivery?: InputMaybe<SpsPartyInput>;
  despatch?: InputMaybe<SpsPartyInput>;
  examination_event?: InputMaybe<SpsEventInput>;
  export_exit_date_time?: InputMaybe<Scalars['AWSDateTime']['input']>;
  exporter_reference?: InputMaybe<Scalars['String']['input']>;
  gross_weight?: InputMaybe<Scalars['String']['input']>;
  import_country?: InputMaybe<SpsCountryInput>;
  items?: InputMaybe<Array<SpsConsignmentItemInput>>;
  loading_base_port_location?: InputMaybe<SpsLocationInput>;
  main_carriage_transport_movements?: InputMaybe<Array<SpsTransportMovementInput>>;
  storage_events?: InputMaybe<Array<SpsEventInput>>;
  transit_countries?: InputMaybe<Array<SpsCountryInput>>;
  transit_locations?: InputMaybe<Array<SpsLocationInput>>;
  unloading_base_port_location?: InputMaybe<SpsLocationInput>;
  utilized_transport_equipment?: InputMaybe<Array<SpsTransportEquipmentInput>>;
};

export type SpsConsignmentItem = {
  __typename?: 'SPSConsignmentItem';
  gross_weight?: Maybe<Scalars['String']['output']>;
  items: Array<SpsTradeLineItem>;
  nature_identification_cargo: Array<CargoTypeClassificationCode>;
};

export type SpsConsignmentItemInput = {
  gross_weight?: InputMaybe<Scalars['String']['input']>;
  items: Array<SpsTradeLineItemInput>;
  nature_identification_cargo?: InputMaybe<Array<CargoTypeClassificationCode>>;
};

export type SpsContact = {
  __typename?: 'SPSContact';
  person_name?: Maybe<Scalars['String']['output']>;
};

export type SpsContactInput = {
  person_name?: InputMaybe<Scalars['String']['input']>;
};

export type SpsCountry = {
  __typename?: 'SPSCountry';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  subdivision?: Maybe<SpsCountrySubDivision>;
};

export type SpsCountryInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  subdivision?: InputMaybe<SpsCountrySubDivisionInput>;
};

export type SpsCountrySubDivision = {
  __typename?: 'SPSCountrySubDivision';
  hierarchicalLevelCode?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SpsCountrySubDivisionInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SpsEvent = {
  __typename?: 'SPSEvent';
  occurrence_location?: Maybe<SpsLocation>;
};

export type SpsEventInput = {
  occurrence_location?: InputMaybe<SpsLocationInput>;
};

export type SpsExchangedDocument = {
  __typename?: 'SPSExchangedDocument';
  certificate_id: Scalars['String']['output'];
  certifier?: Maybe<SpsAuthentication>;
  customs_permit_number?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  included_notes: Array<SpsNote>;
  issue_date_time: Scalars['AWSDateTime']['output'];
  issuer: SpsParty;
  name: Scalars['String']['output'];
  referenced_documents: Array<SpsReferencedDocument>;
  signatories: Array<SpsAuthentication>;
  status_code: Scalars['String']['output'];
  type_code: Scalars['String']['output'];
  verifiers: Array<SpsAuthentication>;
};

export type SpsLocation = {
  __typename?: 'SPSLocation';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SpsLocationInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SpsNote = {
  __typename?: 'SPSNote';
  content: Scalars['String']['output'];
  content_code?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  subject_code?: Maybe<Scalars['String']['output']>;
};

export type SpsNoteInput = {
  content: Scalars['String']['input'];
  content_code?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  subject_code?: InputMaybe<Scalars['String']['input']>;
};

export type SpsPackage = {
  __typename?: 'SPSPackage';
  level_code?: Maybe<Scalars['String']['output']>;
  nominal_gross_volume?: Maybe<Scalars['String']['output']>;
  nominal_gross_weight?: Maybe<Scalars['String']['output']>;
  physical_shipping_marks: Array<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['String']['output']>;
  type_code?: Maybe<NamedCode>;
};

export type SpsPackageInput = {
  level_code?: InputMaybe<Scalars['String']['input']>;
  nominal_gross_volume?: InputMaybe<Scalars['String']['input']>;
  nominal_gross_weight?: InputMaybe<Scalars['String']['input']>;
  physical_shipping_marks?: InputMaybe<Array<Scalars['String']['input']>>;
  quantity?: InputMaybe<Scalars['String']['input']>;
  type_code?: InputMaybe<NamedCodeInput>;
};

export type SpsParty = {
  __typename?: 'SPSParty';
  defined_contacts: Array<SpsContact>;
  name: Scalars['String']['output'];
  party_id?: Maybe<Scalars['String']['output']>;
  role_code?: Maybe<Scalars['String']['output']>;
  specified_address?: Maybe<SpsAddress>;
  specified_person?: Maybe<SpsPerson>;
  type_code?: Maybe<Scalars['Int']['output']>;
};

export type SpsPartyInput = {
  defined_contacts?: InputMaybe<Array<SpsContactInput>>;
  name: Scalars['String']['input'];
  party_id?: InputMaybe<Scalars['String']['input']>;
  role_code?: InputMaybe<Scalars['String']['input']>;
  specified_address?: InputMaybe<SpsAddressInput>;
  specified_person?: InputMaybe<SpsPersonInput>;
  type_code?: InputMaybe<Scalars['Int']['input']>;
};

export type SpsPeriod = {
  __typename?: 'SPSPeriod';
  duration?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['AWSDateTime']['output']>;
  start?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type SpsPeriodInput = {
  duration?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['AWSDateTime']['input']>;
  start?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SpsPerson = {
  __typename?: 'SPSPerson';
  attained_qualifications: Array<SpsQualification>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SpsPersonInput = {
  attained_qualifications?: InputMaybe<Array<SpsQualificationInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SpsProcess = {
  __typename?: 'SPSProcess';
  applicable_process_characteristics: Array<SpsProcessCharacteristic>;
  completion_period?: Maybe<SpsPeriod>;
  operation_country?: Maybe<SpsCountry>;
  operator_party?: Maybe<SpsParty>;
  type_code?: Maybe<NamedCode>;
};

export type SpsProcessCharacteristic = {
  __typename?: 'SPSProcessCharacteristic';
  description?: Maybe<Scalars['String']['output']>;
  maximum?: Maybe<Scalars['String']['output']>;
  minimum?: Maybe<Scalars['String']['output']>;
  type_code?: Maybe<NamedCode>;
  value?: Maybe<Scalars['String']['output']>;
};

export type SpsProcessCharacteristicInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  maximum?: InputMaybe<Scalars['String']['input']>;
  minimum?: InputMaybe<Scalars['String']['input']>;
  type_code?: InputMaybe<NamedCodeInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type SpsProcessInput = {
  applicable_process_characteristics?: InputMaybe<Array<SpsProcessCharacteristicInput>>;
  completion_period?: InputMaybe<SpsPeriodInput>;
  operation_country?: InputMaybe<SpsCountryInput>;
  operator_party?: InputMaybe<SpsPartyInput>;
  type_code?: InputMaybe<NamedCodeInput>;
};

export type SpsQualification = {
  __typename?: 'SPSQualification';
  abbreviated_name?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SpsQualificationInput = {
  abbreviated_name?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SpsReferencedDocument = {
  __typename?: 'SPSReferencedDocument';
  checksum?: Maybe<Scalars['String']['output']>;
  document_id?: Maybe<Scalars['ID']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  filetype?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  information?: Maybe<Scalars['String']['output']>;
  issued?: Maybe<Scalars['AWSDateTime']['output']>;
  relationship_type_code: Scalars['String']['output'];
  type_code?: Maybe<Scalars['String']['output']>;
};

export type SpsSeal = {
  __typename?: 'SPSSeal';
  issuing_party?: Maybe<SpsParty>;
  maximum_id?: Maybe<Scalars['String']['output']>;
  seal_id: Scalars['String']['output'];
};

export type SpsSealInput = {
  issuing_party?: InputMaybe<SpsPartyInput>;
  maximum_id?: InputMaybe<Scalars['String']['input']>;
  seal_id: Scalars['String']['input'];
};

export type SpsTemperature = {
  __typename?: 'SPSTemperature';
  maximum?: Maybe<Scalars['String']['output']>;
  minimum?: Maybe<Scalars['String']['output']>;
  type_code?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type SpsTemperatureInput = {
  maximum?: InputMaybe<Scalars['String']['input']>;
  minimum?: InputMaybe<Scalars['String']['input']>;
  type_code?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type SpsTradeLineItem = {
  __typename?: 'SPSTradeLineItem';
  additional_information: Array<SpsNote>;
  applied_processes: Array<SpsProcess>;
  asserted_authentications: Array<SpsAuthentication>;
  associated_transport_equipment: Array<SpsTransportEquipment>;
  brand_name?: Maybe<Scalars['String']['output']>;
  classifications: Array<SpsClassification>;
  cn_code?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  commodity?: Maybe<Scalars['String']['output']>;
  common_name?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  expiry_date_time?: Maybe<Scalars['AWSDateTime']['output']>;
  gross_volume?: Maybe<Scalars['String']['output']>;
  gross_weight?: Maybe<Scalars['String']['output']>;
  hs_code?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  import_certificate_extract?: Maybe<Array<ImportCertificateExtractItem>>;
  import_certificate_measurement?: Maybe<Array<ImportCertificateExtractMeasurement>>;
  import_permit_number?: Maybe<Scalars['String']['output']>;
  intended_use?: Maybe<Scalars['String']['output']>;
  net_volume?: Maybe<Scalars['String']['output']>;
  net_weight?: Maybe<Scalars['String']['output']>;
  origin_countries?: Maybe<Array<SpsCountry>>;
  origin_country?: Maybe<SpsCountry>;
  origin_locations: Array<SpsLocation>;
  other_quantity?: Maybe<Scalars['String']['output']>;
  physical_packages: Array<SpsPackage>;
  production_batch_id?: Maybe<Scalars['String']['output']>;
  referenced_documents: Array<SpsReferencedDocument>;
  requestor_id?: Maybe<Scalars['String']['output']>;
  scientific_name?: Maybe<Scalars['String']['output']>;
  sequence?: Maybe<Scalars['Int']['output']>;
  verified: Scalars['Boolean']['output'];
  wine?: Maybe<B2GWineFields>;
};

export type SpsTradeLineItemInput = {
  additional_information?: InputMaybe<Array<SpsNoteInput>>;
  applied_processes?: InputMaybe<Array<SpsProcessInput>>;
  asserted_authentications?: InputMaybe<Array<SpsAuthenticationInput>>;
  associated_transport_equipment?: InputMaybe<Array<SpsTransportEquipmentInput>>;
  brand_name?: InputMaybe<Scalars['String']['input']>;
  classifications?: InputMaybe<Array<SpsClassificationInput>>;
  commodity?: InputMaybe<Scalars['String']['input']>;
  common_name?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  expiry_date_time?: InputMaybe<Scalars['AWSDateTime']['input']>;
  gross_volume?: InputMaybe<Scalars['String']['input']>;
  gross_weight?: InputMaybe<Scalars['String']['input']>;
  hs_code?: InputMaybe<Scalars['String']['input']>;
  import_permit_number?: InputMaybe<Scalars['String']['input']>;
  intended_use?: InputMaybe<Scalars['String']['input']>;
  net_volume?: InputMaybe<Scalars['String']['input']>;
  net_weight?: InputMaybe<Scalars['String']['input']>;
  origin_country?: InputMaybe<SpsCountryInput>;
  origin_locations?: InputMaybe<Array<SpsLocationInput>>;
  physical_packages?: InputMaybe<Array<SpsPackageInput>>;
  production_batch_id?: InputMaybe<Scalars['String']['input']>;
  requestor_id?: InputMaybe<Scalars['String']['input']>;
  scientific_name?: InputMaybe<Scalars['String']['input']>;
  sequence?: InputMaybe<Scalars['Int']['input']>;
};

export type SpsTransportEquipment = {
  __typename?: 'SPSTransportEquipment';
  affixed_seals: Array<SpsSeal>;
  temperature_settings: Array<SpsTemperature>;
  transport_equipment_id: Scalars['String']['output'];
};

export type SpsTransportEquipmentInput = {
  affixed_seals?: InputMaybe<Array<SpsSealInput>>;
  temperature_settings?: InputMaybe<Array<SpsTemperatureInput>>;
  transport_equipment_id: Scalars['String']['input'];
};

export type SpsTransportMovement = {
  __typename?: 'SPSTransportMovement';
  mode: TransportModeCode;
  mode_name?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  used_transport_means?: Maybe<Scalars['String']['output']>;
};

export type SpsTransportMovementInput = {
  mode: TransportModeCode;
  reference?: InputMaybe<Scalars['String']['input']>;
  used_transport_means?: InputMaybe<Scalars['String']['input']>;
};

export type SpsTransportMovementTemplate = {
  __typename?: 'SPSTransportMovementTemplate';
  id?: Maybe<Scalars['ID']['output']>;
  mode: TransportMovementCodeTemplate;
  used_transport_means?: Maybe<Scalars['String']['output']>;
};

export type SpsWineConsignment = {
  __typename?: 'SPSWineConsignment';
  is_bulk?: Maybe<Scalars['String']['output']>;
};

export type SalesForceAddress = {
  __typename?: 'SalesForceAddress';
  addressType: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  street: Scalars['String']['output'];
};

export type SalesForceContact = {
  __typename?: 'SalesForceContact';
  emails: Array<SalesForceEmail>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phones: Array<SalesForcePhone>;
  role?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type SalesForceEmail = {
  __typename?: 'SalesForceEmail';
  emailAddress: Scalars['String']['output'];
  emailType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
};

export type SalesForceIdentifier = {
  __typename?: 'SalesForceIdentifier';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type SalesForceParty = {
  __typename?: 'SalesForceParty';
  addresses: Array<SalesForceAddress>;
  contacts: Array<SalesForceContact>;
  id: Scalars['String']['output'];
  identifiers: Array<SalesForceIdentifier>;
  name: Scalars['String']['output'];
  nzbn?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  tradingAs?: Maybe<Scalars['String']['output']>;
};

export type SalesForcePhone = {
  __typename?: 'SalesForcePhone';
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  phoneType: Scalars['String']['output'];
  telephoneNumber: Scalars['String']['output'];
};

export type SalesForceProductId = {
  __typename?: 'SalesForceProductId';
  productId: Scalars['ID']['output'];
  productRevisionId: Scalars['ID']['output'];
  sfProductId?: Maybe<Scalars['ID']['output']>;
};

export type SearchApiKey = {
  __typename?: 'SearchApiKey';
  apiKey: Scalars['String']['output'];
  expires: Scalars['AWSDateTime']['output'];
};

export type SearchOptions = {
  fromDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  startWithEmptyCollection: Scalars['Boolean']['input'];
};

export type SensoryProcessInput = {
  assessmentDate: Scalars['AWSDateTime']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  disputed: Scalars['Boolean']['input'];
  disputedReason?: InputMaybe<WineDisputedReason>;
  productId: Scalars['ID']['input'];
  reference: Scalars['String']['input'];
  winemaker: B2GContactInput;
};

export type SensoryProcessRequest = {
  __typename?: 'SensoryProcessRequest';
  assessmentDate: Scalars['AWSDateTime']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  disputed: Scalars['Boolean']['output'];
  disputedReason?: Maybe<WineDisputedReason>;
  id: Scalars['ID']['output'];
  productId: Scalars['ID']['output'];
  reference: Scalars['String']['output'];
  winemaker: B2GContact;
};

export type SfChangeExportCertificateRequestStatusInput = {
  reason_for_status_change?: InputMaybe<Scalars['String']['input']>;
  requestor?: InputMaybe<Scalars['String']['input']>;
  requestor_id: Scalars['String']['input'];
  status: ExportCertificateStatus;
};

export type SfVerifyExportCertificateRequestProductsInput = {
  products: Array<SfVerifyProductInput>;
  requestor?: InputMaybe<Scalars['String']['input']>;
};

export type SfVerifyProductInput = {
  comments?: InputMaybe<Scalars['String']['input']>;
  requestor_product_id: Scalars['String']['input'];
  verified: Scalars['Boolean']['input'];
};

export type SpsAddressTemplate = {
  __typename?: 'SpsAddressTemplate';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  city_id?: Maybe<Scalars['ID']['output']>;
  country_id?: Maybe<Scalars['ID']['output']>;
  country_name?: Maybe<Scalars['String']['output']>;
  country_subdivision_id?: Maybe<Scalars['ID']['output']>;
  country_subdivision_name?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  postcode_code?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  street_with_commas?: Maybe<Scalars['String']['output']>;
  street_with_line_breaks?: Maybe<Scalars['String']['output']>;
  type_code?: Maybe<Scalars['String']['output']>;
  with_line_breaks?: Maybe<Scalars['String']['output']>;
};

export type SpsAuthenticationTemplate = {
  __typename?: 'SpsAuthenticationTemplate';
  actual?: Maybe<DateTimeTemplate>;
  ic_label?: Maybe<Scalars['String']['output']>;
  ic_value?: Maybe<Scalars['String']['output']>;
  included_clauses?: Maybe<Array<SpsClauseTemplate>>;
  issue_location?: Maybe<SpsLocationTemplate>;
  location_provider_party?: Maybe<SpsPartyTemplate>;
  provider_party?: Maybe<SpsPartyTemplate>;
  type_code?: Maybe<NamedCodeTemplate>;
};

export type SpsCertificateTemplate = {
  __typename?: 'SpsCertificateTemplate';
  consignment: SpsConsignmentTemplate;
  exchanged_document: SpsExchangedDocumentTemplate;
};

export type SpsClassificationTemplate = {
  __typename?: 'SpsClassificationTemplate';
  class_code?: Maybe<Scalars['String']['output']>;
  class_name: Scalars['String']['output'];
  system_id?: Maybe<Scalars['String']['output']>;
  system_name: Scalars['String']['output'];
};

export type SpsClauseTemplate = {
  __typename?: 'SpsClauseTemplate';
  content?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SpsConsignmentItemTemplate = {
  __typename?: 'SpsConsignmentItemTemplate';
  gross_weight?: Maybe<Scalars['String']['output']>;
  items: Array<SpsTradeLineItemTemplate>;
  nature_identification_cargo?: Maybe<Array<Scalars['String']['output']>>;
};

export type SpsConsignmentTemplate = {
  __typename?: 'SpsConsignmentTemplate';
  authentication_parties?: Maybe<Array<SpsPartyTemplate>>;
  availability_due_date_time?: Maybe<DateTimeTemplate>;
  carrier?: Maybe<SpsPartyTemplate>;
  consignee?: Maybe<SpsPartyTemplate>;
  consignee_receipt_location?: Maybe<SpsLocationTemplate>;
  consignor?: Maybe<SpsPartyTemplate>;
  customs_transit_agent?: Maybe<SpsPartyTemplate>;
  delivery?: Maybe<SpsPartyTemplate>;
  despatch?: Maybe<SpsPartyTemplate>;
  examination_event?: Maybe<SpsEventTemplate>;
  export_country?: Maybe<SpsCountryTemplate>;
  export_exit_date_time?: Maybe<DateTimeTemplate>;
  gross_weight?: Maybe<MeasureTemplate>;
  id?: Maybe<Scalars['ID']['output']>;
  import_country?: Maybe<SpsCountryTemplate>;
  items: Array<SpsConsignmentItemTemplate>;
  loading_base_port_location?: Maybe<SpsLocationTemplate>;
  main_carriage_transport_movements?: Maybe<Array<SpsTransportMovementTemplate>>;
  party_table?: Maybe<Array<CombinedPartyTemplate>>;
  processor_parties?: Maybe<Array<SpsPartyTemplate>>;
  reexport_country?: Maybe<SpsCountryTemplate>;
  storage_events?: Maybe<Array<SpsEventTemplate>>;
  transit_countries?: Maybe<Array<SpsCountryTemplate>>;
  transit_locations?: Maybe<Array<SpsLocationTemplate>>;
  unique_production_batch_ids?: Maybe<IDsTemplate>;
  unique_seal_number_ids: Scalars['String']['output'];
  unique_transport_equipment_ids?: Maybe<IDsTemplate>;
  unloading_base_port_location?: Maybe<SpsLocationTemplate>;
  utilized_transport_equipment?: Maybe<Array<SpsTransportEquipmentTemplate>>;
  verifiers?: Maybe<Array<SpsAuthenticationTemplate>>;
  wine?: Maybe<SpsWineConsignmentTemplate>;
};

export type SpsContactTemplate = {
  __typename?: 'SpsContactTemplate';
  person_name?: Maybe<Scalars['String']['output']>;
};

export type SpsCountrySubDivisionTemplate = {
  __typename?: 'SpsCountrySubDivisionTemplate';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SpsCountryTemplate = {
  __typename?: 'SpsCountryTemplate';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  subdivision?: Maybe<SpsCountrySubDivisionTemplate>;
};

export type SpsEventTemplate = {
  __typename?: 'SpsEventTemplate';
  occurrence_location?: Maybe<SpsLocationTemplate>;
};

export type SpsExchangedDocumentTemplate = {
  __typename?: 'SpsExchangedDocumentTemplate';
  additional_declarations?: Maybe<Scalars['String']['output']>;
  additional_information?: Maybe<Scalars['String']['output']>;
  additional_official_information?: Maybe<Scalars['String']['output']>;
  certifier?: Maybe<SpsAuthenticationTemplate>;
  customs_permit_number?: Maybe<Scalars['String']['output']>;
  date_of_inspection?: Maybe<DateTimeTemplate>;
  description?: Maybe<Scalars['String']['output']>;
  distinguishing_marks?: Maybe<Scalars['String']['output']>;
  financial_liability?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  import_permit?: Maybe<Scalars['String']['output']>;
  issue_date_time?: Maybe<DateTimeTemplate>;
  issuer?: Maybe<SpsPartyTemplate>;
  name?: Maybe<Scalars['String']['output']>;
  replacement_certificate?: Maybe<SpsReplacementCertificateTemplate>;
  signatories?: Maybe<Array<Maybe<SpsAuthenticationTemplate>>>;
  status_code?: Maybe<Scalars['String']['output']>;
  status_name?: Maybe<Scalars['String']['output']>;
  type_code?: Maybe<Scalars['String']['output']>;
  type_name?: Maybe<Scalars['String']['output']>;
};

export type SpsLocationTemplate = {
  __typename?: 'SpsLocationTemplate';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SpsNoteTemplate = {
  __typename?: 'SpsNoteTemplate';
  content?: Maybe<Scalars['String']['output']>;
  content_code?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  subject_code?: Maybe<Scalars['String']['output']>;
};

export type SpsPackageTemplate = {
  __typename?: 'SpsPackageTemplate';
  level_code?: Maybe<Scalars['String']['output']>;
  nominal_gross_volume?: Maybe<MeasureTemplate>;
  nominal_gross_weight?: Maybe<MeasureTemplate>;
  physical_shipping_marks?: Maybe<Array<Scalars['String']['output']>>;
  quantity?: Maybe<DecimalTemplate>;
  type_code?: Maybe<NamedCodeTemplate>;
};

export type SpsPartyTemplate = {
  __typename?: 'SpsPartyTemplate';
  defined_contacts?: Maybe<Array<SpsContactTemplate>>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  role_code?: Maybe<Scalars['String']['output']>;
  specified_address?: Maybe<SpsAddressTemplate>;
  specified_person?: Maybe<SpsPersonTemplate>;
  type_code?: Maybe<Scalars['String']['output']>;
};

export type SpsPeriodTemplate = {
  __typename?: 'SpsPeriodTemplate';
  duration?: Maybe<MeasureTemplate>;
  end?: Maybe<DateTimeTemplate>;
  start?: Maybe<DateTimeTemplate>;
};

export type SpsPersonTemplate = {
  __typename?: 'SpsPersonTemplate';
  attained_qualifications?: Maybe<Array<SpsQualificationTemplate>>;
  name?: Maybe<Scalars['String']['output']>;
  signature?: Maybe<Scalars['String']['output']>;
};

export type SpsProcessCharacteristicTemplate = {
  __typename?: 'SpsProcessCharacteristicTemplate';
  description?: Maybe<Scalars['String']['output']>;
  maximum?: Maybe<MeasureTemplate>;
  minimum?: Maybe<MeasureTemplate>;
  type_code?: Maybe<NamedCodeTemplate>;
  value?: Maybe<MeasureTemplate>;
};

export type SpsProcessTemplate = {
  __typename?: 'SpsProcessTemplate';
  applicable_process_characteristics?: Maybe<Array<SpsProcessCharacteristicTemplate>>;
  completion_period?: Maybe<SpsPeriodTemplate>;
  operation_country?: Maybe<SpsCountryTemplate>;
  operator_party?: Maybe<SpsPartyTemplate>;
  type_code?: Maybe<NamedCodeTemplate>;
};

export type SpsQualificationTemplate = {
  __typename?: 'SpsQualificationTemplate';
  abbreviated_name?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SpsReplacementCertificateTemplate = {
  __typename?: 'SpsReplacementCertificateTemplate';
  additional_declaration?: Maybe<Scalars['String']['output']>;
  replaced_certificate_issue_date?: Maybe<DateTimeTemplate>;
  replaced_certificate_number?: Maybe<Scalars['String']['output']>;
  replacement_reason?: Maybe<Scalars['String']['output']>;
};

export type SpsSealTemplate = {
  __typename?: 'SpsSealTemplate';
  id: Scalars['String']['output'];
  issuing_party?: Maybe<SpsPartyTemplate>;
  maximum_id?: Maybe<Scalars['String']['output']>;
};

export type SpsTemperatureTemplate = {
  __typename?: 'SpsTemperatureTemplate';
  maximum?: Maybe<MeasureTemplate>;
  minimum?: Maybe<MeasureTemplate>;
  type_code?: Maybe<Scalars['String']['output']>;
  value?: Maybe<MeasureTemplate>;
};

export type SpsTradeLineItemTemplate = {
  __typename?: 'SpsTradeLineItemTemplate';
  additional_information?: Maybe<Array<SpsNoteTemplate>>;
  applied_processes?: Maybe<Array<SpsProcessTemplate>>;
  asserted_authentications?: Maybe<Array<SpsAuthenticationTemplate>>;
  associated_transport_equipment?: Maybe<Array<SpsTransportEquipmentTemplate>>;
  brand_name?: Maybe<Scalars['String']['output']>;
  classifications?: Maybe<Array<SpsClassificationTemplate>>;
  cn_code?: Maybe<Scalars['String']['output']>;
  commodity?: Maybe<Scalars['String']['output']>;
  common_name?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expiry_date_time?: Maybe<DateTimeTemplate>;
  gross_volume?: Maybe<MeasureTemplate>;
  gross_weight?: Maybe<MeasureTemplate>;
  hs_code?: Maybe<Scalars['String']['output']>;
  import_certificate_extract?: Maybe<ImportCertificateExtractTemplate>;
  import_certificate_measurement?: Maybe<ImportCertificateMeasurementTemplate>;
  import_permit_number?: Maybe<Scalars['String']['output']>;
  intended_use?: Maybe<Scalars['String']['output']>;
  net_volume?: Maybe<MeasureTemplate>;
  net_weight?: Maybe<MeasureTemplate>;
  origin_country?: Maybe<SpsCountryTemplate>;
  origin_locations?: Maybe<Array<SpsLocationTemplate>>;
  packaging?: Maybe<ImportCertificatePackagingTemplate>;
  physical_packages?: Maybe<Array<SpsPackageTemplate>>;
  production_batch_id?: Maybe<Scalars['String']['output']>;
  scientific_name?: Maybe<Scalars['String']['output']>;
  sequence?: Maybe<Scalars['String']['output']>;
  treatments?: Maybe<TreatmentTemplate>;
  wine?: Maybe<WineFieldsTemplate>;
};

export type SpsTransportEquipmentTemplate = {
  __typename?: 'SpsTransportEquipmentTemplate';
  affixed_seals?: Maybe<Array<SpsSealTemplate>>;
  id: Scalars['String']['output'];
  temperature_settings?: Maybe<Array<SpsTemperatureTemplate>>;
};

export type SpsWineConsignmentTemplate = {
  __typename?: 'SpsWineConsignmentTemplate';
  is_bulk?: Maybe<Scalars['String']['output']>;
};

export type Template = {
  __typename?: 'Template';
  approvedVersion?: Maybe<TemplateVersion>;
  id: Scalars['ID']['output'];
  importantVersions?: Maybe<Array<Maybe<TemplateVersion>>>;
  lastVersion: TemplateVersion;
  versions?: Maybe<Array<Maybe<TemplateVersion>>>;
};

export type TemplateDocumentUrl = {
  __typename?: 'TemplateDocumentUrl';
  documentName: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type TemplateFilter = {
  q?: InputMaybe<Scalars['String']['input']>;
};

export type TemplateHistory = {
  __typename?: 'TemplateHistory';
  fieldName: Scalars['String']['output'];
  from: Scalars['String']['output'];
  to: Scalars['String']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
  updatedBy: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type TemplateOptionalFilter = {
  commodity?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  destinationMarket?: InputMaybe<Array<InputMaybe<DestinationMarket>>>;
  effectiveDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  effectiveDateEnd?: InputMaybe<Scalars['AWSDateTime']['input']>;
  effectiveDateStart?: InputMaybe<Scalars['AWSDateTime']['input']>;
  expiryDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  expiryDateEnd?: InputMaybe<Scalars['AWSDateTime']['input']>;
  expiryDateStart?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lastEditedDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lastEditedDateEnd?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lastEditedDateStart?: InputMaybe<Scalars['AWSDateTime']['input']>;
  status?: InputMaybe<Array<InputMaybe<TemplateVersionStatus>>>;
};

export type TemplateSection = {
  __typename?: 'TemplateSection';
  fields: Array<TemplateSectionField>;
  name: Scalars['String']['output'];
};

export type TemplateSectionField = {
  __typename?: 'TemplateSectionField';
  field: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TemplateUploadUrl = {
  __typename?: 'TemplateUploadUrl';
  lastVersion: TemplateVersion;
  url: Scalars['String']['output'];
};

export type TemplateVersion = {
  __typename?: 'TemplateVersion';
  assignedTo?: Maybe<Scalars['String']['output']>;
  businessArea: BusinessArea;
  code: Scalars['String']['output'];
  commodity?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  destinationMarket: DestinationMarket;
  displayedDescription?: Maybe<Scalars['String']['output']>;
  documentId?: Maybe<Scalars['String']['output']>;
  documentRevision?: Maybe<TemplateVersion>;
  editable?: Maybe<Scalars['Boolean']['output']>;
  effective?: Maybe<Scalars['AWSDateTime']['output']>;
  expire?: Maybe<Scalars['AWSDateTime']['output']>;
  exportCertificateType?: Maybe<CertificateType>;
  fields?: Maybe<Array<Maybe<Field>>>;
  history?: Maybe<Array<TemplateHistory>>;
  id: Scalars['ID']['output'];
  importCertificateType?: Maybe<ImportCertificateType>;
  majorVersion: Scalars['Int']['output'];
  minorVersion: Scalars['Int']['output'];
  newFileUpload: Scalars['Boolean']['output'];
  reasonForUpdate?: Maybe<Scalars['String']['output']>;
  s3key?: Maybe<Scalars['String']['output']>;
  s3uri?: Maybe<Scalars['String']['output']>;
  status: TemplateVersionStatus;
  templateId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
  updatedBy: Scalars['String']['output'];
  uploadInProgress: Scalars['Boolean']['output'];
};

export enum TemplateVersionStatus {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  Locked = 'LOCKED',
  Review = 'REVIEW',
  Revoked = 'REVOKED',
  Suspended = 'SUSPENDED'
}

export type TimeTemplate = {
  __typename?: 'TimeTemplate';
  hour: IntTemplate;
  minute: IntTemplate;
  second: Scalars['String']['output'];
};

export enum TransportModeCode {
  AirTransport = 'AIR_TRANSPORT',
  FixedTransportInstallation = 'FIXED_TRANSPORT_INSTALLATION',
  InlandWaterTransport = 'INLAND_WATER_TRANSPORT',
  Mail = 'MAIL',
  MaritimeTransport = 'MARITIME_TRANSPORT',
  MultimodalTransport = 'MULTIMODAL_TRANSPORT',
  NotApplicable = 'NOT_APPLICABLE',
  NotSpecified = 'NOT_SPECIFIED',
  RailTransport = 'RAIL_TRANSPORT',
  RoadTransport = 'ROAD_TRANSPORT'
}

export type TransportMovementCodeTemplate = {
  __typename?: 'TransportMovementCodeTemplate';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TreatmentTemplate = {
  __typename?: 'TreatmentTemplate';
  full_information?: Maybe<Scalars['String']['output']>;
};

export type UpdateTemplateInput = {
  assignedTo?: InputMaybe<Scalars['String']['input']>;
  businessArea: BusinessArea;
  code: Scalars['String']['input'];
  commodity?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  destinationMarket: DestinationMarket;
  displayedDescription?: InputMaybe<Scalars['String']['input']>;
  effective?: InputMaybe<Scalars['AWSDateTime']['input']>;
  expire?: InputMaybe<Scalars['AWSDateTime']['input']>;
  exportCertificateType?: InputMaybe<CertificateType>;
  id: Scalars['ID']['input'];
  importCertificateType?: InputMaybe<ImportCertificateType>;
  majorVersion: Scalars['Int']['input'];
  minorVersion: Scalars['Int']['input'];
  reasonForUpdate?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type UserProfile = {
  __typename?: 'UserProfile';
  organisationName?: Maybe<Scalars['String']['output']>;
  organisationPartyId: Scalars['ID']['output'];
  organisationPrimaryAddress?: Maybe<B2GAddress>;
  registartionNumberId?: Maybe<Scalars['String']['output']>;
  registrationNumberValue?: Maybe<Scalars['String']['output']>;
  userContactId: Scalars['ID']['output'];
  userEmail?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
  userPrimaryPhone?: Maybe<Scalars['String']['output']>;
};

export type WineAnalytesTolerancesInput = {
  chemistryTest?: InputMaybe<Scalars['String']['input']>;
  dealcoholisedWine?: InputMaybe<Scalars['String']['input']>;
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
  red?: InputMaybe<Scalars['Boolean']['input']>;
  wineCategory?: InputMaybe<Scalars['String']['input']>;
};

export type WineAnalytesTolerancesResponse = {
  __typename?: 'WineAnalytesTolerancesResponse';
  destinationMarket?: Maybe<DestinationMarket>;
};

export type WineAnalytesTolerancesResponseInput = {
  dealcoholisedWine?: InputMaybe<Scalars['String']['input']>;
  red: Scalars['Boolean']['input'];
  testResults: Array<ChemistryTest>;
  wineCategory?: InputMaybe<Scalars['String']['input']>;
};

export type WineAnalytesTolerancesResponseRowInput = {
  destinationMarket?: InputMaybe<DestinationMarket>;
};

export type WineAnalytesTolerancesType = DecisionTableBaseInput & {
  __typename?: 'WineAnalytesTolerancesType';
  chemistryTest?: Maybe<Scalars['String']['output']>;
  dealcoholisedWine?: Maybe<Scalars['String']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  red?: Maybe<Scalars['Boolean']['output']>;
  validFrom?: Maybe<Scalars['AWSDateTime']['output']>;
  validUntil?: Maybe<Scalars['AWSDateTime']['output']>;
  wineCategory?: Maybe<Scalars['String']['output']>;
};

export enum WineDisputedReason {
  Malodorous = 'MALODOROUS',
  Oxidised = 'OXIDISED',
  Tainted = 'TAINTED'
}

export type WineFieldsTemplate = {
  __typename?: 'WineFieldsTemplate';
  bottler_reference?: Maybe<Scalars['String']['output']>;
  colour?: Maybe<Scalars['String']['output']>;
  eu_category?: Maybe<Scalars['String']['output']>;
  eu_ingredients?: Maybe<EuIngredientsTemplate>;
  geographic_indication?: Maybe<Scalars['String']['output']>;
  grape_variety?: Maybe<Scalars['String']['output']>;
  is_dealcoholised?: Maybe<Scalars['String']['output']>;
  laboratory?: Maybe<SpsPartyTemplate>;
  name?: Maybe<Scalars['String']['output']>;
  package?: Maybe<PackageTemplate>;
  special_process?: Maybe<Scalars['String']['output']>;
  style?: Maybe<Scalars['String']['output']>;
  vintage?: Maybe<Scalars['String']['output']>;
  wine_description?: Maybe<Scalars['String']['output']>;
};

export enum WinePackaging {
  Cs = 'CS',
  Ft = 'FT'
}

export type WineProductFieldInput = {
  certificateType?: InputMaybe<CertificateType>;
  destinationMarket?: InputMaybe<DestinationMarket>;
};

export type WineProductFieldType = DecisionTableBaseInput & {
  __typename?: 'WineProductFieldType';
  certificateType?: Maybe<CertificateType>;
  destinationMarket?: Maybe<DestinationMarket>;
  validFrom?: Maybe<Scalars['AWSDateTime']['output']>;
  validUntil?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type Winemaking = {
  __typename?: 'Winemaking';
  confirmations?: Maybe<Array<Maybe<WinemakingConfirmation>>>;
  status?: Maybe<ProcessStatus>;
  wsmp?: Maybe<Scalars['ID']['output']>;
};

export type WinemakingConfirmation = {
  __typename?: 'WinemakingConfirmation';
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  disputedReason?: Maybe<Scalars['String']['output']>;
  disputedResolution?: Maybe<Scalars['String']['output']>;
  market: DestinationMarket;
  status: WinemakingStatus;
};

export type WinemakingConfirmationInput = {
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  disputedReason?: InputMaybe<Scalars['String']['input']>;
  disputedResolution?: InputMaybe<Scalars['String']['input']>;
  market: DestinationMarket;
};

export enum WinemakingStatus {
  Confirmed = 'CONFIRMED',
  Disputed = 'DISPUTED',
  NotApplicable = 'NOT_APPLICABLE',
  Requested = 'REQUESTED'
}



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping of union types */
export type ResolversUnionTypes<_RefType extends Record<string, unknown>> = {
  PackagingRun: ( BottlingRunData ) | ( FlexiFillingRunData );
  ProcessAttributesResponse: ( ChemistryTestResponse ) | ( Omit<Packaging, 'runs'> & { runs: Array<_RefType['PackagingRun']> } ) | ( SensoryProcessRequest ) | ( Winemaking );
  RulesInput: ( DeclarationsType ) | ( EligibilityType ) | ( ExportCertificateWorkflowType ) | ( ImportConnectionsType ) | ( ProductFieldType ) | ( WineAnalytesTolerancesType ) | ( WineProductFieldType );
  RulesResponse: ( DeclarationsResponse ) | ( EligibilityResponse ) | ( ExportCertificateWorkflowResponse ) | ( ImportConnectionsResponse ) | ( ProductFieldResponse ) | ( WineAnalytesTolerancesResponse );
};

/** Mapping of interface types */
export type ResolversInterfaceTypes<_RefType extends Record<string, unknown>> = {
  DecisionTableBaseInput: ( DeclarationsType ) | ( EligibilityType ) | ( ExportCertificateWorkflowType ) | ( ImportConnectionsType ) | ( ProductFieldType ) | ( WineAnalytesTolerancesType ) | ( WineProductFieldType );
};

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AWSDate: ResolverTypeWrapper<Scalars['AWSDate']['output']>;
  AWSDateTime: ResolverTypeWrapper<Scalars['AWSDateTime']['output']>;
  AWSEmail: ResolverTypeWrapper<Scalars['AWSEmail']['output']>;
  AWSIPAddress: ResolverTypeWrapper<Scalars['AWSIPAddress']['output']>;
  AWSJSON: ResolverTypeWrapper<Scalars['AWSJSON']['output']>;
  AWSPhone: ResolverTypeWrapper<Scalars['AWSPhone']['output']>;
  AWSTime: ResolverTypeWrapper<Scalars['AWSTime']['output']>;
  AWSTimestamp: ResolverTypeWrapper<Scalars['AWSTimestamp']['output']>;
  AWSURL: ResolverTypeWrapper<Scalars['AWSURL']['output']>;
  AddressTypeCode: AddressTypeCode;
  ApproveCertificateRequestInput: ApproveCertificateRequestInput;
  AvailableTemplateFields: ResolverTypeWrapper<AvailableTemplateFields>;
  B2GAddress: ResolverTypeWrapper<B2GAddress>;
  B2GAddressInput: B2GAddressInput;
  B2GAuthentication: ResolverTypeWrapper<B2GAuthentication>;
  B2GAuthenticationInput: B2GAuthenticationInput;
  B2GBanWineBatchInput: B2GBanWineBatchInput;
  B2GCertificateDelivery: ResolverTypeWrapper<B2GCertificateDelivery>;
  B2GCertificateDeliveryInput: B2GCertificateDeliveryInput;
  B2GCertificateDeliveryMethod: B2GCertificateDeliveryMethod;
  B2GClassification: ResolverTypeWrapper<B2GClassification>;
  B2GClassificationInput: B2GClassificationInput;
  B2GClause: ResolverTypeWrapper<B2GClause>;
  B2GClauseInput: B2GClauseInput;
  B2GCollectionMethod: B2GCollectionMethod;
  B2GConsignment: ResolverTypeWrapper<B2GConsignment>;
  B2GConsignmentInput: B2GConsignmentInput;
  B2GContact: ResolverTypeWrapper<B2GContact>;
  B2GContactInput: B2GContactInput;
  B2GContainer: ResolverTypeWrapper<B2GContainer>;
  B2GContainerInput: B2GContainerInput;
  B2GCountry: ResolverTypeWrapper<B2GCountry>;
  B2GCountryInput: B2GCountryInput;
  B2GCountrySubDivision: ResolverTypeWrapper<B2GCountrySubDivision>;
  B2GCountrySubDivisionInput: B2GCountrySubDivisionInput;
  B2GDeclaration: ResolverTypeWrapper<B2GDeclaration>;
  B2GDeclarationInput: B2GDeclarationInput;
  B2GEligibilityCriteriaMet: ResolverTypeWrapper<B2GEligibilityCriteriaMet>;
  B2GEligibilityCriteriaMetInput: B2GEligibilityCriteriaMetInput;
  B2GEligibilityStatus: ResolverTypeWrapper<B2GEligibilityStatus>;
  B2GEligibilityStatusType: B2GEligibilityStatusType;
  B2GError: B2GError;
  B2GExportCertificateRequest: ResolverTypeWrapper<B2GExportCertificateRequest>;
  B2GExportCertificateRequestInput: B2GExportCertificateRequestInput;
  B2GExporterDeclaration: ResolverTypeWrapper<B2GExporterDeclaration>;
  B2GExporterDeclarationInput: B2GExporterDeclarationInput;
  B2GLocation: ResolverTypeWrapper<B2GLocation>;
  B2GLocationInput: B2GLocationInput;
  B2GNote: ResolverTypeWrapper<B2GNote>;
  B2GNoteInput: B2GNoteInput;
  B2GPackage: ResolverTypeWrapper<B2GPackage>;
  B2GPackageInput: B2GPackageInput;
  B2GParty: ResolverTypeWrapper<B2GParty>;
  B2GPartyInput: B2GPartyInput;
  B2GPeriod: ResolverTypeWrapper<B2GPeriod>;
  B2GPeriodInput: B2GPeriodInput;
  B2GPerson: ResolverTypeWrapper<B2GPerson>;
  B2GPersonInput: B2GPersonInput;
  B2GPremise: ResolverTypeWrapper<B2GPremise>;
  B2GPremiseInput: B2GPremiseInput;
  B2GProcess: ResolverTypeWrapper<B2GProcess>;
  B2GProcessCharacteristic: ResolverTypeWrapper<B2GProcessCharacteristic>;
  B2GProcessCharacteristicInput: B2GProcessCharacteristicInput;
  B2GProcessInput: B2GProcessInput;
  B2GProduct: ResolverTypeWrapper<B2GProduct>;
  B2GProductEligibility: ResolverTypeWrapper<B2GProductEligibility>;
  B2GProductEligibilityInput: B2GProductEligibilityInput;
  B2GProductEvent: ResolverTypeWrapper<B2GProductEvent>;
  B2GProductEventType: B2GProductEventType;
  B2GProductInput: B2GProductInput;
  B2GQualification: ResolverTypeWrapper<B2GQualification>;
  B2GQualificationInput: B2GQualificationInput;
  B2GReferencedDocument: ResolverTypeWrapper<B2GReferencedDocument>;
  B2GReferencedDocumentInput: B2GReferencedDocumentInput;
  B2GSeal: ResolverTypeWrapper<B2GSeal>;
  B2GSealInput: B2GSealInput;
  B2GTemperature: ResolverTypeWrapper<B2GTemperature>;
  B2GTemperatureInput: B2GTemperatureInput;
  B2GTradeLineItem: ResolverTypeWrapper<B2GTradeLineItem>;
  B2GTradeLineItemContainer: ResolverTypeWrapper<B2GTradeLineItemContainer>;
  B2GTradeLineItemContainerInput: B2GTradeLineItemContainerInput;
  B2GTradeLineItemInput: B2GTradeLineItemInput;
  B2GTransportMovement: ResolverTypeWrapper<B2GTransportMovement>;
  B2GTransportMovementInput: B2GTransportMovementInput;
  B2GValidationErrors: ResolverTypeWrapper<B2GValidationErrors>;
  B2GWineConsignment: ResolverTypeWrapper<B2GWineConsignment>;
  B2GWineConsignmentInput: B2GWineConsignmentInput;
  B2GWineFields: ResolverTypeWrapper<B2GWineFields>;
  B2GWineFieldsInput: B2GWineFieldsInput;
  B2GWinePackage: ResolverTypeWrapper<B2GWinePackage>;
  B2GWinePackageInput: B2GWinePackageInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  BottlingRunData: ResolverTypeWrapper<BottlingRunData>;
  BottlingRunDataInput: BottlingRunDataInput;
  BottlingRunInput: BottlingRunInput;
  BottlingRunResponse: ResolverTypeWrapper<BottlingRunResponse>;
  BusinessArea: BusinessArea;
  CTCancellationDataInput: CtCancellationDataInput;
  CTCancelledDataInput: CtCancelledDataInput;
  CTErrorDataInput: CtErrorDataInput;
  CTFailStatusDataInput: CtFailStatusDataInput;
  CTFirstReportedDateDataInput: CtFirstReportedDateDataInput;
  CTInProgressStatusDataInput: CtInProgressStatusDataInput;
  CTNCancellationErrorDataInput: CtnCancellationErrorDataInput;
  CTNoOrgErrorDataInput: CtNoOrgErrorDataInput;
  CTPassStatusDataInput: CtPassStatusDataInput;
  CTRequestedStatusDataInput: CtRequestedStatusDataInput;
  CTStatusEventType: CtStatusEventType;
  CTUnsuitableStatusDataInput: CtUnsuitableStatusDataInput;
  CancelledProcessInput: CancelledProcessInput;
  CancelledProcessResponse: ResolverTypeWrapper<CancelledProcessResponse>;
  CargoTypeClassificationCode: CargoTypeClassificationCode;
  CertificateType: CertificateType;
  ChemistryTest: ChemistryTest;
  ChemistryTestEventDataInput: ChemistryTestEventDataInput;
  ChemistryTestInput: ChemistryTestInput;
  ChemistryTestReceipt: ResolverTypeWrapper<ChemistryTestReceipt>;
  ChemistryTestRequest: ResolverTypeWrapper<ChemistryTestRequest>;
  ChemistryTestRequestInput: ChemistryTestRequestInput;
  ChemistryTestResponse: ResolverTypeWrapper<ChemistryTestResponse>;
  ChemistryTestStatus: ChemistryTestStatus;
  CombinedPartyTemplate: ResolverTypeWrapper<CombinedPartyTemplate>;
  ConfirmPackagingInput: ConfirmPackagingInput;
  ConfirmPackagingResponse: ResolverTypeWrapper<ConfirmPackagingResponse>;
  ConfirmWinemakingInput: ConfirmWinemakingInput;
  ConnectionType: ConnectionType;
  CreateTemplateInput: CreateTemplateInput;
  CustomsPermitNumberUsage: ResolverTypeWrapper<CustomsPermitNumberUsage>;
  DateTemplate: ResolverTypeWrapper<DateTemplate>;
  DateTimeTemplate: ResolverTypeWrapper<DateTimeTemplate>;
  DecimalTemplate: ResolverTypeWrapper<DecimalTemplate>;
  DecisionTable: ResolverTypeWrapper<Omit<DecisionTable, 'approvedRevision' | 'lastRevision' | 'revisions'> & { approvedRevision?: Maybe<ResolversTypes['DecisionTableRevision']>, lastRevision?: Maybe<ResolversTypes['DecisionTableRevision']>, revisions: Array<ResolversTypes['DecisionTableRevision']> }>;
  DecisionTableBaseInput: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['DecisionTableBaseInput']>;
  DecisionTableColumn: ResolverTypeWrapper<DecisionTableColumn>;
  DecisionTableDataType: DecisionTableDataType;
  DecisionTableExpectation: ResolverTypeWrapper<Omit<DecisionTableExpectation, 'input' | 'response'> & { input: ResolversTypes['RulesInput'], response: ResolversTypes['RulesResponse'] }>;
  DecisionTableField: ResolverTypeWrapper<DecisionTableField>;
  DecisionTableFilter: DecisionTableFilter;
  DecisionTableRevision: ResolverTypeWrapper<Omit<DecisionTableRevision, 'expectations' | 'rows'> & { expectations: Array<ResolversTypes['DecisionTableExpectation']>, rows: Array<ResolversTypes['DecisionTableRow']> }>;
  DecisionTableRevisionInput: DecisionTableRevisionInput;
  DecisionTableRevisionStatus: DecisionTableRevisionStatus;
  DecisionTableRow: ResolverTypeWrapper<Omit<DecisionTableRow, 'input' | 'response'> & { input: ResolversTypes['RulesInput'], response: ResolversTypes['RulesResponse'] }>;
  DecisionTableRowInput: DecisionTableRowInput;
  DecisionTableTestResult: ResolverTypeWrapper<DecisionTableTestResult>;
  DeclarationFormletFields: ResolverTypeWrapper<DeclarationFormletFields>;
  DeclarationFormletSection: ResolverTypeWrapper<DeclarationFormletSection>;
  DeclarationsInput: DeclarationsInput;
  DeclarationsResponse: ResolverTypeWrapper<DeclarationsResponse>;
  DeclarationsResponseInput: DeclarationsResponseInput;
  DeclarationsType: ResolverTypeWrapper<DeclarationsType>;
  DestinationMarket: DestinationMarket;
  DraftExportCertificate: ResolverTypeWrapper<DraftExportCertificate>;
  EStarOrganisation: ResolverTypeWrapper<EStarOrganisation>;
  EStarOrganisationFilter: EStarOrganisationFilter;
  EUIngredient: ResolverTypeWrapper<EuIngredient>;
  EUIngredientInput: EuIngredientInput;
  EUIngredientsTemplate: ResolverTypeWrapper<EuIngredientsTemplate>;
  EligibilityInput: EligibilityInput;
  EligibilityResponse: ResolverTypeWrapper<EligibilityResponse>;
  EligibilityResponseInput: EligibilityResponseInput;
  EligibilityType: ResolverTypeWrapper<EligibilityType>;
  EnumerationInfo: ResolverTypeWrapper<EnumerationInfo>;
  EstarCancelRequest: ResolverTypeWrapper<EstarCancelRequest>;
  ExportCertificateRequest: ResolverTypeWrapper<ExportCertificateRequest>;
  ExportCertificateRequestInput: ExportCertificateRequestInput;
  ExportCertificateStatus: ExportCertificateStatus;
  ExportCertificateSupportingDocumentsInput: ExportCertificateSupportingDocumentsInput;
  ExportCertificateWorkflowInput: ExportCertificateWorkflowInput;
  ExportCertificateWorkflowResponse: ResolverTypeWrapper<ExportCertificateWorkflowResponse>;
  ExportCertificateWorkflowResponseInput: ExportCertificateWorkflowResponseInput;
  ExportCertificateWorkflowType: ResolverTypeWrapper<ExportCertificateWorkflowType>;
  Field: ResolverTypeWrapper<Field>;
  FlexiFillingRunData: ResolverTypeWrapper<FlexiFillingRunData>;
  FlexiFillingRunDataInput: FlexiFillingRunDataInput;
  FlexiFillingRunInput: FlexiFillingRunInput;
  FlexiFillingRunResponse: ResolverTypeWrapper<FlexiFillingRunResponse>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  Form: ResolverTypeWrapper<Form>;
  FormletCollectionFilter: ResolverTypeWrapper<FormletCollectionFilter>;
  FormletField: ResolverTypeWrapper<FormletField>;
  FormletFieldType: FormletFieldType;
  FormletNestedFields: ResolverTypeWrapper<FormletNestedFields>;
  FormletOption: ResolverTypeWrapper<FormletOption>;
  GenerateDraftExportCertificateInput: GenerateDraftExportCertificateInput;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  IDsTemplate: ResolverTypeWrapper<IDsTemplate>;
  IPPCHubLogEntry: ResolverTypeWrapper<IppcHubLogEntry>;
  IPPCHubLogInput: IppcHubLogInput;
  ImportCertificate: ResolverTypeWrapper<ImportCertificate>;
  ImportCertificateEmbeddedDocument: ResolverTypeWrapper<ImportCertificateEmbeddedDocument>;
  ImportCertificateExtractItem: ResolverTypeWrapper<ImportCertificateExtractItem>;
  ImportCertificateExtractMeasurement: ResolverTypeWrapper<ImportCertificateExtractMeasurement>;
  ImportCertificateExtractTemplate: ResolverTypeWrapper<ImportCertificateExtractTemplate>;
  ImportCertificateIntegration: ResolverTypeWrapper<ImportCertificateIntegration>;
  ImportCertificateIntegrationResponse: ImportCertificateIntegrationResponse;
  ImportCertificateLogMessage: ResolverTypeWrapper<ImportCertificateLogMessage>;
  ImportCertificateLogMessageInput: ImportCertificateLogMessageInput;
  ImportCertificateLogStatus: ImportCertificateLogStatus;
  ImportCertificateMeasurementTemplate: ResolverTypeWrapper<ImportCertificateMeasurementTemplate>;
  ImportCertificatePackagingTemplate: ResolverTypeWrapper<ImportCertificatePackagingTemplate>;
  ImportCertificatePdf: ResolverTypeWrapper<ImportCertificatePdf>;
  ImportCertificatePdfInput: ImportCertificatePdfInput;
  ImportCertificateStatus: ImportCertificateStatus;
  ImportCertificateStatusChangeInput: ImportCertificateStatusChangeInput;
  ImportCertificateType: ImportCertificateType;
  ImportCertificateXml: ResolverTypeWrapper<ImportCertificateXml>;
  ImportConnectionsInput: ImportConnectionsInput;
  ImportConnectionsResponse: ResolverTypeWrapper<ImportConnectionsResponse>;
  ImportConnectionsResponseInput: ImportConnectionsResponseInput;
  ImportConnectionsType: ResolverTypeWrapper<ImportConnectionsType>;
  IndicatorType: ResolverTypeWrapper<IndicatorType>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  IntTemplate: ResolverTypeWrapper<IntTemplate>;
  ManualVolumeAdjustment: ResolverTypeWrapper<ManualVolumeAdjustment>;
  MeasureTemplate: ResolverTypeWrapper<MeasureTemplate>;
  MonthTemplate: ResolverTypeWrapper<MonthTemplate>;
  Mutation: ResolverTypeWrapper<{}>;
  NamedCode: ResolverTypeWrapper<NamedCode>;
  NamedCodeInput: NamedCodeInput;
  NamedCodeTemplate: ResolverTypeWrapper<NamedCodeTemplate>;
  NewReferencedDocument: ResolverTypeWrapper<NewReferencedDocument>;
  NumberConstrain: ResolverTypeWrapper<NumberConstrain>;
  Optionality: Optionality;
  PackageTemplate: ResolverTypeWrapper<PackageTemplate>;
  Packaging: ResolverTypeWrapper<Omit<Packaging, 'runs'> & { runs: Array<ResolversTypes['PackagingRun']> }>;
  PackagingConfirmation: ResolverTypeWrapper<PackagingConfirmation>;
  PackagingRun: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['PackagingRun']>;
  PackagingStatus: PackagingStatus;
  PdfGenerationStatus: PdfGenerationStatus;
  ProcessAttributesResponse: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['ProcessAttributesResponse']>;
  ProcessResponse: ResolverTypeWrapper<ProcessResponse>;
  ProcessStatus: ProcessStatus;
  ProcessTask: ResolverTypeWrapper<ProcessTask>;
  ProcessType: ResolverTypeWrapper<ProcessType>;
  ProductFieldInput: ProductFieldInput;
  ProductFieldResponse: ResolverTypeWrapper<ProductFieldResponse>;
  ProductFieldResponseInput: ProductFieldResponseInput;
  ProductFieldType: ResolverTypeWrapper<ProductFieldType>;
  ProductFieldsInput: ProductFieldsInput;
  ProductProcessFilterInput: ProductProcessFilterInput;
  ProductProcessResponse: ResolverTypeWrapper<Omit<ProductProcessResponse, 'attributes'> & { attributes: ResolversTypes['ProcessAttributesResponse'] }>;
  ProductProcessType: ProductProcessType;
  ProductProcessesInput: ProductProcessesInput;
  Query: ResolverTypeWrapper<{}>;
  ReferencedDocument: ResolverTypeWrapper<ReferencedDocument>;
  ReferencedDocumentUrl: ResolverTypeWrapper<ReferencedDocumentUrl>;
  ReindexResponse: ResolverTypeWrapper<ReindexResponse>;
  RequestConfirmationInput: RequestConfirmationInput;
  RequestConfirmationResponse: ResolverTypeWrapper<RequestConfirmationResponse>;
  ResetConfirmationRequestInput: ResetConfirmationRequestInput;
  RulesExecutionInput: RulesExecutionInput;
  RulesInput: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['RulesInput']>;
  RulesResponse: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['RulesResponse']>;
  SPSAddress: ResolverTypeWrapper<SpsAddress>;
  SPSAddressInput: SpsAddressInput;
  SPSAuthentication: ResolverTypeWrapper<SpsAuthentication>;
  SPSAuthenticationInput: SpsAuthenticationInput;
  SPSCertificate: ResolverTypeWrapper<SpsCertificate>;
  SPSClassification: ResolverTypeWrapper<SpsClassification>;
  SPSClassificationInput: SpsClassificationInput;
  SPSClause: ResolverTypeWrapper<SpsClause>;
  SPSClauseInput: SpsClauseInput;
  SPSConsignment: ResolverTypeWrapper<SpsConsignment>;
  SPSConsignmentInput: SpsConsignmentInput;
  SPSConsignmentItem: ResolverTypeWrapper<SpsConsignmentItem>;
  SPSConsignmentItemInput: SpsConsignmentItemInput;
  SPSContact: ResolverTypeWrapper<SpsContact>;
  SPSContactInput: SpsContactInput;
  SPSCountry: ResolverTypeWrapper<SpsCountry>;
  SPSCountryInput: SpsCountryInput;
  SPSCountrySubDivision: ResolverTypeWrapper<SpsCountrySubDivision>;
  SPSCountrySubDivisionInput: SpsCountrySubDivisionInput;
  SPSEvent: ResolverTypeWrapper<SpsEvent>;
  SPSEventInput: SpsEventInput;
  SPSExchangedDocument: ResolverTypeWrapper<SpsExchangedDocument>;
  SPSLocation: ResolverTypeWrapper<SpsLocation>;
  SPSLocationInput: SpsLocationInput;
  SPSNote: ResolverTypeWrapper<SpsNote>;
  SPSNoteInput: SpsNoteInput;
  SPSPackage: ResolverTypeWrapper<SpsPackage>;
  SPSPackageInput: SpsPackageInput;
  SPSParty: ResolverTypeWrapper<SpsParty>;
  SPSPartyInput: SpsPartyInput;
  SPSPeriod: ResolverTypeWrapper<SpsPeriod>;
  SPSPeriodInput: SpsPeriodInput;
  SPSPerson: ResolverTypeWrapper<SpsPerson>;
  SPSPersonInput: SpsPersonInput;
  SPSProcess: ResolverTypeWrapper<SpsProcess>;
  SPSProcessCharacteristic: ResolverTypeWrapper<SpsProcessCharacteristic>;
  SPSProcessCharacteristicInput: SpsProcessCharacteristicInput;
  SPSProcessInput: SpsProcessInput;
  SPSQualification: ResolverTypeWrapper<SpsQualification>;
  SPSQualificationInput: SpsQualificationInput;
  SPSReferencedDocument: ResolverTypeWrapper<SpsReferencedDocument>;
  SPSSeal: ResolverTypeWrapper<SpsSeal>;
  SPSSealInput: SpsSealInput;
  SPSTemperature: ResolverTypeWrapper<SpsTemperature>;
  SPSTemperatureInput: SpsTemperatureInput;
  SPSTradeLineItem: ResolverTypeWrapper<SpsTradeLineItem>;
  SPSTradeLineItemInput: SpsTradeLineItemInput;
  SPSTransportEquipment: ResolverTypeWrapper<SpsTransportEquipment>;
  SPSTransportEquipmentInput: SpsTransportEquipmentInput;
  SPSTransportMovement: ResolverTypeWrapper<SpsTransportMovement>;
  SPSTransportMovementInput: SpsTransportMovementInput;
  SPSTransportMovementTemplate: ResolverTypeWrapper<SpsTransportMovementTemplate>;
  SPSWineConsignment: ResolverTypeWrapper<SpsWineConsignment>;
  SalesForceAddress: ResolverTypeWrapper<SalesForceAddress>;
  SalesForceContact: ResolverTypeWrapper<SalesForceContact>;
  SalesForceEmail: ResolverTypeWrapper<SalesForceEmail>;
  SalesForceIdentifier: ResolverTypeWrapper<SalesForceIdentifier>;
  SalesForceParty: ResolverTypeWrapper<SalesForceParty>;
  SalesForcePhone: ResolverTypeWrapper<SalesForcePhone>;
  SalesForceProductId: ResolverTypeWrapper<SalesForceProductId>;
  SearchApiKey: ResolverTypeWrapper<SearchApiKey>;
  SearchOptions: SearchOptions;
  SensoryProcessInput: SensoryProcessInput;
  SensoryProcessRequest: ResolverTypeWrapper<SensoryProcessRequest>;
  SfChangeExportCertificateRequestStatusInput: SfChangeExportCertificateRequestStatusInput;
  SfVerifyExportCertificateRequestProductsInput: SfVerifyExportCertificateRequestProductsInput;
  SfVerifyProductInput: SfVerifyProductInput;
  SpsAddressTemplate: ResolverTypeWrapper<SpsAddressTemplate>;
  SpsAuthenticationTemplate: ResolverTypeWrapper<SpsAuthenticationTemplate>;
  SpsCertificateTemplate: ResolverTypeWrapper<SpsCertificateTemplate>;
  SpsClassificationTemplate: ResolverTypeWrapper<SpsClassificationTemplate>;
  SpsClauseTemplate: ResolverTypeWrapper<SpsClauseTemplate>;
  SpsConsignmentItemTemplate: ResolverTypeWrapper<SpsConsignmentItemTemplate>;
  SpsConsignmentTemplate: ResolverTypeWrapper<SpsConsignmentTemplate>;
  SpsContactTemplate: ResolverTypeWrapper<SpsContactTemplate>;
  SpsCountrySubDivisionTemplate: ResolverTypeWrapper<SpsCountrySubDivisionTemplate>;
  SpsCountryTemplate: ResolverTypeWrapper<SpsCountryTemplate>;
  SpsEventTemplate: ResolverTypeWrapper<SpsEventTemplate>;
  SpsExchangedDocumentTemplate: ResolverTypeWrapper<SpsExchangedDocumentTemplate>;
  SpsLocationTemplate: ResolverTypeWrapper<SpsLocationTemplate>;
  SpsNoteTemplate: ResolverTypeWrapper<SpsNoteTemplate>;
  SpsPackageTemplate: ResolverTypeWrapper<SpsPackageTemplate>;
  SpsPartyTemplate: ResolverTypeWrapper<SpsPartyTemplate>;
  SpsPeriodTemplate: ResolverTypeWrapper<SpsPeriodTemplate>;
  SpsPersonTemplate: ResolverTypeWrapper<SpsPersonTemplate>;
  SpsProcessCharacteristicTemplate: ResolverTypeWrapper<SpsProcessCharacteristicTemplate>;
  SpsProcessTemplate: ResolverTypeWrapper<SpsProcessTemplate>;
  SpsQualificationTemplate: ResolverTypeWrapper<SpsQualificationTemplate>;
  SpsReplacementCertificateTemplate: ResolverTypeWrapper<SpsReplacementCertificateTemplate>;
  SpsSealTemplate: ResolverTypeWrapper<SpsSealTemplate>;
  SpsTemperatureTemplate: ResolverTypeWrapper<SpsTemperatureTemplate>;
  SpsTradeLineItemTemplate: ResolverTypeWrapper<SpsTradeLineItemTemplate>;
  SpsTransportEquipmentTemplate: ResolverTypeWrapper<SpsTransportEquipmentTemplate>;
  SpsWineConsignmentTemplate: ResolverTypeWrapper<SpsWineConsignmentTemplate>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  Template: ResolverTypeWrapper<Template>;
  TemplateDocumentUrl: ResolverTypeWrapper<TemplateDocumentUrl>;
  TemplateFilter: TemplateFilter;
  TemplateHistory: ResolverTypeWrapper<TemplateHistory>;
  TemplateOptionalFilter: TemplateOptionalFilter;
  TemplateSection: ResolverTypeWrapper<TemplateSection>;
  TemplateSectionField: ResolverTypeWrapper<TemplateSectionField>;
  TemplateUploadUrl: ResolverTypeWrapper<TemplateUploadUrl>;
  TemplateVersion: ResolverTypeWrapper<TemplateVersion>;
  TemplateVersionStatus: TemplateVersionStatus;
  TimeTemplate: ResolverTypeWrapper<TimeTemplate>;
  TransportModeCode: TransportModeCode;
  TransportMovementCodeTemplate: ResolverTypeWrapper<TransportMovementCodeTemplate>;
  TreatmentTemplate: ResolverTypeWrapper<TreatmentTemplate>;
  UpdateTemplateInput: UpdateTemplateInput;
  UserProfile: ResolverTypeWrapper<UserProfile>;
  WineAnalytesTolerancesInput: WineAnalytesTolerancesInput;
  WineAnalytesTolerancesResponse: ResolverTypeWrapper<WineAnalytesTolerancesResponse>;
  WineAnalytesTolerancesResponseInput: WineAnalytesTolerancesResponseInput;
  WineAnalytesTolerancesResponseRowInput: WineAnalytesTolerancesResponseRowInput;
  WineAnalytesTolerancesType: ResolverTypeWrapper<WineAnalytesTolerancesType>;
  WineDisputedReason: WineDisputedReason;
  WineFieldsTemplate: ResolverTypeWrapper<WineFieldsTemplate>;
  WinePackaging: WinePackaging;
  WineProductFieldInput: WineProductFieldInput;
  WineProductFieldType: ResolverTypeWrapper<WineProductFieldType>;
  Winemaking: ResolverTypeWrapper<Winemaking>;
  WinemakingConfirmation: ResolverTypeWrapper<WinemakingConfirmation>;
  WinemakingConfirmationInput: WinemakingConfirmationInput;
  WinemakingStatus: WinemakingStatus;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AWSDate: Scalars['AWSDate']['output'];
  AWSDateTime: Scalars['AWSDateTime']['output'];
  AWSEmail: Scalars['AWSEmail']['output'];
  AWSIPAddress: Scalars['AWSIPAddress']['output'];
  AWSJSON: Scalars['AWSJSON']['output'];
  AWSPhone: Scalars['AWSPhone']['output'];
  AWSTime: Scalars['AWSTime']['output'];
  AWSTimestamp: Scalars['AWSTimestamp']['output'];
  AWSURL: Scalars['AWSURL']['output'];
  ApproveCertificateRequestInput: ApproveCertificateRequestInput;
  AvailableTemplateFields: AvailableTemplateFields;
  B2GAddress: B2GAddress;
  B2GAddressInput: B2GAddressInput;
  B2GAuthentication: B2GAuthentication;
  B2GAuthenticationInput: B2GAuthenticationInput;
  B2GBanWineBatchInput: B2GBanWineBatchInput;
  B2GCertificateDelivery: B2GCertificateDelivery;
  B2GCertificateDeliveryInput: B2GCertificateDeliveryInput;
  B2GClassification: B2GClassification;
  B2GClassificationInput: B2GClassificationInput;
  B2GClause: B2GClause;
  B2GClauseInput: B2GClauseInput;
  B2GConsignment: B2GConsignment;
  B2GConsignmentInput: B2GConsignmentInput;
  B2GContact: B2GContact;
  B2GContactInput: B2GContactInput;
  B2GContainer: B2GContainer;
  B2GContainerInput: B2GContainerInput;
  B2GCountry: B2GCountry;
  B2GCountryInput: B2GCountryInput;
  B2GCountrySubDivision: B2GCountrySubDivision;
  B2GCountrySubDivisionInput: B2GCountrySubDivisionInput;
  B2GDeclaration: B2GDeclaration;
  B2GDeclarationInput: B2GDeclarationInput;
  B2GEligibilityCriteriaMet: B2GEligibilityCriteriaMet;
  B2GEligibilityCriteriaMetInput: B2GEligibilityCriteriaMetInput;
  B2GEligibilityStatus: B2GEligibilityStatus;
  B2GExportCertificateRequest: B2GExportCertificateRequest;
  B2GExportCertificateRequestInput: B2GExportCertificateRequestInput;
  B2GExporterDeclaration: B2GExporterDeclaration;
  B2GExporterDeclarationInput: B2GExporterDeclarationInput;
  B2GLocation: B2GLocation;
  B2GLocationInput: B2GLocationInput;
  B2GNote: B2GNote;
  B2GNoteInput: B2GNoteInput;
  B2GPackage: B2GPackage;
  B2GPackageInput: B2GPackageInput;
  B2GParty: B2GParty;
  B2GPartyInput: B2GPartyInput;
  B2GPeriod: B2GPeriod;
  B2GPeriodInput: B2GPeriodInput;
  B2GPerson: B2GPerson;
  B2GPersonInput: B2GPersonInput;
  B2GPremise: B2GPremise;
  B2GPremiseInput: B2GPremiseInput;
  B2GProcess: B2GProcess;
  B2GProcessCharacteristic: B2GProcessCharacteristic;
  B2GProcessCharacteristicInput: B2GProcessCharacteristicInput;
  B2GProcessInput: B2GProcessInput;
  B2GProduct: B2GProduct;
  B2GProductEligibility: B2GProductEligibility;
  B2GProductEligibilityInput: B2GProductEligibilityInput;
  B2GProductEvent: B2GProductEvent;
  B2GProductInput: B2GProductInput;
  B2GQualification: B2GQualification;
  B2GQualificationInput: B2GQualificationInput;
  B2GReferencedDocument: B2GReferencedDocument;
  B2GReferencedDocumentInput: B2GReferencedDocumentInput;
  B2GSeal: B2GSeal;
  B2GSealInput: B2GSealInput;
  B2GTemperature: B2GTemperature;
  B2GTemperatureInput: B2GTemperatureInput;
  B2GTradeLineItem: B2GTradeLineItem;
  B2GTradeLineItemContainer: B2GTradeLineItemContainer;
  B2GTradeLineItemContainerInput: B2GTradeLineItemContainerInput;
  B2GTradeLineItemInput: B2GTradeLineItemInput;
  B2GTransportMovement: B2GTransportMovement;
  B2GTransportMovementInput: B2GTransportMovementInput;
  B2GValidationErrors: B2GValidationErrors;
  B2GWineConsignment: B2GWineConsignment;
  B2GWineConsignmentInput: B2GWineConsignmentInput;
  B2GWineFields: B2GWineFields;
  B2GWineFieldsInput: B2GWineFieldsInput;
  B2GWinePackage: B2GWinePackage;
  B2GWinePackageInput: B2GWinePackageInput;
  Boolean: Scalars['Boolean']['output'];
  BottlingRunData: BottlingRunData;
  BottlingRunDataInput: BottlingRunDataInput;
  BottlingRunInput: BottlingRunInput;
  BottlingRunResponse: BottlingRunResponse;
  CTCancellationDataInput: CtCancellationDataInput;
  CTCancelledDataInput: CtCancelledDataInput;
  CTErrorDataInput: CtErrorDataInput;
  CTFailStatusDataInput: CtFailStatusDataInput;
  CTFirstReportedDateDataInput: CtFirstReportedDateDataInput;
  CTInProgressStatusDataInput: CtInProgressStatusDataInput;
  CTNCancellationErrorDataInput: CtnCancellationErrorDataInput;
  CTNoOrgErrorDataInput: CtNoOrgErrorDataInput;
  CTPassStatusDataInput: CtPassStatusDataInput;
  CTRequestedStatusDataInput: CtRequestedStatusDataInput;
  CTUnsuitableStatusDataInput: CtUnsuitableStatusDataInput;
  CancelledProcessInput: CancelledProcessInput;
  CancelledProcessResponse: CancelledProcessResponse;
  ChemistryTest: ChemistryTest;
  ChemistryTestEventDataInput: ChemistryTestEventDataInput;
  ChemistryTestInput: ChemistryTestInput;
  ChemistryTestReceipt: ChemistryTestReceipt;
  ChemistryTestRequest: ChemistryTestRequest;
  ChemistryTestRequestInput: ChemistryTestRequestInput;
  ChemistryTestResponse: ChemistryTestResponse;
  CombinedPartyTemplate: CombinedPartyTemplate;
  ConfirmPackagingInput: ConfirmPackagingInput;
  ConfirmPackagingResponse: ConfirmPackagingResponse;
  ConfirmWinemakingInput: ConfirmWinemakingInput;
  CreateTemplateInput: CreateTemplateInput;
  CustomsPermitNumberUsage: CustomsPermitNumberUsage;
  DateTemplate: DateTemplate;
  DateTimeTemplate: DateTimeTemplate;
  DecimalTemplate: DecimalTemplate;
  DecisionTable: Omit<DecisionTable, 'approvedRevision' | 'lastRevision' | 'revisions'> & { approvedRevision?: Maybe<ResolversParentTypes['DecisionTableRevision']>, lastRevision?: Maybe<ResolversParentTypes['DecisionTableRevision']>, revisions: Array<ResolversParentTypes['DecisionTableRevision']> };
  DecisionTableBaseInput: ResolversInterfaceTypes<ResolversParentTypes>['DecisionTableBaseInput'];
  DecisionTableColumn: DecisionTableColumn;
  DecisionTableExpectation: Omit<DecisionTableExpectation, 'input' | 'response'> & { input: ResolversParentTypes['RulesInput'], response: ResolversParentTypes['RulesResponse'] };
  DecisionTableField: DecisionTableField;
  DecisionTableFilter: DecisionTableFilter;
  DecisionTableRevision: Omit<DecisionTableRevision, 'expectations' | 'rows'> & { expectations: Array<ResolversParentTypes['DecisionTableExpectation']>, rows: Array<ResolversParentTypes['DecisionTableRow']> };
  DecisionTableRevisionInput: DecisionTableRevisionInput;
  DecisionTableRow: Omit<DecisionTableRow, 'input' | 'response'> & { input: ResolversParentTypes['RulesInput'], response: ResolversParentTypes['RulesResponse'] };
  DecisionTableRowInput: DecisionTableRowInput;
  DecisionTableTestResult: DecisionTableTestResult;
  DeclarationFormletFields: DeclarationFormletFields;
  DeclarationFormletSection: DeclarationFormletSection;
  DeclarationsInput: DeclarationsInput;
  DeclarationsResponse: DeclarationsResponse;
  DeclarationsResponseInput: DeclarationsResponseInput;
  DeclarationsType: DeclarationsType;
  DraftExportCertificate: DraftExportCertificate;
  EStarOrganisation: EStarOrganisation;
  EStarOrganisationFilter: EStarOrganisationFilter;
  EUIngredient: EuIngredient;
  EUIngredientInput: EuIngredientInput;
  EUIngredientsTemplate: EuIngredientsTemplate;
  EligibilityInput: EligibilityInput;
  EligibilityResponse: EligibilityResponse;
  EligibilityResponseInput: EligibilityResponseInput;
  EligibilityType: EligibilityType;
  EnumerationInfo: EnumerationInfo;
  EstarCancelRequest: EstarCancelRequest;
  ExportCertificateRequest: ExportCertificateRequest;
  ExportCertificateRequestInput: ExportCertificateRequestInput;
  ExportCertificateSupportingDocumentsInput: ExportCertificateSupportingDocumentsInput;
  ExportCertificateWorkflowInput: ExportCertificateWorkflowInput;
  ExportCertificateWorkflowResponse: ExportCertificateWorkflowResponse;
  ExportCertificateWorkflowResponseInput: ExportCertificateWorkflowResponseInput;
  ExportCertificateWorkflowType: ExportCertificateWorkflowType;
  Field: Field;
  FlexiFillingRunData: FlexiFillingRunData;
  FlexiFillingRunDataInput: FlexiFillingRunDataInput;
  FlexiFillingRunInput: FlexiFillingRunInput;
  FlexiFillingRunResponse: FlexiFillingRunResponse;
  Float: Scalars['Float']['output'];
  Form: Form;
  FormletCollectionFilter: FormletCollectionFilter;
  FormletField: FormletField;
  FormletNestedFields: FormletNestedFields;
  FormletOption: FormletOption;
  GenerateDraftExportCertificateInput: GenerateDraftExportCertificateInput;
  ID: Scalars['ID']['output'];
  IDsTemplate: IDsTemplate;
  IPPCHubLogEntry: IppcHubLogEntry;
  IPPCHubLogInput: IppcHubLogInput;
  ImportCertificate: ImportCertificate;
  ImportCertificateEmbeddedDocument: ImportCertificateEmbeddedDocument;
  ImportCertificateExtractItem: ImportCertificateExtractItem;
  ImportCertificateExtractMeasurement: ImportCertificateExtractMeasurement;
  ImportCertificateExtractTemplate: ImportCertificateExtractTemplate;
  ImportCertificateIntegration: ImportCertificateIntegration;
  ImportCertificateLogMessage: ImportCertificateLogMessage;
  ImportCertificateLogMessageInput: ImportCertificateLogMessageInput;
  ImportCertificateMeasurementTemplate: ImportCertificateMeasurementTemplate;
  ImportCertificatePackagingTemplate: ImportCertificatePackagingTemplate;
  ImportCertificatePdf: ImportCertificatePdf;
  ImportCertificatePdfInput: ImportCertificatePdfInput;
  ImportCertificateStatusChangeInput: ImportCertificateStatusChangeInput;
  ImportCertificateXml: ImportCertificateXml;
  ImportConnectionsInput: ImportConnectionsInput;
  ImportConnectionsResponse: ImportConnectionsResponse;
  ImportConnectionsResponseInput: ImportConnectionsResponseInput;
  ImportConnectionsType: ImportConnectionsType;
  IndicatorType: IndicatorType;
  Int: Scalars['Int']['output'];
  IntTemplate: IntTemplate;
  ManualVolumeAdjustment: ManualVolumeAdjustment;
  MeasureTemplate: MeasureTemplate;
  MonthTemplate: MonthTemplate;
  Mutation: {};
  NamedCode: NamedCode;
  NamedCodeInput: NamedCodeInput;
  NamedCodeTemplate: NamedCodeTemplate;
  NewReferencedDocument: NewReferencedDocument;
  NumberConstrain: NumberConstrain;
  PackageTemplate: PackageTemplate;
  Packaging: Omit<Packaging, 'runs'> & { runs: Array<ResolversParentTypes['PackagingRun']> };
  PackagingConfirmation: PackagingConfirmation;
  PackagingRun: ResolversUnionTypes<ResolversParentTypes>['PackagingRun'];
  ProcessAttributesResponse: ResolversUnionTypes<ResolversParentTypes>['ProcessAttributesResponse'];
  ProcessResponse: ProcessResponse;
  ProcessTask: ProcessTask;
  ProcessType: ProcessType;
  ProductFieldInput: ProductFieldInput;
  ProductFieldResponse: ProductFieldResponse;
  ProductFieldResponseInput: ProductFieldResponseInput;
  ProductFieldType: ProductFieldType;
  ProductFieldsInput: ProductFieldsInput;
  ProductProcessFilterInput: ProductProcessFilterInput;
  ProductProcessResponse: Omit<ProductProcessResponse, 'attributes'> & { attributes: ResolversParentTypes['ProcessAttributesResponse'] };
  ProductProcessesInput: ProductProcessesInput;
  Query: {};
  ReferencedDocument: ReferencedDocument;
  ReferencedDocumentUrl: ReferencedDocumentUrl;
  ReindexResponse: ReindexResponse;
  RequestConfirmationInput: RequestConfirmationInput;
  RequestConfirmationResponse: RequestConfirmationResponse;
  ResetConfirmationRequestInput: ResetConfirmationRequestInput;
  RulesExecutionInput: RulesExecutionInput;
  RulesInput: ResolversUnionTypes<ResolversParentTypes>['RulesInput'];
  RulesResponse: ResolversUnionTypes<ResolversParentTypes>['RulesResponse'];
  SPSAddress: SpsAddress;
  SPSAddressInput: SpsAddressInput;
  SPSAuthentication: SpsAuthentication;
  SPSAuthenticationInput: SpsAuthenticationInput;
  SPSCertificate: SpsCertificate;
  SPSClassification: SpsClassification;
  SPSClassificationInput: SpsClassificationInput;
  SPSClause: SpsClause;
  SPSClauseInput: SpsClauseInput;
  SPSConsignment: SpsConsignment;
  SPSConsignmentInput: SpsConsignmentInput;
  SPSConsignmentItem: SpsConsignmentItem;
  SPSConsignmentItemInput: SpsConsignmentItemInput;
  SPSContact: SpsContact;
  SPSContactInput: SpsContactInput;
  SPSCountry: SpsCountry;
  SPSCountryInput: SpsCountryInput;
  SPSCountrySubDivision: SpsCountrySubDivision;
  SPSCountrySubDivisionInput: SpsCountrySubDivisionInput;
  SPSEvent: SpsEvent;
  SPSEventInput: SpsEventInput;
  SPSExchangedDocument: SpsExchangedDocument;
  SPSLocation: SpsLocation;
  SPSLocationInput: SpsLocationInput;
  SPSNote: SpsNote;
  SPSNoteInput: SpsNoteInput;
  SPSPackage: SpsPackage;
  SPSPackageInput: SpsPackageInput;
  SPSParty: SpsParty;
  SPSPartyInput: SpsPartyInput;
  SPSPeriod: SpsPeriod;
  SPSPeriodInput: SpsPeriodInput;
  SPSPerson: SpsPerson;
  SPSPersonInput: SpsPersonInput;
  SPSProcess: SpsProcess;
  SPSProcessCharacteristic: SpsProcessCharacteristic;
  SPSProcessCharacteristicInput: SpsProcessCharacteristicInput;
  SPSProcessInput: SpsProcessInput;
  SPSQualification: SpsQualification;
  SPSQualificationInput: SpsQualificationInput;
  SPSReferencedDocument: SpsReferencedDocument;
  SPSSeal: SpsSeal;
  SPSSealInput: SpsSealInput;
  SPSTemperature: SpsTemperature;
  SPSTemperatureInput: SpsTemperatureInput;
  SPSTradeLineItem: SpsTradeLineItem;
  SPSTradeLineItemInput: SpsTradeLineItemInput;
  SPSTransportEquipment: SpsTransportEquipment;
  SPSTransportEquipmentInput: SpsTransportEquipmentInput;
  SPSTransportMovement: SpsTransportMovement;
  SPSTransportMovementInput: SpsTransportMovementInput;
  SPSTransportMovementTemplate: SpsTransportMovementTemplate;
  SPSWineConsignment: SpsWineConsignment;
  SalesForceAddress: SalesForceAddress;
  SalesForceContact: SalesForceContact;
  SalesForceEmail: SalesForceEmail;
  SalesForceIdentifier: SalesForceIdentifier;
  SalesForceParty: SalesForceParty;
  SalesForcePhone: SalesForcePhone;
  SalesForceProductId: SalesForceProductId;
  SearchApiKey: SearchApiKey;
  SearchOptions: SearchOptions;
  SensoryProcessInput: SensoryProcessInput;
  SensoryProcessRequest: SensoryProcessRequest;
  SfChangeExportCertificateRequestStatusInput: SfChangeExportCertificateRequestStatusInput;
  SfVerifyExportCertificateRequestProductsInput: SfVerifyExportCertificateRequestProductsInput;
  SfVerifyProductInput: SfVerifyProductInput;
  SpsAddressTemplate: SpsAddressTemplate;
  SpsAuthenticationTemplate: SpsAuthenticationTemplate;
  SpsCertificateTemplate: SpsCertificateTemplate;
  SpsClassificationTemplate: SpsClassificationTemplate;
  SpsClauseTemplate: SpsClauseTemplate;
  SpsConsignmentItemTemplate: SpsConsignmentItemTemplate;
  SpsConsignmentTemplate: SpsConsignmentTemplate;
  SpsContactTemplate: SpsContactTemplate;
  SpsCountrySubDivisionTemplate: SpsCountrySubDivisionTemplate;
  SpsCountryTemplate: SpsCountryTemplate;
  SpsEventTemplate: SpsEventTemplate;
  SpsExchangedDocumentTemplate: SpsExchangedDocumentTemplate;
  SpsLocationTemplate: SpsLocationTemplate;
  SpsNoteTemplate: SpsNoteTemplate;
  SpsPackageTemplate: SpsPackageTemplate;
  SpsPartyTemplate: SpsPartyTemplate;
  SpsPeriodTemplate: SpsPeriodTemplate;
  SpsPersonTemplate: SpsPersonTemplate;
  SpsProcessCharacteristicTemplate: SpsProcessCharacteristicTemplate;
  SpsProcessTemplate: SpsProcessTemplate;
  SpsQualificationTemplate: SpsQualificationTemplate;
  SpsReplacementCertificateTemplate: SpsReplacementCertificateTemplate;
  SpsSealTemplate: SpsSealTemplate;
  SpsTemperatureTemplate: SpsTemperatureTemplate;
  SpsTradeLineItemTemplate: SpsTradeLineItemTemplate;
  SpsTransportEquipmentTemplate: SpsTransportEquipmentTemplate;
  SpsWineConsignmentTemplate: SpsWineConsignmentTemplate;
  String: Scalars['String']['output'];
  Template: Template;
  TemplateDocumentUrl: TemplateDocumentUrl;
  TemplateFilter: TemplateFilter;
  TemplateHistory: TemplateHistory;
  TemplateOptionalFilter: TemplateOptionalFilter;
  TemplateSection: TemplateSection;
  TemplateSectionField: TemplateSectionField;
  TemplateUploadUrl: TemplateUploadUrl;
  TemplateVersion: TemplateVersion;
  TimeTemplate: TimeTemplate;
  TransportMovementCodeTemplate: TransportMovementCodeTemplate;
  TreatmentTemplate: TreatmentTemplate;
  UpdateTemplateInput: UpdateTemplateInput;
  UserProfile: UserProfile;
  WineAnalytesTolerancesInput: WineAnalytesTolerancesInput;
  WineAnalytesTolerancesResponse: WineAnalytesTolerancesResponse;
  WineAnalytesTolerancesResponseInput: WineAnalytesTolerancesResponseInput;
  WineAnalytesTolerancesResponseRowInput: WineAnalytesTolerancesResponseRowInput;
  WineAnalytesTolerancesType: WineAnalytesTolerancesType;
  WineFieldsTemplate: WineFieldsTemplate;
  WineProductFieldInput: WineProductFieldInput;
  WineProductFieldType: WineProductFieldType;
  Winemaking: Winemaking;
  WinemakingConfirmation: WinemakingConfirmation;
  WinemakingConfirmationInput: WinemakingConfirmationInput;
};

export type Aws_Cognito_User_PoolsDirectiveArgs = {
  cognito_groups?: Maybe<Array<Scalars['String']['input']>>;
};

export type Aws_Cognito_User_PoolsDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_Cognito_User_PoolsDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_IamDirectiveArgs = { };

export type Aws_IamDirectiveResolver<Result, Parent, ContextType = any, Args = Aws_IamDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export interface AwsDateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSDate'], any> {
  name: 'AWSDate';
}

export interface AwsDateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSDateTime'], any> {
  name: 'AWSDateTime';
}

export interface AwsEmailScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSEmail'], any> {
  name: 'AWSEmail';
}

export interface AwsipAddressScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSIPAddress'], any> {
  name: 'AWSIPAddress';
}

export interface AwsjsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSJSON'], any> {
  name: 'AWSJSON';
}

export interface AwsPhoneScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSPhone'], any> {
  name: 'AWSPhone';
}

export interface AwsTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSTime'], any> {
  name: 'AWSTime';
}

export interface AwsTimestampScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSTimestamp'], any> {
  name: 'AWSTimestamp';
}

export interface AwsurlScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['AWSURL'], any> {
  name: 'AWSURL';
}

export type AvailableTemplateFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AvailableTemplateFields'] = ResolversParentTypes['AvailableTemplateFields']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  commodity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  destinationMarket?: Resolver<ResolversTypes['DestinationMarket'], ParentType, ContextType>;
  displayedDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  exportCertificateType?: Resolver<Maybe<ResolversTypes['CertificateType']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  majorVersion?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  minorVersion?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GAddress'] = ResolversParentTypes['B2GAddress']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cityId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  countryId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  countryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countrySubdivisionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  countrySubdivisionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postcodeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  typeCode?: Resolver<Maybe<ResolversTypes['AddressTypeCode']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GAuthenticationResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GAuthentication'] = ResolversParentTypes['B2GAuthentication']> = {
  actual?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  includedClauses?: Resolver<Array<ResolversTypes['B2GClause']>, ParentType, ContextType>;
  issueLocation?: Resolver<Maybe<ResolversTypes['B2GLocation']>, ParentType, ContextType>;
  locationProviderParty?: Resolver<Maybe<ResolversTypes['B2GParty']>, ParentType, ContextType>;
  providerParty?: Resolver<Maybe<ResolversTypes['B2GParty']>, ParentType, ContextType>;
  typeCode?: Resolver<Maybe<ResolversTypes['NamedCode']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GCertificateDeliveryResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GCertificateDelivery'] = ResolversParentTypes['B2GCertificateDelivery']> = {
  certificateDeliveryMethod?: Resolver<Maybe<ResolversTypes['B2GCertificateDeliveryMethod']>, ParentType, ContextType>;
  certificateSigningOffice?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  collectionMethod?: Resolver<Maybe<ResolversTypes['B2GCollectionMethod']>, ParentType, ContextType>;
  copies?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  digitalScanRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  dueDate?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  recipientAddress?: Resolver<Maybe<ResolversTypes['B2GAddress']>, ParentType, ContextType>;
  recipientContactName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recipientEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GClassificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GClassification'] = ResolversParentTypes['B2GClassification']> = {
  classCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  className?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  systemId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  systemName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GClauseResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GClause'] = ResolversParentTypes['B2GClause']> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GConsignmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GConsignment'] = ResolversParentTypes['B2GConsignment']> = {
  EORINumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  carrier?: Resolver<Maybe<ResolversTypes['B2GParty']>, ParentType, ContextType>;
  consignee?: Resolver<Maybe<ResolversTypes['B2GParty']>, ParentType, ContextType>;
  consignor?: Resolver<Maybe<ResolversTypes['B2GParty']>, ParentType, ContextType>;
  consignorReference?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  containers?: Resolver<Array<ResolversTypes['B2GContainer']>, ParentType, ContextType>;
  delivery?: Resolver<Maybe<ResolversTypes['B2GParty']>, ParentType, ContextType>;
  expectedArrivalDateTime?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  exportExitDateTime?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  grossWeight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['B2GTradeLineItem']>, ParentType, ContextType>;
  loadingBasePort?: Resolver<Maybe<ResolversTypes['B2GLocation']>, ParentType, ContextType>;
  mainCarriageTransportMovements?: Resolver<Array<ResolversTypes['B2GTransportMovement']>, ParentType, ContextType>;
  unloadingBasePort?: Resolver<Maybe<ResolversTypes['B2GLocation']>, ParentType, ContextType>;
  verifier?: Resolver<Maybe<ResolversTypes['B2GParty']>, ParentType, ContextType>;
  wineConsignment?: Resolver<Maybe<ResolversTypes['B2GWineConsignment']>, ParentType, ContextType>;
  wineShippingFormat?: Resolver<Maybe<ResolversTypes['WinePackaging']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GContactResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GContact'] = ResolversParentTypes['B2GContact']> = {
  email?: Resolver<Maybe<ResolversTypes['AWSEmail']>, ParentType, ContextType>;
  personName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GContainerResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GContainer'] = ResolversParentTypes['B2GContainer']> = {
  affixedSeals?: Resolver<Array<ResolversTypes['B2GSeal']>, ParentType, ContextType>;
  containerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  temperatureSettings?: Resolver<Array<ResolversTypes['B2GTemperature']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GCountryResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GCountry'] = ResolversParentTypes['B2GCountry']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subdivision?: Resolver<Maybe<ResolversTypes['B2GCountrySubDivision']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GCountrySubDivisionResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GCountrySubDivision'] = ResolversParentTypes['B2GCountrySubDivision']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GDeclarationResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GDeclaration'] = ResolversParentTypes['B2GDeclaration']> = {
  eligibilityCriteria?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  market?: Resolver<Maybe<ResolversTypes['DestinationMarket']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GEligibilityCriteriaMetResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GEligibilityCriteriaMet'] = ResolversParentTypes['B2GEligibilityCriteriaMet']> = {
  effectiveDate?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  eligibilityCriteria?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  expiryDate?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  processId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GEligibilityStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GEligibilityStatus'] = ResolversParentTypes['B2GEligibilityStatus']> = {
  expiryDate?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  market?: Resolver<ResolversTypes['DestinationMarket'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['B2GEligibilityStatusType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GExportCertificateRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GExportCertificateRequest'] = ResolversParentTypes['B2GExportCertificateRequest']> = {
  certificateDelivery?: Resolver<Maybe<ResolversTypes['B2GCertificateDelivery']>, ParentType, ContextType>;
  certificateNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  certificateType?: Resolver<Maybe<ResolversTypes['CertificateType']>, ParentType, ContextType>;
  commodity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consignment?: Resolver<Maybe<ResolversTypes['B2GConsignment']>, ParentType, ContextType>;
  destination?: Resolver<Maybe<ResolversTypes['B2GCountry']>, ParentType, ContextType>;
  exporterDeclaration?: Resolver<Maybe<ResolversTypes['B2GExporterDeclaration']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastStatusChange?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  market?: Resolver<Maybe<ResolversTypes['DestinationMarket']>, ParentType, ContextType>;
  referencedDocuments?: Resolver<Array<ResolversTypes['B2GReferencedDocument']>, ParentType, ContextType>;
  requestName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requestedOnBehalfOfConsignor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ExportCertificateStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GExporterDeclarationResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GExporterDeclaration'] = ResolversParentTypes['B2GExporterDeclaration']> = {
  date?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GLocation'] = ResolversParentTypes['B2GLocation']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GNoteResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GNote'] = ResolversParentTypes['B2GNote']> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  contentCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subjectCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GPackageResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GPackage'] = ResolversParentTypes['B2GPackage']> = {
  levelCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nominalGrossVolume?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nominalGrossWeight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  packaging?: Resolver<Maybe<ResolversTypes['B2GPackage']>, ParentType, ContextType>;
  physicalShippingMarks?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  typeCode?: Resolver<Maybe<ResolversTypes['NamedCode']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GPartyResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GParty'] = ResolversParentTypes['B2GParty']> = {
  definedContacts?: Resolver<Array<ResolversTypes['B2GContact']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  specifiedAddress?: Resolver<Maybe<ResolversTypes['B2GAddress']>, ParentType, ContextType>;
  specifiedPerson?: Resolver<Maybe<ResolversTypes['B2GPerson']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GPeriodResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GPeriod'] = ResolversParentTypes['B2GPeriod']> = {
  duration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GPersonResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GPerson'] = ResolversParentTypes['B2GPerson']> = {
  attainedQualifications?: Resolver<Array<ResolversTypes['B2GQualification']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GPremiseResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GPremise'] = ResolversParentTypes['B2GPremise']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GProcessResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GProcess'] = ResolversParentTypes['B2GProcess']> = {
  applicableProcessCharacteristics?: Resolver<Array<ResolversTypes['B2GProcessCharacteristic']>, ParentType, ContextType>;
  completionPeriod?: Resolver<Maybe<ResolversTypes['B2GPeriod']>, ParentType, ContextType>;
  operationCountry?: Resolver<Maybe<ResolversTypes['B2GCountry']>, ParentType, ContextType>;
  operatorParty?: Resolver<Maybe<ResolversTypes['B2GParty']>, ParentType, ContextType>;
  typeCode?: Resolver<Maybe<ResolversTypes['NamedCode']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GProcessCharacteristicResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GProcessCharacteristic'] = ResolversParentTypes['B2GProcessCharacteristic']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maximum?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minimum?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  typeCode?: Resolver<Maybe<ResolversTypes['NamedCode']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GProduct'] = ResolversParentTypes['B2GProduct']> = {
  additionalInformation?: Resolver<Array<ResolversTypes['B2GNote']>, ParentType, ContextType>;
  appliedProcesses?: Resolver<Array<ResolversTypes['B2GProcess']>, ParentType, ContextType>;
  assertedAuthentications?: Resolver<Array<ResolversTypes['B2GAuthentication']>, ParentType, ContextType>;
  classifications?: Resolver<Array<ResolversTypes['B2GClassification']>, ParentType, ContextType>;
  cnCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commodity?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  commonName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  declarations?: Resolver<Maybe<Array<ResolversTypes['B2GDeclaration']>>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  eligibilityCriteriaFailed?: Resolver<Maybe<Array<ResolversTypes['B2GEligibilityCriteriaMet']>>, ParentType, ContextType>;
  eligibilityCriteriaMet?: Resolver<Maybe<Array<ResolversTypes['B2GEligibilityCriteriaMet']>>, ParentType, ContextType>;
  eligibilityStatus?: Resolver<Maybe<Array<ResolversTypes['B2GEligibilityStatus']>>, ParentType, ContextType>;
  events?: Resolver<Array<ResolversTypes['B2GProductEvent']>, ParentType, ContextType>;
  expiry?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  exporterReference?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  finalProcessor?: Resolver<Maybe<ResolversTypes['B2GParty']>, ParentType, ContextType>;
  grossVolume?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  grossWeight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hsCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  intendedUse?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  migrationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  modifiable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  netVolume?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netWeight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  physicalPackage?: Resolver<Maybe<ResolversTypes['B2GPackage']>, ParentType, ContextType>;
  premise?: Resolver<ResolversTypes['B2GPremise'], ParentType, ContextType>;
  productNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productionBatchIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  referencedDocuments?: Resolver<Array<ResolversTypes['B2GReferencedDocument']>, ParentType, ContextType>;
  scientificName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verifier?: Resolver<Maybe<ResolversTypes['B2GParty']>, ParentType, ContextType>;
  wine?: Resolver<Maybe<ResolversTypes['B2GWineFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GProductEligibilityResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GProductEligibility'] = ResolversParentTypes['B2GProductEligibility']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  criteriaId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  effectiveDate?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  executionArn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expiryDate?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  market?: Resolver<ResolversTypes['DestinationMarket'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['B2GEligibilityStatusType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GProductEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GProductEvent'] = ResolversParentTypes['B2GProductEvent']> = {
  from?: Resolver<ResolversTypes['B2GProduct'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  other?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  to?: Resolver<ResolversTypes['B2GProduct'], ParentType, ContextType>;
  what?: Resolver<ResolversTypes['B2GProductEventType'], ParentType, ContextType>;
  when?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  where?: Resolver<ResolversTypes['B2GPremise'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GQualificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GQualification'] = ResolversParentTypes['B2GQualification']> = {
  abbreviatedName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GReferencedDocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GReferencedDocument'] = ResolversParentTypes['B2GReferencedDocument']> = {
  attachment?: Resolver<Maybe<ResolversTypes['ReferencedDocument']>, ParentType, ContextType>;
  documentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  information?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  issued?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  relationshipTypeCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  requestorId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  typeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GSealResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GSeal'] = ResolversParentTypes['B2GSeal']> = {
  issuingParty?: Resolver<Maybe<ResolversTypes['B2GParty']>, ParentType, ContextType>;
  sealId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GTemperatureResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GTemperature'] = ResolversParentTypes['B2GTemperature']> = {
  maximum?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minimum?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GTradeLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GTradeLineItem'] = ResolversParentTypes['B2GTradeLineItem']> = {
  associatedTransportEquipment?: Resolver<Array<ResolversTypes['B2GContainer']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  physicalPackage?: Resolver<Maybe<ResolversTypes['B2GPackage']>, ParentType, ContextType>;
  product?: Resolver<Maybe<ResolversTypes['B2GProduct']>, ParentType, ContextType>;
  verified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GTradeLineItemContainerResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GTradeLineItemContainer'] = ResolversParentTypes['B2GTradeLineItemContainer']> = {
  containerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  grossVolume?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  grossWeight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netVolume?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  netWeight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GTransportMovementResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GTransportMovement'] = ResolversParentTypes['B2GTransportMovement']> = {
  ITDNNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mode?: Resolver<ResolversTypes['TransportModeCode'], ParentType, ContextType>;
  reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  registeredCountry?: Resolver<Maybe<ResolversTypes['B2GCountry']>, ParentType, ContextType>;
  usedTransportMeans?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GValidationErrorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GValidationErrors'] = ResolversParentTypes['B2GValidationErrors']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['B2GError']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GWineConsignmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GWineConsignment'] = ResolversParentTypes['B2GWineConsignment']> = {
  packaging?: Resolver<Maybe<ResolversTypes['B2GPackage']>, ParentType, ContextType>;
  shippingFormat?: Resolver<Maybe<ResolversTypes['WinePackaging']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GWineFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GWineFields'] = ResolversParentTypes['B2GWineFields']> = {
  EUCategory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  EUIngredients?: Resolver<Maybe<Array<ResolversTypes['EUIngredient']>>, ParentType, ContextType>;
  alternateWineNames?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  availableLitres?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  colour?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  exportedLitres?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  geographicIndication?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  grapeVariety?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mainNamePrefix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mainNameSuffix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  package?: Resolver<Maybe<ResolversTypes['B2GWinePackage']>, ParentType, ContextType>;
  shippingFormat?: Resolver<Maybe<ResolversTypes['WinePackaging']>, ParentType, ContextType>;
  specialProcess?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  style?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalPackagedLitres?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  vintage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  volumeAdjustment?: Resolver<Maybe<Array<ResolversTypes['ManualVolumeAdjustment']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type B2GWinePackageResolvers<ContextType = any, ParentType extends ResolversParentTypes['B2GWinePackage'] = ResolversParentTypes['B2GWinePackage']> = {
  bottleSize?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bottlesPerCase?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cases?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  extraBottles?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nameForDocuments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BottlingRunDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['BottlingRunData'] = ResolversParentTypes['BottlingRunData']> = {
  bottleVolume?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  bottlesPerCase?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cases?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  extraBottles?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  litres?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BottlingRunResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BottlingRunResponse'] = ResolversParentTypes['BottlingRunResponse']> = {
  processId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  runs?: Resolver<Array<ResolversTypes['BottlingRunData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CancelledProcessResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CancelledProcessResponse'] = ResolversParentTypes['CancelledProcessResponse']> = {
  cancelledReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstResultReportedDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receipt?: Resolver<ResolversTypes['ChemistryTestReceipt'], ParentType, ContextType>;
  request?: Resolver<ResolversTypes['ChemistryTestRequest'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChemistryTestReceiptResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChemistryTestReceipt'] = ResolversParentTypes['ChemistryTestReceipt']> = {
  laboratoryReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receiptDate?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  sampleSuitable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  unsuitableReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChemistryTestRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChemistryTestRequest'] = ResolversParentTypes['ChemistryTestRequest']> = {
  expectedAlcoholContent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  laboratory?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  packagingDate?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  packagingReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sampleCollectionDate?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  sampleReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  testRequired?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  wineTestReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChemistryTestResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChemistryTestResponse'] = ResolversParentTypes['ChemistryTestResponse']> = {
  cancelledReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstResultReportedDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receipt?: Resolver<ResolversTypes['ChemistryTestReceipt'], ParentType, ContextType>;
  request?: Resolver<ResolversTypes['ChemistryTestRequest'], ParentType, ContextType>;
  requestedDate?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CombinedPartyTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['CombinedPartyTemplate'] = ResolversParentTypes['CombinedPartyTemplate']> = {
  authenticator?: Resolver<Maybe<ResolversTypes['SpsPartyTemplate']>, ParentType, ContextType>;
  processor?: Resolver<Maybe<ResolversTypes['SpsPartyTemplate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfirmPackagingResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConfirmPackagingResponse'] = ResolversParentTypes['ConfirmPackagingResponse']> = {
  confirmed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  disputeReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disputeResolution?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  processId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomsPermitNumberUsageResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomsPermitNumberUsage'] = ResolversParentTypes['CustomsPermitNumberUsage']> = {
  allocatedInPastThirtyDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unallocated?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DateTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DateTemplate'] = ResolversParentTypes['DateTemplate']> = {
  day?: Resolver<ResolversTypes['IntTemplate'], ParentType, ContextType>;
  month?: Resolver<ResolversTypes['MonthTemplate'], ParentType, ContextType>;
  year?: Resolver<ResolversTypes['IntTemplate'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DateTimeTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DateTimeTemplate'] = ResolversParentTypes['DateTimeTemplate']> = {
  date?: Resolver<ResolversTypes['DateTemplate'], ParentType, ContextType>;
  time?: Resolver<ResolversTypes['TimeTemplate'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DecimalTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DecimalTemplate'] = ResolversParentTypes['DecimalTemplate']> = {
  in_English?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DecisionTableResolvers<ContextType = any, ParentType extends ResolversParentTypes['DecisionTable'] = ResolversParentTypes['DecisionTable']> = {
  approvedRevision?: Resolver<Maybe<ResolversTypes['DecisionTableRevision']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inputColumns?: Resolver<Array<ResolversTypes['DecisionTableColumn']>, ParentType, ContextType>;
  lastRevision?: Resolver<Maybe<ResolversTypes['DecisionTableRevision']>, ParentType, ContextType>;
  responseColumns?: Resolver<Array<ResolversTypes['DecisionTableColumn']>, ParentType, ContextType>;
  revisions?: Resolver<Array<ResolversTypes['DecisionTableRevision']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DecisionTableBaseInputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DecisionTableBaseInput'] = ResolversParentTypes['DecisionTableBaseInput']> = {
  __resolveType: TypeResolveFn<'DeclarationsType' | 'EligibilityType' | 'ExportCertificateWorkflowType' | 'ImportConnectionsType' | 'ProductFieldType' | 'WineAnalytesTolerancesType' | 'WineProductFieldType', ParentType, ContextType>;
  validFrom?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  validUntil?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
};

export type DecisionTableColumnResolvers<ContextType = any, ParentType extends ResolversParentTypes['DecisionTableColumn'] = ResolversParentTypes['DecisionTableColumn']> = {
  collection?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dataType?: Resolver<ResolversTypes['DecisionTableDataType'], ParentType, ContextType>;
  enumeration?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  enumerationInfo?: Resolver<Maybe<Array<ResolversTypes['EnumerationInfo']>>, ParentType, ContextType>;
  header?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  mandatory?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DecisionTableExpectationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DecisionTableExpectation'] = ResolversParentTypes['DecisionTableExpectation']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  input?: Resolver<ResolversTypes['RulesInput'], ParentType, ContextType>;
  response?: Resolver<ResolversTypes['RulesResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DecisionTableFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['DecisionTableField'] = ResolversParentTypes['DecisionTableField']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mandatory?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DecisionTableRevisionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DecisionTableRevision'] = ResolversParentTypes['DecisionTableRevision']> = {
  createdAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expectations?: Resolver<Array<ResolversTypes['DecisionTableExpectation']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  revisionNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rows?: Resolver<Array<ResolversTypes['DecisionTableRow']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['DecisionTableRevisionStatus'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DecisionTableRowResolvers<ContextType = any, ParentType extends ResolversParentTypes['DecisionTableRow'] = ResolversParentTypes['DecisionTableRow']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  input?: Resolver<ResolversTypes['RulesInput'], ParentType, ContextType>;
  response?: Resolver<ResolversTypes['RulesResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DecisionTableTestResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['DecisionTableTestResult'] = ResolversParentTypes['DecisionTableTestResult']> = {
  error_message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  succes?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeclarationFormletFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeclarationFormletFields'] = ResolversParentTypes['DeclarationFormletFields']> = {
  eligibilityCriteria?: Resolver<ResolversTypes['FormletField'], ParentType, ContextType>;
  market?: Resolver<ResolversTypes['FormletField'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['FormletField'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeclarationFormletSectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeclarationFormletSection'] = ResolversParentTypes['DeclarationFormletSection']> = {
  fields?: Resolver<ResolversTypes['DeclarationFormletFields'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['FormletFieldType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeclarationsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeclarationsResponse'] = ResolversParentTypes['DeclarationsResponse']> = {
  commodity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  declaration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  destinationMarket?: Resolver<Maybe<ResolversTypes['DestinationMarket']>, ParentType, ContextType>;
  eligibilityCriteria?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeclarationsTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeclarationsType'] = ResolversParentTypes['DeclarationsType']> = {
  commodity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  destinationMarket?: Resolver<Maybe<ResolversTypes['DestinationMarket']>, ParentType, ContextType>;
  processType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  validFrom?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  validUntil?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftExportCertificateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DraftExportCertificate'] = ResolversParentTypes['DraftExportCertificate']> = {
  executionArn?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EStarOrganisationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EStarOrganisation'] = ResolversParentTypes['EStarOrganisation']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EuIngredientResolvers<ContextType = any, ParentType extends ResolversParentTypes['EUIngredient'] = ResolversParentTypes['EUIngredient']> = {
  allergen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  eNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  functionalCategory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  ingredient?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  useENumber?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EuIngredientsTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['EUIngredientsTemplate'] = ResolversParentTypes['EUIngredientsTemplate']> = {
  as_formatted_paragraph?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EligibilityResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EligibilityResponse'] = ResolversParentTypes['EligibilityResponse']> = {
  commodity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  destinationMarket?: Resolver<Maybe<ResolversTypes['DestinationMarket']>, ParentType, ContextType>;
  eligibilityCriteria?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EligibilityTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EligibilityType'] = ResolversParentTypes['EligibilityType']> = {
  commodity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  destinationMarket?: Resolver<Maybe<ResolversTypes['DestinationMarket']>, ParentType, ContextType>;
  eligibilityCriteria?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  validFrom?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  validUntil?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnumerationInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['EnumerationInfo'] = ResolversParentTypes['EnumerationInfo']> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstarCancelRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['EstarCancelRequest'] = ResolversParentTypes['EstarCancelRequest']> = {
  executionArn?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExportCertificateRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExportCertificateRequest'] = ResolversParentTypes['ExportCertificateRequest']> = {
  certificate_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  certificate_type?: Resolver<ResolversTypes['CertificateType'], ParentType, ContextType>;
  certifier?: Resolver<Maybe<ResolversTypes['SPSAuthentication']>, ParentType, ContextType>;
  commodity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consignment?: Resolver<ResolversTypes['SPSConsignment'], ParentType, ContextType>;
  destination_market?: Resolver<ResolversTypes['DestinationMarket'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  reason_for_status_change?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requestor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requestor_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state_machine_execution_arn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ExportCertificateStatus'], ParentType, ContextType>;
  verifiers?: Resolver<Array<ResolversTypes['SPSAuthentication']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExportCertificateWorkflowResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExportCertificateWorkflowResponse'] = ResolversParentTypes['ExportCertificateWorkflowResponse']> = {
  validatonNeeded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExportCertificateWorkflowTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExportCertificateWorkflowType'] = ResolversParentTypes['ExportCertificateWorkflowType']> = {
  certificateType?: Resolver<Maybe<ResolversTypes['CertificateType']>, ParentType, ContextType>;
  validFrom?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  validUntil?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['Field'] = ResolversParentTypes['Field']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  valid?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlexiFillingRunDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlexiFillingRunData'] = ResolversParentTypes['FlexiFillingRunData']> = {
  date?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  litres?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlexiFillingRunResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlexiFillingRunResponse'] = ResolversParentTypes['FlexiFillingRunResponse']> = {
  processId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  runs?: Resolver<Array<ResolversTypes['FlexiFillingRunData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormResolvers<ContextType = any, ParentType extends ResolversParentTypes['Form'] = ResolversParentTypes['Form']> = {
  declarations?: Resolver<Array<ResolversTypes['DeclarationFormletSection']>, ParentType, ContextType>;
  productDetails?: Resolver<Array<ResolversTypes['FormletField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormletCollectionFilterResolvers<ContextType = any, ParentType extends ResolversParentTypes['FormletCollectionFilter'] = ResolversParentTypes['FormletCollectionFilter']> = {
  attribute?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormletFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['FormletField'] = ResolversParentTypes['FormletField']> = {
  charLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  collection?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filter?: Resolver<Maybe<ResolversTypes['FormletCollectionFilter']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nested?: Resolver<Maybe<Array<Maybe<ResolversTypes['FormletNestedFields']>>>, ParentType, ContextType>;
  numberConstrain?: Resolver<Maybe<ResolversTypes['NumberConstrain']>, ParentType, ContextType>;
  numberOfLines?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  options?: Resolver<Maybe<Array<Maybe<ResolversTypes['FormletOption']>>>, ParentType, ContextType>;
  readonly?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  required?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sectionFields?: Resolver<Maybe<Array<Maybe<ResolversTypes['FormletField']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['FormletFieldType']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormletNestedFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FormletNestedFields'] = ResolversParentTypes['FormletNestedFields']> = {
  collection?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['FormletFieldType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormletOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['FormletOption'] = ResolversParentTypes['FormletOption']> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IDsTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['IDsTemplate'] = ResolversParentTypes['IDsTemplate']> = {
  as_rows?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  comma_separated?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  new_line_separated?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IppcHubLogEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['IPPCHubLogEntry'] = ResolversParentTypes['IPPCHubLogEntry']> = {
  certificateId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  certificateNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  exportCountryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hubDeliveryNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hubTrackingInfo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImportCertificateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImportCertificate'] = ResolversParentTypes['ImportCertificate']> = {
  certificate_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  consignee?: Resolver<Maybe<ResolversTypes['SPSParty']>, ParentType, ContextType>;
  consignment?: Resolver<ResolversTypes['SPSConsignment'], ParentType, ContextType>;
  consignor?: Resolver<Maybe<ResolversTypes['SPSParty']>, ParentType, ContextType>;
  embeddedDocument?: Resolver<Array<ResolversTypes['ImportCertificateEmbeddedDocument']>, ParentType, ContextType>;
  exchanged_document?: Resolver<ResolversTypes['SPSExchangedDocument'], ParentType, ContextType>;
  export_country?: Resolver<ResolversTypes['SPSCountry'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  import_country?: Resolver<ResolversTypes['SPSCountry'], ParentType, ContextType>;
  included_notes?: Resolver<Array<ResolversTypes['SPSNote']>, ParentType, ContextType>;
  ippcLogs?: Resolver<Array<ResolversTypes['IPPCHubLogEntry']>, ParentType, ContextType>;
  issued?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  issuer?: Resolver<Maybe<ResolversTypes['SPSParty']>, ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['SPSTradeLineItem']>, ParentType, ContextType>;
  last_transport_movement?: Resolver<Maybe<ResolversTypes['SPSTransportMovement']>, ParentType, ContextType>;
  loading_base_port_location?: Resolver<Maybe<ResolversTypes['SPSLocation']>, ParentType, ContextType>;
  logs?: Resolver<Array<ResolversTypes['ImportCertificateLogMessage']>, ParentType, ContextType>;
  main_carriage_transport_movements?: Resolver<Maybe<Array<ResolversTypes['SPSTransportMovement']>>, ParentType, ContextType>;
  pdf_generated?: Resolver<ResolversTypes['PdfGenerationStatus'], ParentType, ContextType>;
  referenced_documents?: Resolver<Array<ResolversTypes['SPSReferencedDocument']>, ParentType, ContextType>;
  replaced_by?: Resolver<Maybe<ResolversTypes['ImportCertificate']>, ParentType, ContextType>;
  replacement_reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  replacing?: Resolver<Maybe<ResolversTypes['ImportCertificate']>, ParentType, ContextType>;
  replacing_certificate_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signatories?: Resolver<Array<ResolversTypes['SPSAuthentication']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ImportCertificateStatus'], ParentType, ContextType>;
  status_code?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  transit_countries?: Resolver<Maybe<Array<ResolversTypes['SPSCountry']>>, ParentType, ContextType>;
  type_code?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unloading_base_port_location?: Resolver<Maybe<ResolversTypes['SPSLocation']>, ParentType, ContextType>;
  utilized_transport_equipment?: Resolver<Array<ResolversTypes['SPSTransportEquipment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImportCertificateEmbeddedDocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImportCertificateEmbeddedDocument'] = ResolversParentTypes['ImportCertificateEmbeddedDocument']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  file_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  file_size?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  file_type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImportCertificateExtractItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImportCertificateExtractItem'] = ResolversParentTypes['ImportCertificateExtractItem']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  items?: Resolver<Maybe<Array<ResolversTypes['ImportCertificateExtractItem']>>, ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImportCertificateExtractMeasurementResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImportCertificateExtractMeasurement'] = ResolversParentTypes['ImportCertificateExtractMeasurement']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  measures?: Resolver<Maybe<Array<ResolversTypes['ImportCertificateExtractItem']>>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImportCertificateExtractTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImportCertificateExtractTemplate'] = ResolversParentTypes['ImportCertificateExtractTemplate']> = {
  as_items?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImportCertificateIntegrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImportCertificateIntegration'] = ResolversParentTypes['ImportCertificateIntegration']> = {
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  response?: Resolver<Maybe<ResolversTypes['ImportCertificateIntegrationResponse']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImportCertificateLogMessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImportCertificateLogMessage'] = ResolversParentTypes['ImportCertificateLogMessage']> = {
  createdBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  relatedCertificate?: Resolver<Maybe<ResolversTypes['ImportCertificate']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ImportCertificateLogStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImportCertificateMeasurementTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImportCertificateMeasurementTemplate'] = ResolversParentTypes['ImportCertificateMeasurementTemplate']> = {
  as_new_lines?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImportCertificatePackagingTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImportCertificatePackagingTemplate'] = ResolversParentTypes['ImportCertificatePackagingTemplate']> = {
  as_new_lines?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImportCertificatePdfResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImportCertificatePdf'] = ResolversParentTypes['ImportCertificatePdf']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['PdfGenerationStatus'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImportCertificateXmlResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImportCertificateXml'] = ResolversParentTypes['ImportCertificateXml']> = {
  documentName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImportConnectionsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImportConnectionsResponse'] = ResolversParentTypes['ImportConnectionsResponse']> = {
  connectionType?: Resolver<ResolversTypes['ConnectionType'], ParentType, ContextType>;
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  receiving?: Resolver<Maybe<ResolversTypes['ImportCertificateIntegrationResponse']>, ParentType, ContextType>;
  sending?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  spsAcknowledgement?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImportConnectionsTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImportConnectionsType'] = ResolversParentTypes['ImportConnectionsType']> = {
  connectionType?: Resolver<Maybe<ResolversTypes['ConnectionType']>, ParentType, ContextType>;
  countryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  validFrom?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  validUntil?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IndicatorTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['IndicatorType'] = ResolversParentTypes['IndicatorType']> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  format?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntTemplate'] = ResolversParentTypes['IntTemplate']> = {
  in_English?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManualVolumeAdjustmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManualVolumeAdjustment'] = ResolversParentTypes['ManualVolumeAdjustment']> = {
  date?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  litres?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MeasureTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MeasureTemplate'] = ResolversParentTypes['MeasureTemplate']> = {
  mpi_unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  three_decimals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  three_digits?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MonthTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['MonthTemplate'] = ResolversParentTypes['MonthTemplate']> = {
  abbreviation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  number?: Resolver<ResolversTypes['IntTemplate'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  acceptedTermsAndConditions?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  addBottlingRuns?: Resolver<ResolversTypes['BottlingRunResponse'], ParentType, ContextType, RequireFields<MutationAddBottlingRunsArgs, 'input'>>;
  addCustomsPermitNumbers?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<MutationAddCustomsPermitNumbersArgs, 'csv'>>;
  addFlexiFillingRuns?: Resolver<ResolversTypes['FlexiFillingRunResponse'], ParentType, ContextType, RequireFields<MutationAddFlexiFillingRunsArgs, 'input'>>;
  addProduct?: Resolver<ResolversTypes['B2GProduct'], ParentType, ContextType, RequireFields<MutationAddProductArgs, 'product'>>;
  allocateCustomsPermitNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationAllocateCustomsPermitNumberArgs, 'certificateRequestId'>>;
  appendIPPCHubLog?: Resolver<ResolversTypes['IPPCHubLogEntry'], ParentType, ContextType, RequireFields<MutationAppendIppcHubLogArgs, 'input'>>;
  appendImportCertificateLog?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAppendImportCertificateLogArgs, 'input'>>;
  approveExportCertificateRequest?: Resolver<Maybe<ResolversTypes['ExportCertificateRequest']>, ParentType, ContextType, RequireFields<MutationApproveExportCertificateRequestArgs, 'input'>>;
  attachTemplateDocument?: Resolver<ResolversTypes['TemplateUploadUrl'], ParentType, ContextType, RequireFields<MutationAttachTemplateDocumentArgs, 'templateId'>>;
  banWineBatch?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationBanWineBatchArgs, 'input'>>;
  cancelEstarRequest?: Resolver<ResolversTypes['EstarCancelRequest'], ParentType, ContextType, RequireFields<MutationCancelEstarRequestArgs, 'sampleId'>>;
  cancelExportCertificateRequest?: Resolver<ResolversTypes['B2GExportCertificateRequest'], ParentType, ContextType, RequireFields<MutationCancelExportCertificateRequestArgs, 'id'>>;
  cancelProcess?: Resolver<ResolversTypes['CancelledProcessResponse'], ParentType, ContextType, RequireFields<MutationCancelProcessArgs, 'input'>>;
  cloneTemplate?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<MutationCloneTemplateArgs, 'code' | 'parentTemplateId' | 'title'>>;
  completeSensoryProcess?: Resolver<ResolversTypes['SensoryProcessRequest'], ParentType, ContextType, RequireFields<MutationCompleteSensoryProcessArgs, 'input'>>;
  confirmPackaging?: Resolver<ResolversTypes['ConfirmPackagingResponse'], ParentType, ContextType, RequireFields<MutationConfirmPackagingArgs, 'input'>>;
  confirmWinemaking?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationConfirmWinemakingArgs, 'input'>>;
  createTemplate?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<MutationCreateTemplateArgs, 'template'>>;
  deleteAllCustomsPermitNumbers?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  deleteExportCertificateRequest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteExportCertificateRequestArgs, 'requestor' | 'requestor_id'>>;
  deleteExportRequest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteExportRequestArgs, 'id'>>;
  deleteProduct?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteProductArgs, 'id'>>;
  deleteReferencedDocument?: Resolver<ResolversTypes['ID'], ParentType, ContextType, RequireFields<MutationDeleteReferencedDocumentArgs, 'id'>>;
  deleteTemplate?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationDeleteTemplateArgs, 'id'>>;
  draftExportCertificateRequest?: Resolver<ResolversTypes['B2GExportCertificateRequest'], ParentType, ContextType, RequireFields<MutationDraftExportCertificateRequestArgs, 'request'>>;
  e2eRaiseExportCertificateRequest?: Resolver<Maybe<ResolversTypes['B2GExportCertificateRequest']>, ParentType, ContextType, RequireFields<MutationE2eRaiseExportCertificateRequestArgs, 'id' | 'partyId'>>;
  executeChemistryTestEvent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationExecuteChemistryTestEventArgs, 'input'>>;
  forceApproveTemplateVersion?: Resolver<ResolversTypes['TemplateVersion'], ParentType, ContextType, RequireFields<MutationForceApproveTemplateVersionArgs, 'templateVersionId'>>;
  generateDraftExportCertificate?: Resolver<ResolversTypes['DraftExportCertificate'], ParentType, ContextType, RequireFields<MutationGenerateDraftExportCertificateArgs, 'input'>>;
  getNewTemplateVersionUploadUrl?: Resolver<ResolversTypes['TemplateUploadUrl'], ParentType, ContextType, RequireFields<MutationGetNewTemplateVersionUploadUrlArgs, 'templateId'>>;
  makeProductEligible?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationMakeProductEligibleArgs, 'market' | 'productId'>>;
  newPartyApiKey?: Resolver<Maybe<ResolversTypes['SearchApiKey']>, ParentType, ContextType>;
  newReferencedDocument?: Resolver<ResolversTypes['NewReferencedDocument'], ParentType, ContextType, Partial<MutationNewReferencedDocumentArgs>>;
  newSearchApiKey?: Resolver<Maybe<ResolversTypes['SearchApiKey']>, ParentType, ContextType>;
  raiseExportCertificateRequest?: Resolver<Maybe<ResolversTypes['B2GExportCertificateRequest']>, ParentType, ContextType, RequireFields<MutationRaiseExportCertificateRequestArgs, 'id'>>;
  reindexCollection?: Resolver<ResolversTypes['ReindexResponse'], ParentType, ContextType, RequireFields<MutationReindexCollectionArgs, 'collectionName'>>;
  reindexDatabaseItem?: Resolver<ResolversTypes['ReindexResponse'], ParentType, ContextType, RequireFields<MutationReindexDatabaseItemArgs, 'collectionName' | 'ids'>>;
  rejectImportCertificate?: Resolver<Maybe<ResolversTypes['ImportCertificate']>, ParentType, ContextType, RequireFields<MutationRejectImportCertificateArgs, 'input'>>;
  requestChemistryTest?: Resolver<ResolversTypes['ChemistryTestResponse'], ParentType, ContextType, RequireFields<MutationRequestChemistryTestArgs, 'input'>>;
  requestConfirmation?: Resolver<ResolversTypes['RequestConfirmationResponse'], ParentType, ContextType, RequireFields<MutationRequestConfirmationArgs, 'input'>>;
  requestReplacementImportCertificate?: Resolver<Maybe<ResolversTypes['ImportCertificate']>, ParentType, ContextType, RequireFields<MutationRequestReplacementImportCertificateArgs, 'input'>>;
  resetConfirmationRequest?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationResetConfirmationRequestArgs, 'input'>>;
  saveExportCertificateRequest?: Resolver<ResolversTypes['ExportCertificateRequest'], ParentType, ContextType, RequireFields<MutationSaveExportCertificateRequestArgs, 'input'>>;
  setTemplateVersionApproved?: Resolver<ResolversTypes['TemplateVersion'], ParentType, ContextType, RequireFields<MutationSetTemplateVersionApprovedArgs, 'templateVersionId'>>;
  setTemplateVersionStatus?: Resolver<ResolversTypes['TemplateVersion'], ParentType, ContextType, RequireFields<MutationSetTemplateVersionStatusArgs, 'status' | 'templateVersionId'>>;
  sfChangeExportCertificateRequestStatus?: Resolver<Maybe<ResolversTypes['ExportCertificateRequest']>, ParentType, ContextType, RequireFields<MutationSfChangeExportCertificateRequestStatusArgs, 'input'>>;
  sfVerifyExportCertificateRequestProducts?: Resolver<Maybe<Array<Maybe<ResolversTypes['SPSTradeLineItem']>>>, ParentType, ContextType, RequireFields<MutationSfVerifyExportCertificateRequestProductsArgs, 'input'>>;
  splitProduct?: Resolver<Array<ResolversTypes['B2GProduct']>, ParentType, ContextType, RequireFields<MutationSplitProductArgs, 'fromProduct' | 'toProducts'>>;
  startPackaging?: Resolver<ResolversTypes['ProcessResponse'], ParentType, ContextType, RequireFields<MutationStartPackagingArgs, 'productId'>>;
  startWinemaking?: Resolver<ResolversTypes['ProcessResponse'], ParentType, ContextType, RequireFields<MutationStartWinemakingArgs, 'productId'>>;
  transferProduct?: Resolver<ResolversTypes['B2GProduct'], ParentType, ContextType, RequireFields<MutationTransferProductArgs, 'id' | 'toPremise'>>;
  updateDecisionTable?: Resolver<ResolversTypes['DecisionTableRevision'], ParentType, ContextType, RequireFields<MutationUpdateDecisionTableArgs, 'input'>>;
  updateTemplate?: Resolver<ResolversTypes['Template'], ParentType, ContextType, RequireFields<MutationUpdateTemplateArgs, 'template'>>;
};

export type NamedCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['NamedCode'] = ResolversParentTypes['NamedCode']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NamedCodeTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['NamedCodeTemplate'] = ResolversParentTypes['NamedCodeTemplate']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewReferencedDocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['NewReferencedDocument'] = ResolversParentTypes['NewReferencedDocument']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NumberConstrainResolvers<ContextType = any, ParentType extends ResolversParentTypes['NumberConstrain'] = ResolversParentTypes['NumberConstrain']> = {
  max?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  step?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PackageTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PackageTemplate'] = ResolversParentTypes['PackageTemplate']> = {
  bottle_size?: Resolver<ResolversTypes['MeasureTemplate'], ParentType, ContextType>;
  bottles_per_case?: Resolver<ResolversTypes['IntTemplate'], ParentType, ContextType>;
  cases?: Resolver<ResolversTypes['IntTemplate'], ParentType, ContextType>;
  extra_bottles?: Resolver<ResolversTypes['IntTemplate'], ParentType, ContextType>;
  total_bottles?: Resolver<Maybe<ResolversTypes['MeasureTemplate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PackagingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Packaging'] = ResolversParentTypes['Packaging']> = {
  availableLitres?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  confirmation?: Resolver<Maybe<ResolversTypes['PackagingConfirmation']>, ParentType, ContextType>;
  exportedLitres?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  runs?: Resolver<Array<ResolversTypes['PackagingRun']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['PackagingStatus'], ParentType, ContextType>;
  totalPackagedLitres?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  volumeAdjustment?: Resolver<Maybe<Array<Maybe<ResolversTypes['ManualVolumeAdjustment']>>>, ParentType, ContextType>;
  wsmp?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PackagingConfirmationResolvers<ContextType = any, ParentType extends ResolversParentTypes['PackagingConfirmation'] = ResolversParentTypes['PackagingConfirmation']> = {
  confirmed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  disputeReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disputeResolution?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PackagingRunResolvers<ContextType = any, ParentType extends ResolversParentTypes['PackagingRun'] = ResolversParentTypes['PackagingRun']> = {
  __resolveType: TypeResolveFn<'BottlingRunData' | 'FlexiFillingRunData', ParentType, ContextType>;
};

export type ProcessAttributesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProcessAttributesResponse'] = ResolversParentTypes['ProcessAttributesResponse']> = {
  __resolveType: TypeResolveFn<'ChemistryTestResponse' | 'Packaging' | 'SensoryProcessRequest' | 'Winemaking', ParentType, ContextType>;
};

export type ProcessResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProcessResponse'] = ResolversParentTypes['ProcessResponse']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  task?: Resolver<Maybe<ResolversTypes['ProcessTask']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ProductProcessType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProcessTaskResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProcessTask'] = ResolversParentTypes['ProcessTask']> = {
  assignedTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taskId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProcessTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProcessType'] = ResolversParentTypes['ProcessType']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ProductProcessType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductFieldResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductFieldResponse'] = ResolversParentTypes['ProductFieldResponse']> = {
  certificateType?: Resolver<Maybe<ResolversTypes['CertificateType']>, ParentType, ContextType>;
  collection?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commodity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defaultUnit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defaultValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  destinationMarket?: Resolver<Maybe<ResolversTypes['DestinationMarket']>, ParentType, ContextType>;
  fieldId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  listOfValues?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  maxStringLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  required?: Resolver<ResolversTypes['Optionality'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductFieldTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductFieldType'] = ResolversParentTypes['ProductFieldType']> = {
  certificateType?: Resolver<Maybe<ResolversTypes['CertificateType']>, ParentType, ContextType>;
  commodity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  destinationMarket?: Resolver<Maybe<ResolversTypes['DestinationMarket']>, ParentType, ContextType>;
  validFrom?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  validUntil?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductProcessResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductProcessResponse'] = ResolversParentTypes['ProductProcessResponse']> = {
  attributes?: Resolver<ResolversTypes['ProcessAttributesResponse'], ParentType, ContextType>;
  confirmationTaskId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  processId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  processType?: Resolver<ResolversTypes['ProductProcessType'], ParentType, ContextType>;
  requestTaskId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  resetTaskId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  taskId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  attestations?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType, RequireFields<QueryAttestationsArgs, 'certificateType' | 'commodity' | 'destination'>>;
  availableProcesses?: Resolver<Array<ResolversTypes['ProcessType']>, ParentType, ContextType, RequireFields<QueryAvailableProcessesArgs, 'productId'>>;
  availableTemplates?: Resolver<Maybe<Array<ResolversTypes['AvailableTemplateFields']>>, ParentType, ContextType, RequireFields<QueryAvailableTemplatesArgs, 'commodity' | 'countryCode'>>;
  certificateTemplateFields?: Resolver<Maybe<ResolversTypes['AWSJSON']>, ParentType, ContextType, Partial<QueryCertificateTemplateFieldsArgs>>;
  certificateTemplateFlatFields?: Resolver<Array<ResolversTypes['TemplateSection']>, ParentType, ContextType, Partial<QueryCertificateTemplateFlatFieldsArgs>>;
  certificateTemplateFlattestFields?: Resolver<Array<ResolversTypes['TemplateSectionField']>, ParentType, ContextType, Partial<QueryCertificateTemplateFlattestFieldsArgs>>;
  customsPermitNumberUsage?: Resolver<ResolversTypes['CustomsPermitNumberUsage'], ParentType, ContextType>;
  decisionTable?: Resolver<Maybe<ResolversTypes['DecisionTable']>, ParentType, ContextType, RequireFields<QueryDecisionTableArgs, 'id'>>;
  downloadDocument?: Resolver<ResolversTypes['TemplateDocumentUrl'], ParentType, ContextType, RequireFields<QueryDownloadDocumentArgs, 'templateVersionId'>>;
  downloadReferencedDocument?: Resolver<ResolversTypes['ReferencedDocumentUrl'], ParentType, ContextType, RequireFields<QueryDownloadReferencedDocumentArgs, 'id'>>;
  estarOrganisations?: Resolver<Array<ResolversTypes['EStarOrganisation']>, ParentType, ContextType, Partial<QueryEstarOrganisationsArgs>>;
  executeRules?: Resolver<Array<ResolversTypes['RulesResponse']>, ParentType, ContextType, RequireFields<QueryExecuteRulesArgs, 'input'>>;
  exportCertificateAsTemplateValues?: Resolver<ResolversTypes['SpsCertificateTemplate'], ParentType, ContextType, RequireFields<QueryExportCertificateAsTemplateValuesArgs, 'id'>>;
  exportCertificateRequest?: Resolver<Maybe<ResolversTypes['B2GExportCertificateRequest']>, ParentType, ContextType, RequireFields<QueryExportCertificateRequestArgs, 'id'>>;
  exportCertificateSupportingDocuments?: Resolver<Array<ResolversTypes['B2GReferencedDocument']>, ParentType, ContextType, RequireFields<QueryExportCertificateSupportingDocumentsArgs, 'input'>>;
  findApprovedImportCertificate?: Resolver<Maybe<ResolversTypes['TemplateVersion']>, ParentType, ContextType, RequireFields<QueryFindApprovedImportCertificateArgs, 'certificateType' | 'destinationMarket'>>;
  getApprovedTemplateVersion?: Resolver<Maybe<ResolversTypes['TemplateVersion']>, ParentType, ContextType, RequireFields<QueryGetApprovedTemplateVersionArgs, 'certificateType' | 'destinationMarket'>>;
  getExportCertificateRequest?: Resolver<Maybe<ResolversTypes['ExportCertificateRequest']>, ParentType, ContextType, RequireFields<QueryGetExportCertificateRequestArgs, 'id'>>;
  getFeatureFlags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  getImportCertificate?: Resolver<Maybe<ResolversTypes['ImportCertificate']>, ParentType, ContextType, RequireFields<QueryGetImportCertificateArgs, 'id'>>;
  getPresignedUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryGetPresignedUrlArgs, 'fileStore' | 'key'>>;
  getTemplate?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType, RequireFields<QueryGetTemplateArgs, 'templateId'>>;
  getTemplateVersion?: Resolver<Maybe<ResolversTypes['TemplateVersion']>, ParentType, ContextType, RequireFields<QueryGetTemplateVersionArgs, 'templateVersionId'>>;
  importCertificateEmbeddedDocument?: Resolver<ResolversTypes['ImportCertificateEmbeddedDocument'], ParentType, ContextType, RequireFields<QueryImportCertificateEmbeddedDocumentArgs, 'id'>>;
  importCertificatePdf?: Resolver<ResolversTypes['ImportCertificatePdf'], ParentType, ContextType, RequireFields<QueryImportCertificatePdfArgs, 'input'>>;
  importCertificateXml?: Resolver<ResolversTypes['ImportCertificateXml'], ParentType, ContextType, RequireFields<QueryImportCertificateXmlArgs, 'id'>>;
  listImportCertificateIntegrations?: Resolver<Array<ResolversTypes['ImportCertificateIntegration']>, ParentType, ContextType>;
  listImportCertificates?: Resolver<Array<ResolversTypes['ImportCertificate']>, ParentType, ContextType>;
  numberOfDocuments?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryNumberOfDocumentsArgs, 'collectionName'>>;
  product?: Resolver<ResolversTypes['B2GProduct'], ParentType, ContextType, RequireFields<QueryProductArgs, 'id'>>;
  productFields?: Resolver<Array<Maybe<ResolversTypes['ProductFieldResponse']>>, ParentType, ContextType, RequireFields<QueryProductFieldsArgs, 'input'>>;
  productForm?: Resolver<Array<Maybe<ResolversTypes['FormletField']>>, ParentType, ContextType, RequireFields<QueryProductFormArgs, 'input'>>;
  productFormlet?: Resolver<ResolversTypes['Form'], ParentType, ContextType, RequireFields<QueryProductFormletArgs, 'input'>>;
  productProcesses?: Resolver<Array<ResolversTypes['ProductProcessResponse']>, ParentType, ContextType, RequireFields<QueryProductProcessesArgs, 'input'>>;
  referenceDocuments?: Resolver<Maybe<Array<Maybe<ResolversTypes['B2GReferencedDocument']>>>, ParentType, ContextType, RequireFields<QueryReferenceDocumentsArgs, 'exportRequestId'>>;
  referencedDocument?: Resolver<Maybe<ResolversTypes['ReferencedDocument']>, ParentType, ContextType, RequireFields<QueryReferencedDocumentArgs, 'id'>>;
  salesForceApplicationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<QuerySalesForceApplicationIdArgs, 'requestId'>>;
  salesForceProductId?: Resolver<Maybe<ResolversTypes['SalesForceProductId']>, ParentType, ContextType, RequireFields<QuerySalesForceProductIdArgs, 'productId'>>;
  searchDecisionTables?: Resolver<Array<ResolversTypes['DecisionTable']>, ParentType, ContextType, Partial<QuerySearchDecisionTablesArgs>>;
  searchTemplateVersions?: Resolver<Array<ResolversTypes['TemplateVersion']>, ParentType, ContextType, Partial<QuerySearchTemplateVersionsArgs>>;
  searchTemplates?: Resolver<Array<ResolversTypes['Template']>, ParentType, ContextType, Partial<QuerySearchTemplatesArgs>>;
  sfExporter?: Resolver<ResolversTypes['SalesForceParty'], ParentType, ContextType, RequireFields<QuerySfExporterArgs, 'id'>>;
  testDecisionTableRevision?: Resolver<Array<ResolversTypes['DecisionTableTestResult']>, ParentType, ContextType, RequireFields<QueryTestDecisionTableRevisionArgs, 'input'>>;
  tradecertUserProfile?: Resolver<ResolversTypes['UserProfile'], ParentType, ContextType, Partial<QueryTradecertUserProfileArgs>>;
  validate?: Resolver<Maybe<Array<Maybe<ResolversTypes['B2GValidationErrors']>>>, ParentType, ContextType, RequireFields<QueryValidateArgs, 'id'>>;
  validateProduct?: Resolver<Maybe<Array<Maybe<ResolversTypes['B2GValidationErrors']>>>, ParentType, ContextType, RequireFields<QueryValidateProductArgs, 'id'>>;
};

export type ReferencedDocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReferencedDocument'] = ResolversParentTypes['ReferencedDocument']> = {
  filename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  uploadInProgress?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReferencedDocumentUrlResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReferencedDocumentUrl'] = ResolversParentTypes['ReferencedDocumentUrl']> = {
  documentName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReindexResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReindexResponse'] = ResolversParentTypes['ReindexResponse']> = {
  alias?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  collectionName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  functionName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequestConfirmationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequestConfirmationResponse'] = ResolversParentTypes['RequestConfirmationResponse']> = {
  processId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  wsmp?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RulesInputResolvers<ContextType = any, ParentType extends ResolversParentTypes['RulesInput'] = ResolversParentTypes['RulesInput']> = {
  __resolveType: TypeResolveFn<'DeclarationsType' | 'EligibilityType' | 'ExportCertificateWorkflowType' | 'ImportConnectionsType' | 'ProductFieldType' | 'WineAnalytesTolerancesType' | 'WineProductFieldType', ParentType, ContextType>;
};

export type RulesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RulesResponse'] = ResolversParentTypes['RulesResponse']> = {
  __resolveType: TypeResolveFn<'DeclarationsResponse' | 'EligibilityResponse' | 'ExportCertificateWorkflowResponse' | 'ImportConnectionsResponse' | 'ProductFieldResponse' | 'WineAnalytesTolerancesResponse', ParentType, ContextType>;
};

export type SpsAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSAddress'] = ResolversParentTypes['SPSAddress']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city_id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  country_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country_subdivision_id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  country_subdivision_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postcode_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type_code?: Resolver<Maybe<ResolversTypes['AddressTypeCode']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsAuthenticationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSAuthentication'] = ResolversParentTypes['SPSAuthentication']> = {
  actual?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  included_clauses?: Resolver<Array<ResolversTypes['SPSClause']>, ParentType, ContextType>;
  issue_location?: Resolver<Maybe<ResolversTypes['SPSLocation']>, ParentType, ContextType>;
  location_provider_party?: Resolver<Maybe<ResolversTypes['SPSParty']>, ParentType, ContextType>;
  provider_party?: Resolver<Maybe<ResolversTypes['SPSParty']>, ParentType, ContextType>;
  type_code?: Resolver<Maybe<ResolversTypes['NamedCode']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsCertificateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSCertificate'] = ResolversParentTypes['SPSCertificate']> = {
  consignment?: Resolver<ResolversTypes['SPSConsignment'], ParentType, ContextType>;
  exchanged_document?: Resolver<ResolversTypes['SPSExchangedDocument'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsClassificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSClassification'] = ResolversParentTypes['SPSClassification']> = {
  class_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  class_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  system_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  system_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsClauseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSClause'] = ResolversParentTypes['SPSClause']> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsConsignmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSConsignment'] = ResolversParentTypes['SPSConsignment']> = {
  availability_due_date_time?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  carrier?: Resolver<Maybe<ResolversTypes['SPSParty']>, ParentType, ContextType>;
  consignee?: Resolver<Maybe<ResolversTypes['SPSParty']>, ParentType, ContextType>;
  consignee_receipt_location?: Resolver<Maybe<ResolversTypes['SPSLocation']>, ParentType, ContextType>;
  consignor?: Resolver<Maybe<ResolversTypes['SPSParty']>, ParentType, ContextType>;
  customs_transit_agent?: Resolver<Maybe<ResolversTypes['SPSParty']>, ParentType, ContextType>;
  delivery?: Resolver<Maybe<ResolversTypes['SPSParty']>, ParentType, ContextType>;
  despatch?: Resolver<Maybe<ResolversTypes['SPSParty']>, ParentType, ContextType>;
  examination_event?: Resolver<Maybe<ResolversTypes['SPSEvent']>, ParentType, ContextType>;
  export_country?: Resolver<Maybe<ResolversTypes['SPSCountry']>, ParentType, ContextType>;
  export_exit_date_time?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  exporter_reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gross_weight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  import_country?: Resolver<Maybe<ResolversTypes['SPSCountry']>, ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['SPSConsignmentItem']>, ParentType, ContextType>;
  loading_base_port_location?: Resolver<Maybe<ResolversTypes['SPSLocation']>, ParentType, ContextType>;
  main_carriage_transport_movements?: Resolver<Array<ResolversTypes['SPSTransportMovement']>, ParentType, ContextType>;
  reexport_country?: Resolver<Maybe<ResolversTypes['SPSCountry']>, ParentType, ContextType>;
  ship_stores_indicator?: Resolver<Maybe<ResolversTypes['IndicatorType']>, ParentType, ContextType>;
  storage_events?: Resolver<Array<ResolversTypes['SPSEvent']>, ParentType, ContextType>;
  transit_countries?: Resolver<Array<ResolversTypes['SPSCountry']>, ParentType, ContextType>;
  transit_locations?: Resolver<Array<ResolversTypes['SPSLocation']>, ParentType, ContextType>;
  unloading_base_port_location?: Resolver<Maybe<ResolversTypes['SPSLocation']>, ParentType, ContextType>;
  utilized_transport_equipment?: Resolver<Array<ResolversTypes['SPSTransportEquipment']>, ParentType, ContextType>;
  wine?: Resolver<Maybe<ResolversTypes['SPSWineConsignment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsConsignmentItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSConsignmentItem'] = ResolversParentTypes['SPSConsignmentItem']> = {
  gross_weight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['SPSTradeLineItem']>, ParentType, ContextType>;
  nature_identification_cargo?: Resolver<Array<ResolversTypes['CargoTypeClassificationCode']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsContactResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSContact'] = ResolversParentTypes['SPSContact']> = {
  person_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsCountryResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSCountry'] = ResolversParentTypes['SPSCountry']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subdivision?: Resolver<Maybe<ResolversTypes['SPSCountrySubDivision']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsCountrySubDivisionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSCountrySubDivision'] = ResolversParentTypes['SPSCountrySubDivision']> = {
  hierarchicalLevelCode?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSEvent'] = ResolversParentTypes['SPSEvent']> = {
  occurrence_location?: Resolver<Maybe<ResolversTypes['SPSLocation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsExchangedDocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSExchangedDocument'] = ResolversParentTypes['SPSExchangedDocument']> = {
  certificate_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  certifier?: Resolver<Maybe<ResolversTypes['SPSAuthentication']>, ParentType, ContextType>;
  customs_permit_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  included_notes?: Resolver<Array<ResolversTypes['SPSNote']>, ParentType, ContextType>;
  issue_date_time?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  issuer?: Resolver<ResolversTypes['SPSParty'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  referenced_documents?: Resolver<Array<ResolversTypes['SPSReferencedDocument']>, ParentType, ContextType>;
  signatories?: Resolver<Array<ResolversTypes['SPSAuthentication']>, ParentType, ContextType>;
  status_code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type_code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  verifiers?: Resolver<Array<ResolversTypes['SPSAuthentication']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSLocation'] = ResolversParentTypes['SPSLocation']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsNoteResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSNote'] = ResolversParentTypes['SPSNote']> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsPackageResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSPackage'] = ResolversParentTypes['SPSPackage']> = {
  level_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nominal_gross_volume?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nominal_gross_weight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  physical_shipping_marks?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type_code?: Resolver<Maybe<ResolversTypes['NamedCode']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsPartyResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSParty'] = ResolversParentTypes['SPSParty']> = {
  defined_contacts?: Resolver<Array<ResolversTypes['SPSContact']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  party_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  specified_address?: Resolver<Maybe<ResolversTypes['SPSAddress']>, ParentType, ContextType>;
  specified_person?: Resolver<Maybe<ResolversTypes['SPSPerson']>, ParentType, ContextType>;
  type_code?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsPeriodResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSPeriod'] = ResolversParentTypes['SPSPeriod']> = {
  duration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsPersonResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSPerson'] = ResolversParentTypes['SPSPerson']> = {
  attained_qualifications?: Resolver<Array<ResolversTypes['SPSQualification']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsProcessResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSProcess'] = ResolversParentTypes['SPSProcess']> = {
  applicable_process_characteristics?: Resolver<Array<ResolversTypes['SPSProcessCharacteristic']>, ParentType, ContextType>;
  completion_period?: Resolver<Maybe<ResolversTypes['SPSPeriod']>, ParentType, ContextType>;
  operation_country?: Resolver<Maybe<ResolversTypes['SPSCountry']>, ParentType, ContextType>;
  operator_party?: Resolver<Maybe<ResolversTypes['SPSParty']>, ParentType, ContextType>;
  type_code?: Resolver<Maybe<ResolversTypes['NamedCode']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsProcessCharacteristicResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSProcessCharacteristic'] = ResolversParentTypes['SPSProcessCharacteristic']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maximum?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minimum?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type_code?: Resolver<Maybe<ResolversTypes['NamedCode']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsQualificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSQualification'] = ResolversParentTypes['SPSQualification']> = {
  abbreviated_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsReferencedDocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSReferencedDocument'] = ResolversParentTypes['SPSReferencedDocument']> = {
  checksum?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  document_id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  filename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filetype?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  information?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  issued?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  relationship_type_code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsSealResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSSeal'] = ResolversParentTypes['SPSSeal']> = {
  issuing_party?: Resolver<Maybe<ResolversTypes['SPSParty']>, ParentType, ContextType>;
  maximum_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seal_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsTemperatureResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSTemperature'] = ResolversParentTypes['SPSTemperature']> = {
  maximum?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minimum?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsTradeLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSTradeLineItem'] = ResolversParentTypes['SPSTradeLineItem']> = {
  additional_information?: Resolver<Array<ResolversTypes['SPSNote']>, ParentType, ContextType>;
  applied_processes?: Resolver<Array<ResolversTypes['SPSProcess']>, ParentType, ContextType>;
  asserted_authentications?: Resolver<Array<ResolversTypes['SPSAuthentication']>, ParentType, ContextType>;
  associated_transport_equipment?: Resolver<Array<ResolversTypes['SPSTransportEquipment']>, ParentType, ContextType>;
  brand_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classifications?: Resolver<Array<ResolversTypes['SPSClassification']>, ParentType, ContextType>;
  cn_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commodity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  common_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expiry_date_time?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  gross_volume?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gross_weight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hs_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  import_certificate_extract?: Resolver<Maybe<Array<ResolversTypes['ImportCertificateExtractItem']>>, ParentType, ContextType>;
  import_certificate_measurement?: Resolver<Maybe<Array<ResolversTypes['ImportCertificateExtractMeasurement']>>, ParentType, ContextType>;
  import_permit_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  intended_use?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  net_volume?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  net_weight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  origin_countries?: Resolver<Maybe<Array<ResolversTypes['SPSCountry']>>, ParentType, ContextType>;
  origin_country?: Resolver<Maybe<ResolversTypes['SPSCountry']>, ParentType, ContextType>;
  origin_locations?: Resolver<Array<ResolversTypes['SPSLocation']>, ParentType, ContextType>;
  other_quantity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  physical_packages?: Resolver<Array<ResolversTypes['SPSPackage']>, ParentType, ContextType>;
  production_batch_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  referenced_documents?: Resolver<Array<ResolversTypes['SPSReferencedDocument']>, ParentType, ContextType>;
  requestor_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scientific_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sequence?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  verified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  wine?: Resolver<Maybe<ResolversTypes['B2GWineFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsTransportEquipmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSTransportEquipment'] = ResolversParentTypes['SPSTransportEquipment']> = {
  affixed_seals?: Resolver<Array<ResolversTypes['SPSSeal']>, ParentType, ContextType>;
  temperature_settings?: Resolver<Array<ResolversTypes['SPSTemperature']>, ParentType, ContextType>;
  transport_equipment_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsTransportMovementResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSTransportMovement'] = ResolversParentTypes['SPSTransportMovement']> = {
  mode?: Resolver<ResolversTypes['TransportModeCode'], ParentType, ContextType>;
  mode_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  used_transport_means?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsTransportMovementTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSTransportMovementTemplate'] = ResolversParentTypes['SPSTransportMovementTemplate']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  mode?: Resolver<ResolversTypes['TransportMovementCodeTemplate'], ParentType, ContextType>;
  used_transport_means?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsWineConsignmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['SPSWineConsignment'] = ResolversParentTypes['SPSWineConsignment']> = {
  is_bulk?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesForceAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesForceAddress'] = ResolversParentTypes['SalesForceAddress']> = {
  addressType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isPrimary?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesForceContactResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesForceContact'] = ResolversParentTypes['SalesForceContact']> = {
  emails?: Resolver<Array<ResolversTypes['SalesForceEmail']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phones?: Resolver<Array<ResolversTypes['SalesForcePhone']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesForceEmailResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesForceEmail'] = ResolversParentTypes['SalesForceEmail']> = {
  emailAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isPrimary?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesForceIdentifierResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesForceIdentifier'] = ResolversParentTypes['SalesForceIdentifier']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesForcePartyResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesForceParty'] = ResolversParentTypes['SalesForceParty']> = {
  addresses?: Resolver<Array<ResolversTypes['SalesForceAddress']>, ParentType, ContextType>;
  contacts?: Resolver<Array<ResolversTypes['SalesForceContact']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  identifiers?: Resolver<Array<ResolversTypes['SalesForceIdentifier']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nzbn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  registrationNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tradingAs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesForcePhoneResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesForcePhone'] = ResolversParentTypes['SalesForcePhone']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isPrimary?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  phoneType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  telephoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalesForceProductIdResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalesForceProductId'] = ResolversParentTypes['SalesForceProductId']> = {
  productId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  productRevisionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  sfProductId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchApiKeyResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchApiKey'] = ResolversParentTypes['SearchApiKey']> = {
  apiKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expires?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SensoryProcessRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['SensoryProcessRequest'] = ResolversParentTypes['SensoryProcessRequest']> = {
  assessmentDate?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disputed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  disputedReason?: Resolver<Maybe<ResolversTypes['WineDisputedReason']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  productId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  reference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  winemaker?: Resolver<ResolversTypes['B2GContact'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsAddressTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsAddressTemplate'] = ResolversParentTypes['SpsAddressTemplate']> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city_id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  country_id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  country_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country_subdivision_id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  country_subdivision_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postcode_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street_with_commas?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street_with_line_breaks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  with_line_breaks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsAuthenticationTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsAuthenticationTemplate'] = ResolversParentTypes['SpsAuthenticationTemplate']> = {
  actual?: Resolver<Maybe<ResolversTypes['DateTimeTemplate']>, ParentType, ContextType>;
  ic_label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ic_value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  included_clauses?: Resolver<Maybe<Array<ResolversTypes['SpsClauseTemplate']>>, ParentType, ContextType>;
  issue_location?: Resolver<Maybe<ResolversTypes['SpsLocationTemplate']>, ParentType, ContextType>;
  location_provider_party?: Resolver<Maybe<ResolversTypes['SpsPartyTemplate']>, ParentType, ContextType>;
  provider_party?: Resolver<Maybe<ResolversTypes['SpsPartyTemplate']>, ParentType, ContextType>;
  type_code?: Resolver<Maybe<ResolversTypes['NamedCodeTemplate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsCertificateTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsCertificateTemplate'] = ResolversParentTypes['SpsCertificateTemplate']> = {
  consignment?: Resolver<ResolversTypes['SpsConsignmentTemplate'], ParentType, ContextType>;
  exchanged_document?: Resolver<ResolversTypes['SpsExchangedDocumentTemplate'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsClassificationTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsClassificationTemplate'] = ResolversParentTypes['SpsClassificationTemplate']> = {
  class_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  class_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  system_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  system_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsClauseTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsClauseTemplate'] = ResolversParentTypes['SpsClauseTemplate']> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsConsignmentItemTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsConsignmentItemTemplate'] = ResolversParentTypes['SpsConsignmentItemTemplate']> = {
  gross_weight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['SpsTradeLineItemTemplate']>, ParentType, ContextType>;
  nature_identification_cargo?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsConsignmentTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsConsignmentTemplate'] = ResolversParentTypes['SpsConsignmentTemplate']> = {
  authentication_parties?: Resolver<Maybe<Array<ResolversTypes['SpsPartyTemplate']>>, ParentType, ContextType>;
  availability_due_date_time?: Resolver<Maybe<ResolversTypes['DateTimeTemplate']>, ParentType, ContextType>;
  carrier?: Resolver<Maybe<ResolversTypes['SpsPartyTemplate']>, ParentType, ContextType>;
  consignee?: Resolver<Maybe<ResolversTypes['SpsPartyTemplate']>, ParentType, ContextType>;
  consignee_receipt_location?: Resolver<Maybe<ResolversTypes['SpsLocationTemplate']>, ParentType, ContextType>;
  consignor?: Resolver<Maybe<ResolversTypes['SpsPartyTemplate']>, ParentType, ContextType>;
  customs_transit_agent?: Resolver<Maybe<ResolversTypes['SpsPartyTemplate']>, ParentType, ContextType>;
  delivery?: Resolver<Maybe<ResolversTypes['SpsPartyTemplate']>, ParentType, ContextType>;
  despatch?: Resolver<Maybe<ResolversTypes['SpsPartyTemplate']>, ParentType, ContextType>;
  examination_event?: Resolver<Maybe<ResolversTypes['SpsEventTemplate']>, ParentType, ContextType>;
  export_country?: Resolver<Maybe<ResolversTypes['SpsCountryTemplate']>, ParentType, ContextType>;
  export_exit_date_time?: Resolver<Maybe<ResolversTypes['DateTimeTemplate']>, ParentType, ContextType>;
  gross_weight?: Resolver<Maybe<ResolversTypes['MeasureTemplate']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  import_country?: Resolver<Maybe<ResolversTypes['SpsCountryTemplate']>, ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['SpsConsignmentItemTemplate']>, ParentType, ContextType>;
  loading_base_port_location?: Resolver<Maybe<ResolversTypes['SpsLocationTemplate']>, ParentType, ContextType>;
  main_carriage_transport_movements?: Resolver<Maybe<Array<ResolversTypes['SPSTransportMovementTemplate']>>, ParentType, ContextType>;
  party_table?: Resolver<Maybe<Array<ResolversTypes['CombinedPartyTemplate']>>, ParentType, ContextType>;
  processor_parties?: Resolver<Maybe<Array<ResolversTypes['SpsPartyTemplate']>>, ParentType, ContextType>;
  reexport_country?: Resolver<Maybe<ResolversTypes['SpsCountryTemplate']>, ParentType, ContextType>;
  storage_events?: Resolver<Maybe<Array<ResolversTypes['SpsEventTemplate']>>, ParentType, ContextType>;
  transit_countries?: Resolver<Maybe<Array<ResolversTypes['SpsCountryTemplate']>>, ParentType, ContextType>;
  transit_locations?: Resolver<Maybe<Array<ResolversTypes['SpsLocationTemplate']>>, ParentType, ContextType>;
  unique_production_batch_ids?: Resolver<Maybe<ResolversTypes['IDsTemplate']>, ParentType, ContextType>;
  unique_seal_number_ids?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  unique_transport_equipment_ids?: Resolver<Maybe<ResolversTypes['IDsTemplate']>, ParentType, ContextType>;
  unloading_base_port_location?: Resolver<Maybe<ResolversTypes['SpsLocationTemplate']>, ParentType, ContextType>;
  utilized_transport_equipment?: Resolver<Maybe<Array<ResolversTypes['SpsTransportEquipmentTemplate']>>, ParentType, ContextType>;
  verifiers?: Resolver<Maybe<Array<ResolversTypes['SpsAuthenticationTemplate']>>, ParentType, ContextType>;
  wine?: Resolver<Maybe<ResolversTypes['SpsWineConsignmentTemplate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsContactTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsContactTemplate'] = ResolversParentTypes['SpsContactTemplate']> = {
  person_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsCountrySubDivisionTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsCountrySubDivisionTemplate'] = ResolversParentTypes['SpsCountrySubDivisionTemplate']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsCountryTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsCountryTemplate'] = ResolversParentTypes['SpsCountryTemplate']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subdivision?: Resolver<Maybe<ResolversTypes['SpsCountrySubDivisionTemplate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsEventTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsEventTemplate'] = ResolversParentTypes['SpsEventTemplate']> = {
  occurrence_location?: Resolver<Maybe<ResolversTypes['SpsLocationTemplate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsExchangedDocumentTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsExchangedDocumentTemplate'] = ResolversParentTypes['SpsExchangedDocumentTemplate']> = {
  additional_declarations?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  additional_information?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  additional_official_information?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  certifier?: Resolver<Maybe<ResolversTypes['SpsAuthenticationTemplate']>, ParentType, ContextType>;
  customs_permit_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date_of_inspection?: Resolver<Maybe<ResolversTypes['DateTimeTemplate']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  distinguishing_marks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  financial_liability?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  import_permit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  issue_date_time?: Resolver<Maybe<ResolversTypes['DateTimeTemplate']>, ParentType, ContextType>;
  issuer?: Resolver<Maybe<ResolversTypes['SpsPartyTemplate']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  replacement_certificate?: Resolver<Maybe<ResolversTypes['SpsReplacementCertificateTemplate']>, ParentType, ContextType>;
  signatories?: Resolver<Maybe<Array<Maybe<ResolversTypes['SpsAuthenticationTemplate']>>>, ParentType, ContextType>;
  status_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsLocationTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsLocationTemplate'] = ResolversParentTypes['SpsLocationTemplate']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsNoteTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsNoteTemplate'] = ResolversParentTypes['SpsNoteTemplate']> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  content_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsPackageTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsPackageTemplate'] = ResolversParentTypes['SpsPackageTemplate']> = {
  level_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nominal_gross_volume?: Resolver<Maybe<ResolversTypes['MeasureTemplate']>, ParentType, ContextType>;
  nominal_gross_weight?: Resolver<Maybe<ResolversTypes['MeasureTemplate']>, ParentType, ContextType>;
  physical_shipping_marks?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['DecimalTemplate']>, ParentType, ContextType>;
  type_code?: Resolver<Maybe<ResolversTypes['NamedCodeTemplate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsPartyTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsPartyTemplate'] = ResolversParentTypes['SpsPartyTemplate']> = {
  defined_contacts?: Resolver<Maybe<Array<ResolversTypes['SpsContactTemplate']>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  specified_address?: Resolver<Maybe<ResolversTypes['SpsAddressTemplate']>, ParentType, ContextType>;
  specified_person?: Resolver<Maybe<ResolversTypes['SpsPersonTemplate']>, ParentType, ContextType>;
  type_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsPeriodTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsPeriodTemplate'] = ResolversParentTypes['SpsPeriodTemplate']> = {
  duration?: Resolver<Maybe<ResolversTypes['MeasureTemplate']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['DateTimeTemplate']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTimeTemplate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsPersonTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsPersonTemplate'] = ResolversParentTypes['SpsPersonTemplate']> = {
  attained_qualifications?: Resolver<Maybe<Array<ResolversTypes['SpsQualificationTemplate']>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsProcessCharacteristicTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsProcessCharacteristicTemplate'] = ResolversParentTypes['SpsProcessCharacteristicTemplate']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maximum?: Resolver<Maybe<ResolversTypes['MeasureTemplate']>, ParentType, ContextType>;
  minimum?: Resolver<Maybe<ResolversTypes['MeasureTemplate']>, ParentType, ContextType>;
  type_code?: Resolver<Maybe<ResolversTypes['NamedCodeTemplate']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['MeasureTemplate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsProcessTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsProcessTemplate'] = ResolversParentTypes['SpsProcessTemplate']> = {
  applicable_process_characteristics?: Resolver<Maybe<Array<ResolversTypes['SpsProcessCharacteristicTemplate']>>, ParentType, ContextType>;
  completion_period?: Resolver<Maybe<ResolversTypes['SpsPeriodTemplate']>, ParentType, ContextType>;
  operation_country?: Resolver<Maybe<ResolversTypes['SpsCountryTemplate']>, ParentType, ContextType>;
  operator_party?: Resolver<Maybe<ResolversTypes['SpsPartyTemplate']>, ParentType, ContextType>;
  type_code?: Resolver<Maybe<ResolversTypes['NamedCodeTemplate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsQualificationTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsQualificationTemplate'] = ResolversParentTypes['SpsQualificationTemplate']> = {
  abbreviated_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsReplacementCertificateTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsReplacementCertificateTemplate'] = ResolversParentTypes['SpsReplacementCertificateTemplate']> = {
  additional_declaration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  replaced_certificate_issue_date?: Resolver<Maybe<ResolversTypes['DateTimeTemplate']>, ParentType, ContextType>;
  replaced_certificate_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  replacement_reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsSealTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsSealTemplate'] = ResolversParentTypes['SpsSealTemplate']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  issuing_party?: Resolver<Maybe<ResolversTypes['SpsPartyTemplate']>, ParentType, ContextType>;
  maximum_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsTemperatureTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsTemperatureTemplate'] = ResolversParentTypes['SpsTemperatureTemplate']> = {
  maximum?: Resolver<Maybe<ResolversTypes['MeasureTemplate']>, ParentType, ContextType>;
  minimum?: Resolver<Maybe<ResolversTypes['MeasureTemplate']>, ParentType, ContextType>;
  type_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['MeasureTemplate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsTradeLineItemTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsTradeLineItemTemplate'] = ResolversParentTypes['SpsTradeLineItemTemplate']> = {
  additional_information?: Resolver<Maybe<Array<ResolversTypes['SpsNoteTemplate']>>, ParentType, ContextType>;
  applied_processes?: Resolver<Maybe<Array<ResolversTypes['SpsProcessTemplate']>>, ParentType, ContextType>;
  asserted_authentications?: Resolver<Maybe<Array<ResolversTypes['SpsAuthenticationTemplate']>>, ParentType, ContextType>;
  associated_transport_equipment?: Resolver<Maybe<Array<ResolversTypes['SpsTransportEquipmentTemplate']>>, ParentType, ContextType>;
  brand_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classifications?: Resolver<Maybe<Array<ResolversTypes['SpsClassificationTemplate']>>, ParentType, ContextType>;
  cn_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commodity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  common_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expiry_date_time?: Resolver<Maybe<ResolversTypes['DateTimeTemplate']>, ParentType, ContextType>;
  gross_volume?: Resolver<Maybe<ResolversTypes['MeasureTemplate']>, ParentType, ContextType>;
  gross_weight?: Resolver<Maybe<ResolversTypes['MeasureTemplate']>, ParentType, ContextType>;
  hs_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  import_certificate_extract?: Resolver<Maybe<ResolversTypes['ImportCertificateExtractTemplate']>, ParentType, ContextType>;
  import_certificate_measurement?: Resolver<Maybe<ResolversTypes['ImportCertificateMeasurementTemplate']>, ParentType, ContextType>;
  import_permit_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  intended_use?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  net_volume?: Resolver<Maybe<ResolversTypes['MeasureTemplate']>, ParentType, ContextType>;
  net_weight?: Resolver<Maybe<ResolversTypes['MeasureTemplate']>, ParentType, ContextType>;
  origin_country?: Resolver<Maybe<ResolversTypes['SpsCountryTemplate']>, ParentType, ContextType>;
  origin_locations?: Resolver<Maybe<Array<ResolversTypes['SpsLocationTemplate']>>, ParentType, ContextType>;
  packaging?: Resolver<Maybe<ResolversTypes['ImportCertificatePackagingTemplate']>, ParentType, ContextType>;
  physical_packages?: Resolver<Maybe<Array<ResolversTypes['SpsPackageTemplate']>>, ParentType, ContextType>;
  production_batch_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scientific_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sequence?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  treatments?: Resolver<Maybe<ResolversTypes['TreatmentTemplate']>, ParentType, ContextType>;
  wine?: Resolver<Maybe<ResolversTypes['WineFieldsTemplate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsTransportEquipmentTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsTransportEquipmentTemplate'] = ResolversParentTypes['SpsTransportEquipmentTemplate']> = {
  affixed_seals?: Resolver<Maybe<Array<ResolversTypes['SpsSealTemplate']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  temperature_settings?: Resolver<Maybe<Array<ResolversTypes['SpsTemperatureTemplate']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpsWineConsignmentTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpsWineConsignmentTemplate'] = ResolversParentTypes['SpsWineConsignmentTemplate']> = {
  is_bulk?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Template'] = ResolversParentTypes['Template']> = {
  approvedVersion?: Resolver<Maybe<ResolversTypes['TemplateVersion']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  importantVersions?: Resolver<Maybe<Array<Maybe<ResolversTypes['TemplateVersion']>>>, ParentType, ContextType>;
  lastVersion?: Resolver<ResolversTypes['TemplateVersion'], ParentType, ContextType>;
  versions?: Resolver<Maybe<Array<Maybe<ResolversTypes['TemplateVersion']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateDocumentUrlResolvers<ContextType = any, ParentType extends ResolversParentTypes['TemplateDocumentUrl'] = ResolversParentTypes['TemplateDocumentUrl']> = {
  documentName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['TemplateHistory'] = ResolversParentTypes['TemplateHistory']> = {
  fieldName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateSectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TemplateSection'] = ResolversParentTypes['TemplateSection']> = {
  fields?: Resolver<Array<ResolversTypes['TemplateSectionField']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateSectionFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['TemplateSectionField'] = ResolversParentTypes['TemplateSectionField']> = {
  field?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateUploadUrlResolvers<ContextType = any, ParentType extends ResolversParentTypes['TemplateUploadUrl'] = ResolversParentTypes['TemplateUploadUrl']> = {
  lastVersion?: Resolver<ResolversTypes['TemplateVersion'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateVersionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TemplateVersion'] = ResolversParentTypes['TemplateVersion']> = {
  assignedTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  businessArea?: Resolver<ResolversTypes['BusinessArea'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  commodity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  destinationMarket?: Resolver<ResolversTypes['DestinationMarket'], ParentType, ContextType>;
  displayedDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentRevision?: Resolver<Maybe<ResolversTypes['TemplateVersion']>, ParentType, ContextType>;
  editable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  effective?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  expire?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  exportCertificateType?: Resolver<Maybe<ResolversTypes['CertificateType']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<Array<Maybe<ResolversTypes['Field']>>>, ParentType, ContextType>;
  history?: Resolver<Maybe<Array<ResolversTypes['TemplateHistory']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  importCertificateType?: Resolver<Maybe<ResolversTypes['ImportCertificateType']>, ParentType, ContextType>;
  majorVersion?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  minorVersion?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  newFileUpload?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  reasonForUpdate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  s3key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  s3uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['TemplateVersionStatus'], ParentType, ContextType>;
  templateId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['AWSDateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uploadInProgress?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeTemplate'] = ResolversParentTypes['TimeTemplate']> = {
  hour?: Resolver<ResolversTypes['IntTemplate'], ParentType, ContextType>;
  minute?: Resolver<ResolversTypes['IntTemplate'], ParentType, ContextType>;
  second?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportMovementCodeTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportMovementCodeTemplate'] = ResolversParentTypes['TransportMovementCodeTemplate']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TreatmentTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TreatmentTemplate'] = ResolversParentTypes['TreatmentTemplate']> = {
  full_information?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProfile'] = ResolversParentTypes['UserProfile']> = {
  organisationName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organisationPartyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  organisationPrimaryAddress?: Resolver<Maybe<ResolversTypes['B2GAddress']>, ParentType, ContextType>;
  registartionNumberId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  registrationNumberValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userContactId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  userEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userPrimaryPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WineAnalytesTolerancesResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['WineAnalytesTolerancesResponse'] = ResolversParentTypes['WineAnalytesTolerancesResponse']> = {
  destinationMarket?: Resolver<Maybe<ResolversTypes['DestinationMarket']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WineAnalytesTolerancesTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['WineAnalytesTolerancesType'] = ResolversParentTypes['WineAnalytesTolerancesType']> = {
  chemistryTest?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dealcoholisedWine?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  max?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  red?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  validFrom?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  validUntil?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  wineCategory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WineFieldsTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['WineFieldsTemplate'] = ResolversParentTypes['WineFieldsTemplate']> = {
  bottler_reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  colour?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eu_category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eu_ingredients?: Resolver<Maybe<ResolversTypes['EUIngredientsTemplate']>, ParentType, ContextType>;
  geographic_indication?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  grape_variety?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  is_dealcoholised?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  laboratory?: Resolver<Maybe<ResolversTypes['SpsPartyTemplate']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  package?: Resolver<Maybe<ResolversTypes['PackageTemplate']>, ParentType, ContextType>;
  special_process?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  style?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vintage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wine_description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WineProductFieldTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['WineProductFieldType'] = ResolversParentTypes['WineProductFieldType']> = {
  certificateType?: Resolver<Maybe<ResolversTypes['CertificateType']>, ParentType, ContextType>;
  destinationMarket?: Resolver<Maybe<ResolversTypes['DestinationMarket']>, ParentType, ContextType>;
  validFrom?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  validUntil?: Resolver<Maybe<ResolversTypes['AWSDateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WinemakingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Winemaking'] = ResolversParentTypes['Winemaking']> = {
  confirmations?: Resolver<Maybe<Array<Maybe<ResolversTypes['WinemakingConfirmation']>>>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ProcessStatus']>, ParentType, ContextType>;
  wsmp?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WinemakingConfirmationResolvers<ContextType = any, ParentType extends ResolversParentTypes['WinemakingConfirmation'] = ResolversParentTypes['WinemakingConfirmation']> = {
  confirmed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  disputedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disputedResolution?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  market?: Resolver<ResolversTypes['DestinationMarket'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['WinemakingStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AWSDate?: GraphQLScalarType;
  AWSDateTime?: GraphQLScalarType;
  AWSEmail?: GraphQLScalarType;
  AWSIPAddress?: GraphQLScalarType;
  AWSJSON?: GraphQLScalarType;
  AWSPhone?: GraphQLScalarType;
  AWSTime?: GraphQLScalarType;
  AWSTimestamp?: GraphQLScalarType;
  AWSURL?: GraphQLScalarType;
  AvailableTemplateFields?: AvailableTemplateFieldsResolvers<ContextType>;
  B2GAddress?: B2GAddressResolvers<ContextType>;
  B2GAuthentication?: B2GAuthenticationResolvers<ContextType>;
  B2GCertificateDelivery?: B2GCertificateDeliveryResolvers<ContextType>;
  B2GClassification?: B2GClassificationResolvers<ContextType>;
  B2GClause?: B2GClauseResolvers<ContextType>;
  B2GConsignment?: B2GConsignmentResolvers<ContextType>;
  B2GContact?: B2GContactResolvers<ContextType>;
  B2GContainer?: B2GContainerResolvers<ContextType>;
  B2GCountry?: B2GCountryResolvers<ContextType>;
  B2GCountrySubDivision?: B2GCountrySubDivisionResolvers<ContextType>;
  B2GDeclaration?: B2GDeclarationResolvers<ContextType>;
  B2GEligibilityCriteriaMet?: B2GEligibilityCriteriaMetResolvers<ContextType>;
  B2GEligibilityStatus?: B2GEligibilityStatusResolvers<ContextType>;
  B2GExportCertificateRequest?: B2GExportCertificateRequestResolvers<ContextType>;
  B2GExporterDeclaration?: B2GExporterDeclarationResolvers<ContextType>;
  B2GLocation?: B2GLocationResolvers<ContextType>;
  B2GNote?: B2GNoteResolvers<ContextType>;
  B2GPackage?: B2GPackageResolvers<ContextType>;
  B2GParty?: B2GPartyResolvers<ContextType>;
  B2GPeriod?: B2GPeriodResolvers<ContextType>;
  B2GPerson?: B2GPersonResolvers<ContextType>;
  B2GPremise?: B2GPremiseResolvers<ContextType>;
  B2GProcess?: B2GProcessResolvers<ContextType>;
  B2GProcessCharacteristic?: B2GProcessCharacteristicResolvers<ContextType>;
  B2GProduct?: B2GProductResolvers<ContextType>;
  B2GProductEligibility?: B2GProductEligibilityResolvers<ContextType>;
  B2GProductEvent?: B2GProductEventResolvers<ContextType>;
  B2GQualification?: B2GQualificationResolvers<ContextType>;
  B2GReferencedDocument?: B2GReferencedDocumentResolvers<ContextType>;
  B2GSeal?: B2GSealResolvers<ContextType>;
  B2GTemperature?: B2GTemperatureResolvers<ContextType>;
  B2GTradeLineItem?: B2GTradeLineItemResolvers<ContextType>;
  B2GTradeLineItemContainer?: B2GTradeLineItemContainerResolvers<ContextType>;
  B2GTransportMovement?: B2GTransportMovementResolvers<ContextType>;
  B2GValidationErrors?: B2GValidationErrorsResolvers<ContextType>;
  B2GWineConsignment?: B2GWineConsignmentResolvers<ContextType>;
  B2GWineFields?: B2GWineFieldsResolvers<ContextType>;
  B2GWinePackage?: B2GWinePackageResolvers<ContextType>;
  BottlingRunData?: BottlingRunDataResolvers<ContextType>;
  BottlingRunResponse?: BottlingRunResponseResolvers<ContextType>;
  CancelledProcessResponse?: CancelledProcessResponseResolvers<ContextType>;
  ChemistryTestReceipt?: ChemistryTestReceiptResolvers<ContextType>;
  ChemistryTestRequest?: ChemistryTestRequestResolvers<ContextType>;
  ChemistryTestResponse?: ChemistryTestResponseResolvers<ContextType>;
  CombinedPartyTemplate?: CombinedPartyTemplateResolvers<ContextType>;
  ConfirmPackagingResponse?: ConfirmPackagingResponseResolvers<ContextType>;
  CustomsPermitNumberUsage?: CustomsPermitNumberUsageResolvers<ContextType>;
  DateTemplate?: DateTemplateResolvers<ContextType>;
  DateTimeTemplate?: DateTimeTemplateResolvers<ContextType>;
  DecimalTemplate?: DecimalTemplateResolvers<ContextType>;
  DecisionTable?: DecisionTableResolvers<ContextType>;
  DecisionTableBaseInput?: DecisionTableBaseInputResolvers<ContextType>;
  DecisionTableColumn?: DecisionTableColumnResolvers<ContextType>;
  DecisionTableExpectation?: DecisionTableExpectationResolvers<ContextType>;
  DecisionTableField?: DecisionTableFieldResolvers<ContextType>;
  DecisionTableRevision?: DecisionTableRevisionResolvers<ContextType>;
  DecisionTableRow?: DecisionTableRowResolvers<ContextType>;
  DecisionTableTestResult?: DecisionTableTestResultResolvers<ContextType>;
  DeclarationFormletFields?: DeclarationFormletFieldsResolvers<ContextType>;
  DeclarationFormletSection?: DeclarationFormletSectionResolvers<ContextType>;
  DeclarationsResponse?: DeclarationsResponseResolvers<ContextType>;
  DeclarationsType?: DeclarationsTypeResolvers<ContextType>;
  DraftExportCertificate?: DraftExportCertificateResolvers<ContextType>;
  EStarOrganisation?: EStarOrganisationResolvers<ContextType>;
  EUIngredient?: EuIngredientResolvers<ContextType>;
  EUIngredientsTemplate?: EuIngredientsTemplateResolvers<ContextType>;
  EligibilityResponse?: EligibilityResponseResolvers<ContextType>;
  EligibilityType?: EligibilityTypeResolvers<ContextType>;
  EnumerationInfo?: EnumerationInfoResolvers<ContextType>;
  EstarCancelRequest?: EstarCancelRequestResolvers<ContextType>;
  ExportCertificateRequest?: ExportCertificateRequestResolvers<ContextType>;
  ExportCertificateWorkflowResponse?: ExportCertificateWorkflowResponseResolvers<ContextType>;
  ExportCertificateWorkflowType?: ExportCertificateWorkflowTypeResolvers<ContextType>;
  Field?: FieldResolvers<ContextType>;
  FlexiFillingRunData?: FlexiFillingRunDataResolvers<ContextType>;
  FlexiFillingRunResponse?: FlexiFillingRunResponseResolvers<ContextType>;
  Form?: FormResolvers<ContextType>;
  FormletCollectionFilter?: FormletCollectionFilterResolvers<ContextType>;
  FormletField?: FormletFieldResolvers<ContextType>;
  FormletNestedFields?: FormletNestedFieldsResolvers<ContextType>;
  FormletOption?: FormletOptionResolvers<ContextType>;
  IDsTemplate?: IDsTemplateResolvers<ContextType>;
  IPPCHubLogEntry?: IppcHubLogEntryResolvers<ContextType>;
  ImportCertificate?: ImportCertificateResolvers<ContextType>;
  ImportCertificateEmbeddedDocument?: ImportCertificateEmbeddedDocumentResolvers<ContextType>;
  ImportCertificateExtractItem?: ImportCertificateExtractItemResolvers<ContextType>;
  ImportCertificateExtractMeasurement?: ImportCertificateExtractMeasurementResolvers<ContextType>;
  ImportCertificateExtractTemplate?: ImportCertificateExtractTemplateResolvers<ContextType>;
  ImportCertificateIntegration?: ImportCertificateIntegrationResolvers<ContextType>;
  ImportCertificateLogMessage?: ImportCertificateLogMessageResolvers<ContextType>;
  ImportCertificateMeasurementTemplate?: ImportCertificateMeasurementTemplateResolvers<ContextType>;
  ImportCertificatePackagingTemplate?: ImportCertificatePackagingTemplateResolvers<ContextType>;
  ImportCertificatePdf?: ImportCertificatePdfResolvers<ContextType>;
  ImportCertificateXml?: ImportCertificateXmlResolvers<ContextType>;
  ImportConnectionsResponse?: ImportConnectionsResponseResolvers<ContextType>;
  ImportConnectionsType?: ImportConnectionsTypeResolvers<ContextType>;
  IndicatorType?: IndicatorTypeResolvers<ContextType>;
  IntTemplate?: IntTemplateResolvers<ContextType>;
  ManualVolumeAdjustment?: ManualVolumeAdjustmentResolvers<ContextType>;
  MeasureTemplate?: MeasureTemplateResolvers<ContextType>;
  MonthTemplate?: MonthTemplateResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NamedCode?: NamedCodeResolvers<ContextType>;
  NamedCodeTemplate?: NamedCodeTemplateResolvers<ContextType>;
  NewReferencedDocument?: NewReferencedDocumentResolvers<ContextType>;
  NumberConstrain?: NumberConstrainResolvers<ContextType>;
  PackageTemplate?: PackageTemplateResolvers<ContextType>;
  Packaging?: PackagingResolvers<ContextType>;
  PackagingConfirmation?: PackagingConfirmationResolvers<ContextType>;
  PackagingRun?: PackagingRunResolvers<ContextType>;
  ProcessAttributesResponse?: ProcessAttributesResponseResolvers<ContextType>;
  ProcessResponse?: ProcessResponseResolvers<ContextType>;
  ProcessTask?: ProcessTaskResolvers<ContextType>;
  ProcessType?: ProcessTypeResolvers<ContextType>;
  ProductFieldResponse?: ProductFieldResponseResolvers<ContextType>;
  ProductFieldType?: ProductFieldTypeResolvers<ContextType>;
  ProductProcessResponse?: ProductProcessResponseResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  ReferencedDocument?: ReferencedDocumentResolvers<ContextType>;
  ReferencedDocumentUrl?: ReferencedDocumentUrlResolvers<ContextType>;
  ReindexResponse?: ReindexResponseResolvers<ContextType>;
  RequestConfirmationResponse?: RequestConfirmationResponseResolvers<ContextType>;
  RulesInput?: RulesInputResolvers<ContextType>;
  RulesResponse?: RulesResponseResolvers<ContextType>;
  SPSAddress?: SpsAddressResolvers<ContextType>;
  SPSAuthentication?: SpsAuthenticationResolvers<ContextType>;
  SPSCertificate?: SpsCertificateResolvers<ContextType>;
  SPSClassification?: SpsClassificationResolvers<ContextType>;
  SPSClause?: SpsClauseResolvers<ContextType>;
  SPSConsignment?: SpsConsignmentResolvers<ContextType>;
  SPSConsignmentItem?: SpsConsignmentItemResolvers<ContextType>;
  SPSContact?: SpsContactResolvers<ContextType>;
  SPSCountry?: SpsCountryResolvers<ContextType>;
  SPSCountrySubDivision?: SpsCountrySubDivisionResolvers<ContextType>;
  SPSEvent?: SpsEventResolvers<ContextType>;
  SPSExchangedDocument?: SpsExchangedDocumentResolvers<ContextType>;
  SPSLocation?: SpsLocationResolvers<ContextType>;
  SPSNote?: SpsNoteResolvers<ContextType>;
  SPSPackage?: SpsPackageResolvers<ContextType>;
  SPSParty?: SpsPartyResolvers<ContextType>;
  SPSPeriod?: SpsPeriodResolvers<ContextType>;
  SPSPerson?: SpsPersonResolvers<ContextType>;
  SPSProcess?: SpsProcessResolvers<ContextType>;
  SPSProcessCharacteristic?: SpsProcessCharacteristicResolvers<ContextType>;
  SPSQualification?: SpsQualificationResolvers<ContextType>;
  SPSReferencedDocument?: SpsReferencedDocumentResolvers<ContextType>;
  SPSSeal?: SpsSealResolvers<ContextType>;
  SPSTemperature?: SpsTemperatureResolvers<ContextType>;
  SPSTradeLineItem?: SpsTradeLineItemResolvers<ContextType>;
  SPSTransportEquipment?: SpsTransportEquipmentResolvers<ContextType>;
  SPSTransportMovement?: SpsTransportMovementResolvers<ContextType>;
  SPSTransportMovementTemplate?: SpsTransportMovementTemplateResolvers<ContextType>;
  SPSWineConsignment?: SpsWineConsignmentResolvers<ContextType>;
  SalesForceAddress?: SalesForceAddressResolvers<ContextType>;
  SalesForceContact?: SalesForceContactResolvers<ContextType>;
  SalesForceEmail?: SalesForceEmailResolvers<ContextType>;
  SalesForceIdentifier?: SalesForceIdentifierResolvers<ContextType>;
  SalesForceParty?: SalesForcePartyResolvers<ContextType>;
  SalesForcePhone?: SalesForcePhoneResolvers<ContextType>;
  SalesForceProductId?: SalesForceProductIdResolvers<ContextType>;
  SearchApiKey?: SearchApiKeyResolvers<ContextType>;
  SensoryProcessRequest?: SensoryProcessRequestResolvers<ContextType>;
  SpsAddressTemplate?: SpsAddressTemplateResolvers<ContextType>;
  SpsAuthenticationTemplate?: SpsAuthenticationTemplateResolvers<ContextType>;
  SpsCertificateTemplate?: SpsCertificateTemplateResolvers<ContextType>;
  SpsClassificationTemplate?: SpsClassificationTemplateResolvers<ContextType>;
  SpsClauseTemplate?: SpsClauseTemplateResolvers<ContextType>;
  SpsConsignmentItemTemplate?: SpsConsignmentItemTemplateResolvers<ContextType>;
  SpsConsignmentTemplate?: SpsConsignmentTemplateResolvers<ContextType>;
  SpsContactTemplate?: SpsContactTemplateResolvers<ContextType>;
  SpsCountrySubDivisionTemplate?: SpsCountrySubDivisionTemplateResolvers<ContextType>;
  SpsCountryTemplate?: SpsCountryTemplateResolvers<ContextType>;
  SpsEventTemplate?: SpsEventTemplateResolvers<ContextType>;
  SpsExchangedDocumentTemplate?: SpsExchangedDocumentTemplateResolvers<ContextType>;
  SpsLocationTemplate?: SpsLocationTemplateResolvers<ContextType>;
  SpsNoteTemplate?: SpsNoteTemplateResolvers<ContextType>;
  SpsPackageTemplate?: SpsPackageTemplateResolvers<ContextType>;
  SpsPartyTemplate?: SpsPartyTemplateResolvers<ContextType>;
  SpsPeriodTemplate?: SpsPeriodTemplateResolvers<ContextType>;
  SpsPersonTemplate?: SpsPersonTemplateResolvers<ContextType>;
  SpsProcessCharacteristicTemplate?: SpsProcessCharacteristicTemplateResolvers<ContextType>;
  SpsProcessTemplate?: SpsProcessTemplateResolvers<ContextType>;
  SpsQualificationTemplate?: SpsQualificationTemplateResolvers<ContextType>;
  SpsReplacementCertificateTemplate?: SpsReplacementCertificateTemplateResolvers<ContextType>;
  SpsSealTemplate?: SpsSealTemplateResolvers<ContextType>;
  SpsTemperatureTemplate?: SpsTemperatureTemplateResolvers<ContextType>;
  SpsTradeLineItemTemplate?: SpsTradeLineItemTemplateResolvers<ContextType>;
  SpsTransportEquipmentTemplate?: SpsTransportEquipmentTemplateResolvers<ContextType>;
  SpsWineConsignmentTemplate?: SpsWineConsignmentTemplateResolvers<ContextType>;
  Template?: TemplateResolvers<ContextType>;
  TemplateDocumentUrl?: TemplateDocumentUrlResolvers<ContextType>;
  TemplateHistory?: TemplateHistoryResolvers<ContextType>;
  TemplateSection?: TemplateSectionResolvers<ContextType>;
  TemplateSectionField?: TemplateSectionFieldResolvers<ContextType>;
  TemplateUploadUrl?: TemplateUploadUrlResolvers<ContextType>;
  TemplateVersion?: TemplateVersionResolvers<ContextType>;
  TimeTemplate?: TimeTemplateResolvers<ContextType>;
  TransportMovementCodeTemplate?: TransportMovementCodeTemplateResolvers<ContextType>;
  TreatmentTemplate?: TreatmentTemplateResolvers<ContextType>;
  UserProfile?: UserProfileResolvers<ContextType>;
  WineAnalytesTolerancesResponse?: WineAnalytesTolerancesResponseResolvers<ContextType>;
  WineAnalytesTolerancesType?: WineAnalytesTolerancesTypeResolvers<ContextType>;
  WineFieldsTemplate?: WineFieldsTemplateResolvers<ContextType>;
  WineProductFieldType?: WineProductFieldTypeResolvers<ContextType>;
  Winemaking?: WinemakingResolvers<ContextType>;
  WinemakingConfirmation?: WinemakingConfirmationResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  aws_cognito_user_pools?: Aws_Cognito_User_PoolsDirectiveResolver<any, any, ContextType>;
  aws_iam?: Aws_IamDirectiveResolver<any, any, ContextType>;
};
